import React from 'react';
import { translate } from 'react-admin';
import { Show, TabbedShowLayout, TextField, Tab, ReferenceField, DateField} from 'react-admin';

const PackageShow = ({ translate, ...props }) => (

    <Show {...props} >
        <TabbedShowLayout>
            <Tab label="Package" >
                <ReferenceField source="countryUuid" reference="countries" linkType="show" allowEmpty>
                    <TextField emptyText source="name" />
                </ReferenceField>

                <ReferenceField source="organisationUuid" reference="organisations" linkType="show" allowEmpty>
                    <TextField emptyText source="name" />
                </ReferenceField>

                <ReferenceField source="locationUuid" reference="locations" linkType="show" allowEmpty>
                    <TextField emptyText source="locationName" />
                </ReferenceField>

                <ReferenceField source="skuUuid" reference="skus" linkType="show" allowEmpty>
                    <TextField emptyText source="name" />
                </ReferenceField>

                <TextField emptyText source="startDate" />
                <TextField emptyText source="endDate" />

                <TextField emptyText source="psaDiscountGroupUuid" />
                <TextField emptyText source="dailyRentalFeeSettingsId" />
                <TextField emptyText source="freightCapitalMetroFeeSettingsId" />

                <TextField emptyText source="issueFee" />
                <TextField emptyText source="dailyRentalFee" />
                <TextField emptyText source="cleaningFee" />
                <TextField emptyText source="freightCapitalMetroFee" />
                <TextField emptyText source="interCountryFee" />
                <TextField emptyText source="taproomVenueFee" />
                <TextField emptyText source="taproomDistributorFee" />
                <TextField emptyText source="freightRegionalMetroFeeSettingsUuid" />
                <TextField emptyText source="freightRegionalFeeSettingsUuid" />
                <TextField emptyText source="freightRegionalMetroFee" />
                <TextField emptyText source="freightRegionalFee" />
                <TextField emptyText source="timedFee" />

            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default translate(PackageShow);
