import React, { Fragment } from 'react';
import MonthlySummary from "../summaries/MonthlySummary";
import {
    useParams
} from "react-router-dom";

const Monthly = () => {
    let { date } = useParams();

    return (
        <Fragment>
            <MonthlySummary organisation={localStorage.getItem('oUuid')} dateOfTheMonth={date} />
        </Fragment>
    );
}

export default Monthly;
