import KegList from './KegList';
import KegShow from "./KegShow";
import KegIcon from '@material-ui/icons/Kitchen';
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: KegList,
    show: KegShow,
    icon: KegIcon,
    unauthorised: UnauthorisedPage
};
