import React, {Fragment, useCallback} from "react";
import {Link, usePermissions, useQuery} from 'react-admin';
import OrganisationIcon from '@material-ui/icons/Business';
import OrganisationViewIcon from '@material-ui/icons/LocationCity';
import LocationIcon from '@material-ui/icons/Map';
import HomeIcon from '@material-ui/icons/Home';
import {Link as ALink} from "@material-ui/core";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {makeStyles} from "@material-ui/core";
import {isCustomer, isOperations} from "../security/Role";
import KegstarCircularProgress from "./layout/KegstarCircularProgress";

import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1, 2),
    },
    link: {
        display: 'flex',
        color: theme.palette.primary.dark
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    home: {
        color: theme.palette.primary.dark,
    },
    homeIcon: {
        color: theme.palette.primary.dark,
        fontSize: '1.4rem',
        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    delimiterIcon: {
        fontSize: '22px'
    },
    links: {
        verticalAlign: 'super',
        color: theme.palette.primary.dark,
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        },
        fontSize: 'smaller'
    },
    displayName: {
        verticalAlign: 'super',
        color: theme.palette.primary.dark,
        fontWeight: 'bold'
    },
}));

export const DisplayNames = {
    'countries': 'name',
    'packages/countries': 'name',
    'organisations': 'name',
    'locations': 'locationName',
    'kegs': 'serialNumber',
    'invoices/draft': 'displayId',
    'invoices': 'displayId',
    'rentals': 'kegSerialNumber',
    'packages': 'displayId'
}

const ShowInterimBreadcrumb = ( resource, location ) => {

    let isViewPage =  (location.pathname.indexOf('show') !== -1) ? true : false;
    let showInterimBreadcrumb = false;
    let interimResource = null;
    if( (resource === 'locations' || resource === 'rentals') && isViewPage) {
        interimResource = 'organisations';
        showInterimBreadcrumb = true;
    }
    var hash = {};
    hash['showInterimBreadcrumb'] = showInterimBreadcrumb;
    hash['interimResource'] = interimResource;
    return hash;
}

const InterimBreadcrumb = ( {interim, record} ) => {
    const classes = useStyles();
    let show = interim.showInterimBreadcrumb;
    let payload = {id: record.organisationUuid};

    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: interim.interimResource,
        payload: payload
    });

    if (loading) return <KegstarCircularProgress />;
    if (error) return null;
    if (!data) return null;
    if (!show) return null;

    return (
        <Link to={`/${interim.interimResource}/${data.uuid}/show`} className={classes.link} >
            <ShowIcon resource={interim.interimResource} classes={classes} />
            { data[ DisplayNames[interim.interimResource] ] }
        </Link>
    )
}

const GetBreadcrumbs = ( {resource, classes, id, showInterimBreadcrumbs, ...props} ) => {

    const location = props.location;
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: resource,
        payload: {id: id}
    });

    if (loading) return <KegstarCircularProgress />;
    if (error) return null;
    if (!data) return null;

    return (
        <Fragment>
            { (showInterimBreadcrumbs) && <InterimBreadcrumb interim={showInterimBreadcrumbs} record={data} /> }
            { !(showInterimBreadcrumbs) && <Link to={location.pathname} className={classes.link}>
                <ShowIcon resource={resource} classes={classes} />
                { data[ DisplayNames[resource] ] }
            </Link> }
        </Fragment>
    );

}

const PackagesLink = ({id, classes}) => {

    // const location = props.location;
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'packages',
        payload: {id: id}
    });

    if (loading) return <KegstarCircularProgress />;
    if (error) return null;
    if (!data) return null;
    let link = '';

    const country = data.countryUuid;
    const organisation = data.organisationUuid;
    const location = data.locationUuid;

    if(country) {
        link = `/packages/countries/${country}/show?view=true`;
    } else if(organisation) {
        link = `/organisations/${organisation}/show/packages?view=true`;
    } else if(location) {
        link = `/locations/${location}/show/packages?view=true`;
    }

    return (
        <Link to={link} className={classes.link}>
            Package Details
        </Link>
    );

}

const DisplayResource = ({resource, permissions, isViewPage, isEditPage, classes, id}) => {
    const history = useHistory();
    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);

    let useBackButton = false;
    let resourceName = resource;

    if(resource.indexOf('report') !== -1 || resource.indexOf('invoices') !== -1|| resource.indexOf('packages') !== -1){
        useBackButton = true;
    }

    let resourceNameDisplay = resourceName.charAt(0).toUpperCase() + resourceName.slice(1);

    if(resourceNameDisplay.indexOf('/')) {

        if(resourceName === 'report/charges' || resourceName === 'report/rentals' ) {
            resourceNameDisplay = 'Go back';
        } else if(resourceName === 'packages/countries') {
            resourceNameDisplay = 'Countries';
        } else {
            let resourceNameArray = resourceNameDisplay.split("/");
            resourceNameDisplay = resourceNameArray[0].charAt(0).toUpperCase() + resourceNameArray[0].slice(1);
        }

    }

    if(isOperations(permissions) && resourceName === 'packages' && isEditPage) {
        return (
            <Fragment>
                <PackagesLink id={id} classes={classes} />
            </Fragment>
        )
    }

    return (
        <Fragment>
            {
                (isOperations(permissions) && !isCustomer(permissions) && useBackButton) && <Link onClick={handleBack} className={classes.link}>

                    {resourceNameDisplay}
                </Link>
            }
            {
                (isOperations(permissions) && !isCustomer(permissions) && !useBackButton) && <Link to={'/'+resource} className={classes.link}>

                    {resourceNameDisplay}
                </Link>
            }
            {
                // (isCustomer(permissions) && useBackButton) && <Link onClick={handleBack} className={classes.link}>  {resourceNameDisplay} </Link>
                (isCustomer(permissions) && useBackButton) && <span className={classes.link}>{resourceNameDisplay}</span>
            }
        </Fragment>
    )

}

const ShowIcon = ({resource, classes, isViewPage}) => {

    return (
        <Fragment>
            { (resource === 'organisations' && !isViewPage) && <OrganisationIcon className={classes.icon}/> }
            { (resource === 'organisations' && isViewPage) && <OrganisationViewIcon className={classes.icon}/> }
            { (resource === 'locations') && <LocationIcon className={classes.icon}/> }
        </Fragment>
    )
}
const KegstarBreadcrumbs = (props) => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    const location = props.location;
    const match = props.match;
    let resource = props.resource;

    let id = null;

    if (location) {

        let isViewPage = false;
        let isEditPage = false;
        if (location.pathname.indexOf('show') !== -1) {
            isViewPage = true;
            if(resource === 'invoices/draft'){
                isViewPage = false;
            }
        }

        if (match.path === "/packages/:id") {
            isEditPage = true;
        }

        if(isViewPage || isEditPage) {
            id = props.id;
        }
        let showInterimBreadcrumbs = ShowInterimBreadcrumb(resource, location);

        return (
            <Breadcrumbs aria-label="breadcrumb">
                { !isCustomer(permissions) ?
                    <Link to={'/'} className={classes.link}>
                        <HomeIcon className={classes.homeIcon}/>
                    </Link>
                :
                    <ALink href={`${process.env.REACT_APP_MYSTAR_URL}`} className={classes.link}>
                        <HomeIcon className={classes.homeIcon}/>
                    </ALink>
                }
                <DisplayResource classes={classes} resource={resource} permissions={permissions} isViewPage={isViewPage} isEditPage={isEditPage} id={id} />
                { (showInterimBreadcrumbs['showInterimBreadcrumb']) && <GetBreadcrumbs {...props} resource={resource} id={id} classes={classes} showInterimBreadcrumbs={showInterimBreadcrumbs} /> }
                { (isViewPage || isEditPage) && <GetBreadcrumbs {...props} resource={resource} id={id} classes={classes} /> }
            </Breadcrumbs>
        )
    }
    return null;
}

export default KegstarBreadcrumbs;
