import PackageSetGroupList from './PackageSetGroupList';
import PackageSetGroupShow from "./PackageSetGroupShow";
import PackageSetGroupIcon from '@material-ui/icons/Ballot';
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: PackageSetGroupList,
    show: PackageSetGroupShow,
    icon: PackageSetGroupIcon,
    unauthorised: UnauthorisedPage
};
