import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./SummaryImageStyles";
const useStyles = makeStyles(styles);

const NewCyclesImage = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <div style={{textAlign: 'center', padding: '8px'}}>
                <img src={require('../../../assets/images/NewCycles.svg')} alt={'alt'} style={{width: '64px'}} className={classes.image} />
            </div>
        </Fragment>
    )
}

export default NewCyclesImage;
