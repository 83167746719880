import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import {makeStyles} from "@material-ui/core";
import { useInput } from 'react-admin';
import { format } from 'date-fns';

import { styles } from './KegnovaDatePickerField';

const useStyles = makeStyles(styles);

const KegnovaDatePickerInput = ({inputVariant, ...props}) => {
    const [selectedDate, setSelectedDate] = React.useState(null);
    const classes = useStyles();
    inputVariant = (inputVariant) ? inputVariant : 'outlined';
    const {
        input: { name, onChange },
        meta: { touched, error }
    } = useInput(props);

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <div className={classes.dateField} id={name} >
                <KeyboardDatePicker
                    name={name}
                    emptyLabel={''}
                    inputVariant={inputVariant}
                    margin={'normal'}
                    format="dd/MM/yyyy"
                    label={(props.label) ? props.label : ''}
                    value={selectedDate}
                    onChange={handleDateChange}
                    onAccept={onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    readOnly={(props.readOnly) ? props.readOnly : false}
                    clearable
                />
            </div>
        </MuiPickersUtilsProvider>
    );
}

export default KegnovaDatePickerInput;
