import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const RentalsExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            startDate,	endDate, billingCycleStartDate,	billingCycleEndDate, organisationTimezone, countryUuid, countryName,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'id',
            'organisationName',
            'cycleDays',
            'kegSerialNumber',	'tracksOrderId',	'startDateText', 'endDateText', 'billingCycleStartDateText',	'billingCycleEndDateText',	'startKegRoleRoleText',	'startKegRoleLocationName',	'stopKegRoleRoleText',	'stopKegRoleLocationName'
        ] // order fields in the export
        ,
        rename:[
            'Id',
            'Organisation',
            'Cycle Days',
            'Asset Code/Serial Number',	'Order Id',	'Rental Start Date', 'Rental End Date', 'Cycle Start Date',	'Cycle End Date',	'Cycle Start Role', 'Cycle Start Location',	'Cycle Stop Role',	'Cycle Stop Location'
        ]
    }, (err, csv) => {
        downloadCSV(csv, 'Rentals'); // download as 'posts.csv` file
    });

};

export default RentalsExport;
