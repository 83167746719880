import React, { forwardRef, Fragment } from 'react';

import KegstarAppBar from "./KegstarAppbar";

import {UserMenu} from 'react-admin';
import Typography from '@material-ui/core/Typography';

import UserProfileIcon from '../components/icons/AppbarUserProfileIcon';
import {makeStyles, Theme} from '@material-ui/core/styles';

import AppLogo from "./AppLogo";
import OrganisationHeader from './OrganisationHeader';

import {useMediaQuery} from "@material-ui/core";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import {FullNameAvatar, FullUserName, UserRole} from "../components/Utilities";
import { CountryFlag } from "../components/CountryFlag";

import KegstarCountrySelect from "../components/KegstarCountrySelect";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import {KegstarLogout} from "../auth";
import KegstarIcon from "../components/icons/KegstarIcon";
import CloseIcon from '@material-ui/icons/Close';
import {useSelector} from "react-redux";
import {AppState} from "../types";
import ThemeChanger from "../configuration/ThemeChanger";


const useStyles = makeStyles(theme => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    cardContent: {
        backgroundColor: theme.palette.primary.light
    },
    list: {
        width: 280,
    },
    actionMenu: {
        padding: 0,
        paddingTop: '5px',
        borderRadius: 0
    },
    menuLabel : {
        verticalAlign: 'top', marginLeft: '5px',
        fontSize: 'larger',
        color: theme.palette.primary.dark,
    }
}));

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    type DrawerSide = 'top' | 'left' | 'bottom' | 'right';
    const toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const sideList = (side: DrawerSide) => (
        <div
            className={classes.list}
            role="presentation"
            // onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <Card>

                <CardHeader className={classes.actionMenu}
                    action={
                        <Fragment>
                            <ThemeChanger />
                            <Button onClick={toggleDrawer(side, false)} ><CloseIcon /></Button>
                        </Fragment>
                    }
                />
                <Divider />
                <CardHeader
                    avatar={
                        <FullNameAvatar />
                    }
                    action={
                        null
                    }
                    title={<FullUserName />}
                    subheader={UserRole(localStorage.getItem('role'))}
                />
                <CardMedia>
                    <Box alignItems="center" justifyContent="center">
                        <CountryFlag country={null} height={null} />
                    </Box>
                    <Box>
                        <KegstarCountrySelect />
                    </Box>
                </CardMedia>
                <Divider />
                {/*<CardContent className={classes.cardContent} >*/}
                {/*    <Box m={1}>*/}
                {/*        <Link href={process.env.REACT_APP_MYSTAR_URL} rel="noreferrer" style={{textDecoration: 'none'}}>*/}
                {/*            <KegstarIcon />*/}
                {/*            <span className={classes.menuLabel}> MYSTAR </span>*/}
                {/*        </Link>*/}
                {/*    </Box>*/}
                {/*</CardContent>*/}
                {/*<Divider />*/}
                <CardContent className={classes.cardContent} >
                    <Box m={1}>
                    <Link href={process.env.REACT_APP_TRACKS_URL} rel="noreferrer" style={{textDecoration: 'none'}}>
                        <KegstarIcon />
                        <span className={classes.menuLabel}> TRACKS </span>
                    </Link>
                    </Box>
                </CardContent>

                <Divider />
                <CardActions disableSpacing>
                    <KegstarLogout button={true} />
                </CardActions>
            </Card>
        </div>
    );

    return (
        <div>
            <Button onClick={toggleDrawer('right', true)}><UserProfileIcon /></Button>
            <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
                {sideList('right')}
            </Drawer>
        </div>
    );
});

const CustomUserMenu = (props: any) => (
    <Fragment>
        {/*<ConfigurationMenu />*/}
        {/*<Button><NotificationsIcon /></Button>*/}
        <ConfigurationMenu {...props} icon={<UserProfileIcon />} >
            {/*<ConfigurationMenu />*/}
        </ConfigurationMenu>
    </Fragment>
);

const MyUserMenu = (props: any) => (<UserMenu {...props} icon={<UserProfileIcon />} />);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery('(min-width: 600px)');

    return (
        <KegstarAppBar {...props} userMenu={<CustomUserMenu />}>
            <AppLogo />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                // id="react-admin-title"
            />

            { isDesktop && ((localStorage.getItem('oUuid')) && <OrganisationHeader />) }

            <span className={classes.spacer} />

        </KegstarAppBar>
    );
};

export default CustomAppBar;
