import React, {
    HtmlHTMLAttributes,
    ReactNode,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    Card,
    Avatar,
    createMuiTheme,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';

import defaultTheme from '../layout/DefaultLayoutTheme';
import DefaultLoginForm from './KegstarLoginForm';
import { Notification } from 'react-admin';

interface Props {
    backgroundImage?: string;
    children: ReactNode;
    classes?: object;
    className?: string;
    staticContext?: StaticContext;
    theme: object;
}


const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: 'none'
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary.main,
        },
    }),
    { name: 'RaLogin' }
);

// @ts-ignore
const KegstarLogin: React.FunctionComponent<
    Props & HtmlHTMLAttributes<HTMLDivElement>
    > = ({
             theme,
             classes: classesOverride,
             className,
             children,
             staticContext,
             backgroundImage,
             ...rest
         }) => {
    const containerRef = useRef<HTMLDivElement>();
    const classes = useStyles({ classes: classesOverride });
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });



    return (
        <ThemeProvider theme={muiTheme}>
            <div
                {...rest}
                // @ts-ignore
                ref={containerRef}
            >
                {children}
                <Notification />
            </div>
        </ThemeProvider>
    );
};

// @ts-ignore
KegstarLogin.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

KegstarLogin.defaultProps = {
    backgroundImage: process.env.REACT_APP_LOGIN_BACKGROUND,
    theme: defaultTheme,
    children: <DefaultLoginForm />,
};

export default KegstarLogin;
