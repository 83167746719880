import React, {Fragment, useState} from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
    getRentalFees,
    displayCountryName,
    displayPackageDate,
    displayDetailsFee,
    displayDetailsMinimumFreightFee,
    displayDetailsTimedFeeDescription,
    displayDetailsPsaDescription,
    displayDetailsDay,
    displayRowName,
    displayRowNameHighlight,
    DisplayRow,
    DisplayDetailedTimedFeeSettings,
    highlightCurrentPsaDiscount, displayPsaDate
} from "./PackageFunctions";
import Chip from "@material-ui/core/Chip";
import WarningIcon from '@material-ui/icons/Warning';
import {styles} from "../PackageStyles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
const useStyles = makeStyles(styles);

const PackageDetailsContent = ({ type, name, packageDetails, isError }) => {
    const classes = useStyles();

    if (isError) {
        return (
            <Fragment>
                <Paper style={{color: 'rgb(102, 60, 0)', backgroundColor: 'rgb(255, 244, 229)', padding: '1em', display: 'flex'}}>
                    <div><WarningIcon /></div>
                    <div style={{padding: '3px', paddingLeft: '10px'}}> Unable to retrieve package information. Please try again later.</div>
                </Paper>
            </Fragment>
        )
    }

    const skuHeaders = ['KS20A', 'KS20S','KS30A','KS30D','KS30S','KS50A','KS50D','KS50S','KS1/2D','KS1/6D','KS9GC'];
    const typeOverrides = ['organisation', 'location'];

    const rentalFees = getRentalFees(packageDetails);
    const rentalCount = (packageDetails.rentalCounts) ? packageDetails.rentalCounts.previousQuarter : false;

    return (
        <Fragment>
            <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                <Grid item xs>
                    <Box m={2}>
                        <Divider className={classes.divider} />
                        <Typography variant={'h5'}> PACKAGE DETAILS </Typography>
                    </Box>
                </Grid>
            </Grid>
            <div style={{width: 'auto', overflowX: 'scroll', maxWidth: '100vw'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell colSpan={3} className={classes.tableDetailsHeaderIndicator}> </TableCell>
                                    <TableCell colSpan={6} className={classes.tableDetailsHeaderIndicatorRentals}>RENTALS </TableCell>
                                    <TableCell colSpan={13} className={classes.tableDetailsHeaderIndicatorFreight}>FREIGHT </TableCell>
                                    <TableCell colSpan={2} className={classes.tableDetailsHeaderIndicatorCollection}>COLLECTION </TableCell>
                                    <TableCell colSpan={1} className={classes.tableDetailsHeaderIndicatorTimed}>TIMED </TableCell>
                                    <TableCell colSpan={3} className={classes.tableDetailsHeaderIndicatorPSA}>PSA </TableCell>
                                    <TableCell colSpan={6} className={classes.tableDetailsHeaderIndicator}>PAYMENT TERMS </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.tableCellLabel}> </TableCell>
                                    <TableCell className={classes.tableCellLabel}>Start Date</TableCell>
                                    <TableCell className={classes.tableCellLabel}>End Date</TableCell>
                                    <TableCell className={classes.tableDetailsHeaderRentals}>SKU </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderRentals}>Issue Fee</TableCell>
                                    <TableCell className={classes.tableDetailsHeaderRentals}>Cleaning Fee</TableCell>
                                    <TableCell className={classes.tableDetailsHeaderRentals}>Daily Rental Fee    </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderRentals}>Taproom to Venue Fee   </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderRentals}>Taproom to Distributor Fee    </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Freight Fee - Capital Metro </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Minumum Freight Fee - Capital Metro </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Freight Fee -Regional Metro </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Minimum Freight Fee -Regional Metro </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Freight Fee - Regional </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Minimum Freight Fee- Regional </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Freight Fee - Super Regional </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Minimum Freight Fee - Super Regional </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Customer Pickup Fee </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Pallet Fee </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Express Fee </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Non-Mystar Fee </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderFreight}>Inter- Country Fee </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderCollection}>Collection Fee - Regional </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderCollection}>Collection Fee  - Super Regional </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderTimed}>Timed Fee </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderPSA}>PSA Bands </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderPSA}>PSA Start </TableCell>
                                    <TableCell className={classes.tableDetailsHeaderPSA}>PSA End </TableCell>
                                    <TableCell className={classes.tableCellLabel}>Order PSA Invoice </TableCell>
                                    <TableCell className={classes.tableCellLabel}>Order Non- PSA Invoice </TableCell>
                                    <TableCell className={classes.tableCellLabel}>Monthly PSA Invoice </TableCell>
                                    <TableCell className={classes.tableCellLabel}>Monthly Non- PSA Invoice </TableCell>
                                    {/*<TableCell className={classes.tableCellLabel}>Added By </TableCell>*/}
                                    {/*<TableCell className={classes.tableCellLabel}>Added On </TableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {/*{ packageDetails.packages.map( record => ( */}
                                {/* Country Level Package */}
                                    <TableRow>
                                        <TableCell> Base / Country </TableCell> {/* country */}
                                        <TableCell> { displayPackageDate(packageDetails.packages, 'country', 'startDate', false) } </TableCell> {/* startDate */}
                                        <TableCell> { displayPackageDate(packageDetails.packages, 'country', 'endDate', false) } </TableCell> {/* endDate */}
                                        <TableCell> - </TableCell> {/* skuName */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'issueFee', false) } </TableCell> {/* issueFee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'cleaningFee', false) }  </TableCell> {/* cleaningFee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'dailyRentalFee', false) } </TableCell> {/* dailyRentalFee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'taproomVenueFee', false) } </TableCell> {/* taproomToVenueFee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'taproomDistributorFee', false) } </TableCell> {/* taproomToDistributorFee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'freightCapitalMetroFee', false, false, '/freight') } </TableCell> {/* Freight Fee - Capital Metro */}
                                        <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, 'country', 'freightCapitalMetroFeeSettings', false, false,'/freight') } </TableCell> {/* Minumum Freight Fee - Capital Metro */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'freightRegionalMetroFee', false, false,'/freight') } </TableCell> {/* Freight Fee -Regional Metro */}
                                        <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, 'country', 'freightRegionalMetroFeeSettings', false, false,'/freight') } </TableCell> {/* Minimum Freight Fee -Regional Metro */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'freightRegionalFee', false, false,'/freight') } </TableCell> {/* Freight Fee - Regional */}
                                        <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, 'country', 'freightRegionalFeeSettings', false, false,'/freight') } </TableCell> {/* Minimum Freight Fee- Regional */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'freightSuperRegionalFee', false, false,'/freight') } </TableCell> {/* Freight Fee - Super Regional */}
                                        <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, 'country', 'freightSuperRegionalFeeSettings', false, false,'/freight') } </TableCell> {/* Minimum Freight Fee - Super Regional */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'freightPickUpFee', false, false,'/freight') } </TableCell> {/* Customer Pickup Fee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'palletFee', false, false,'/freight') } </TableCell> {/* Pallet Fee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'expressFee', false, false,'/freight') } </TableCell> {/* Express Fee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'nonMystarFee', false, false,'/freight') } </TableCell> {/* Non-Mystar Fee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'interCountryFee', false, false,'/freight') } </TableCell> {/* Inter- Country Fee */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'collectionRegionalFee', false, false,'/collection') } </TableCell> {/* Collection Fee - Regional */}
                                        <TableCell> { displayDetailsFee(packageDetails.packages, 'country', 'collectionSuperRegionalFee', false, false,'/collection') } </TableCell> {/* Collection Fee  - Super Regional */}
                                        <TableCell> { displayDetailsTimedFeeDescription(packageDetails.packages, 'country', 'timedFeeSettings', false, false, '/timed') } </TableCell> {/* Timed Fee */}
                                        <TableCell> { displayDetailsPsaDescription(packageDetails.packages, 'country', 'psaDiscountGroup', false, false, '/psa') } </TableCell> {/* PSA Bands */}
                                        <TableCell> { displayPsaDate(packageDetails.packages, 'country', 'psaStart', false, false, '/psa') } </TableCell> {/* PSA Bands */}
                                        <TableCell> { displayPsaDate(packageDetails.packages, 'country', 'psaEnd', false, false, '/psa') } </TableCell> {/* PSA Bands */}
                                        <TableCell> { displayDetailsDay(packageDetails.packages, 'country', 'orderPsaInvoicePaymentDays', false, false, '/payment') } </TableCell> {/* Order PSA Invoice */}
                                        <TableCell> { displayDetailsDay(packageDetails.packages, 'country', 'orderInvoicePaymentDays', false, false,'/payment') } </TableCell> {/* Order Non- PSA Invoice */}
                                        <TableCell> { displayDetailsDay(packageDetails.packages, 'country', 'monthlyPsaInvoicePaymentDays', false, false,'/payment') } </TableCell> {/* Monthly PSA Invoice */}
                                        <TableCell> { displayDetailsDay(packageDetails.packages, 'country', 'monthlyInvoicePaymentDays', false, false,'/payment') } </TableCell> {/* Monthly Non- PSA Invoice */}
                                        {/*<TableCell> SYSTEM </TableCell> /!* Added By *!/*/}
                                        {/*<TableCell> 01/06/19 </TableCell> /!* Added On *!/*/}
                                    </TableRow>
                                {/* Country Level Package */}
                                {/*) )}*/}

                                {/* Per Sku Package */}
                                { skuHeaders.map( skuHeader => (
                                <TableRow>
                                    <TableCell> { displayCountryName(packageDetails.packagesPerSku, 'all', true, skuHeader) } </TableCell> {/* country */}
                                    <TableCell> { displayPackageDate(packageDetails.packagesPerSku, 'all', 'startDate', true, skuHeader) } </TableCell> {/* startDate */}
                                    <TableCell> { displayPackageDate(packageDetails.packagesPerSku, 'all', 'endDate', true, skuHeader) } </TableCell> {/* endDate */}
                                    <TableCell> { skuHeader } </TableCell> {/* skuName */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'issueFee', true, skuHeader) } </TableCell> {/* issueFee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'cleaningFee', true, skuHeader) }  </TableCell> {/* cleaningFee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'dailyRentalFee', true, skuHeader) } </TableCell> {/* dailyRentalFee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'taproomVenueFee', true, skuHeader) } </TableCell> {/* taproomToVenueFee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'taproomDistributorFee', true, skuHeader) } </TableCell> {/* taproomToDistributorFee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'freightCapitalMetroFee', true, skuHeader, '/freight') } </TableCell> {/* Freight Fee - Capital Metro */}
                                    <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packagesPerSku, 'all', 'freightCapitalMetroFeeSettings', true, skuHeader, '/freight') } </TableCell> {/* Minumum Freight Fee - Capital Metro */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'freightRegionalMetroFee', true, skuHeader, '/freight') } </TableCell> {/* Freight Fee -Regional Metro */}
                                    <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packagesPerSku, 'all', 'freightRegionalMetroFeeSettings', true, skuHeader, '/freight') } </TableCell> {/* Minimum Freight Fee -Regional Metro */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'freightRegionalFee', true, skuHeader, '/freight') } </TableCell> {/* Freight Fee - Regional */}
                                    <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packagesPerSku, 'all', 'freightRegionalFeeSettings', true, skuHeader, '/freight') } </TableCell> {/* Minimum Freight Fee- Regional */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'freightSuperRegionalFee', true, skuHeader, '/freight') } </TableCell> {/* Freight Fee - Super Regional */}
                                    <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packagesPerSku, 'all', 'freightSuperRegionalFeeSettings', true, skuHeader, '/freight') } </TableCell> {/* Minimum Freight Fee - Super Regional */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'freightPickUpFee', true, skuHeader, '/freight') } </TableCell> {/* Customer Pickup Fee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'palletFee', true, skuHeader, '/freight') } </TableCell> {/* Pallet Fee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'expressFee', true, skuHeader, '/freight') } </TableCell> {/* Express Fee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'nonMystarFee', true, skuHeader, '/freight') } </TableCell> {/* Non-Mystar Fee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'interCountryFee', true, skuHeader, '/freight') } </TableCell> {/* Inter- Country Fee */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'collectionRegionalFee', true, skuHeader, '/collection') } </TableCell> {/* Collection Fee - Regional */}
                                    <TableCell> { displayDetailsFee(packageDetails.packagesPerSku, 'all', 'collectionSuperRegionalFee', true, skuHeader, '/collection') } </TableCell> {/* Collection Fee  - Super Regional */}
                                    <TableCell> { displayDetailsTimedFeeDescription(packageDetails.packagesPerSku, 'all', 'timedFeeSettings', true, skuHeader, '/timed') } </TableCell> {/* Timed Fee */}
                                    <TableCell> { displayDetailsPsaDescription(packageDetails.packagesPerSku, 'all', 'psaDiscountGroup', true, skuHeader, '/psa') } </TableCell> {/* PSA Bands */}
                                    <TableCell> { displayPsaDate(packageDetails.packagesPerSku, 'all', 'psaStart', true, skuHeader, '/psa') } </TableCell> {/* PSA Bands */}
                                    <TableCell> { displayPsaDate(packageDetails.packagesPerSku, 'all', 'psaEnd', true, skuHeader, '/psa') } </TableCell> {/* PSA Bands */}
                                    <TableCell> { displayDetailsDay(packageDetails.packagesPerSku, 'all', 'orderPsaInvoicePaymentDays', true, skuHeader, '/payment') } </TableCell> {/* Order PSA Invoice */}
                                    <TableCell> { displayDetailsDay(packageDetails.packagesPerSku, 'all', 'orderInvoicePaymentDays', true, skuHeader, '/payment') } </TableCell> {/* Order Non- PSA Invoice */}
                                    <TableCell> { displayDetailsDay(packageDetails.packagesPerSku, 'all', 'monthlyPsaInvoicePaymentDays', true, skuHeader, '/payment') } </TableCell> {/* Monthly PSA Invoice */}
                                    <TableCell> { displayDetailsDay(packageDetails.packagesPerSku, 'all', 'monthlyInvoicePaymentDays', true, skuHeader, '/payment') } </TableCell> {/* Monthly Non- PSA Invoice */}
                                    {/*<TableCell> SYSTEM </TableCell> /!* Added By *!/*/}
                                    {/*<TableCell> 01/06/19 </TableCell> /!* Added On *!/*/}
                                </TableRow>
                                ) )}
                                {/* Per Sku Package */}

                                {/* Override Level Package(s) */}
                                { typeOverrides.map( typeOverride => (
                                <TableRow>
                                    {  DisplayRow(typeOverride, type) && <TableCell className={ displayRowNameHighlight(typeOverride, classes) }> { displayRowName(typeOverride, type, name) } </TableCell> } {/* country */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayPackageDate(packageDetails.packages, typeOverride, 'startDate', false) } </TableCell> } {/* startDate */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayPackageDate(packageDetails.packages, typeOverride, 'endDate', false) } </TableCell> } {/* endDate */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> - </TableCell> } {/* skuName */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'issueFee', false) } </TableCell> } {/* issueFee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'cleaningFee', false) }  </TableCell> } {/* cleaningFee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'dailyRentalFee', false) } </TableCell> } {/* dailyRentalFee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'taproomVenueFee', false) } </TableCell> } {/* taproomToVenueFee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'taproomDistributorFee', false) } </TableCell> } {/* taproomToDistributorFee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'freightCapitalMetroFee', false, false, '/freight') } </TableCell> } {/* Freight Fee - Capital Metro */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, typeOverride, 'freightCapitalMetroFeeSettings', false,  false,'/freight') } </TableCell> } {/* Minumum Freight Fee - Capital Metro */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'freightRegionalMetroFee', false,  false,'/freight') } </TableCell> } {/* Freight Fee -Regional Metro */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, typeOverride, 'freightRegionalMetroFeeSettings',  false,false, '/freight') } </TableCell> } {/* Minimum Freight Fee -Regional Metro */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'freightRegionalFee', false,  false,'/freight') } </TableCell> } {/* Freight Fee - Regional */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, typeOverride, 'freightRegionalFeeSettings', false,  false,'/freight') } </TableCell> } {/* Minimum Freight Fee- Regional */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'freightSuperRegionalFee', false,  false,'/freight') } </TableCell> } {/* Freight Fee - Super Regional */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsMinimumFreightFee(packageDetails.packages, typeOverride, 'freightSuperRegionalFeeSettings', false,  false,'/freight') } </TableCell> } {/* Minimum Freight Fee - Super Regional */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'freightPickUpFee', false,  false,'/freight') } </TableCell> } {/* Customer Pickup Fee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'palletFee', false,  false,'/freight') } </TableCell> } {/* Pallet Fee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'expressFee', false,  false,'/freight') } </TableCell> } {/* Express Fee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'nonMystarFee', false,  false,'/freight') } </TableCell> } {/* Non-Mystar Fee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'interCountryFee', false,  false,'/freight') } </TableCell> } {/* Inter- Country Fee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'collectionRegionalFee', false,  false,'/collection') } </TableCell> } {/* Collection Fee - Regional */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsFee(packageDetails.packages, typeOverride, 'collectionSuperRegionalFee', false,  false,'/collection') } </TableCell> } {/* Collection Fee  - Super Regional */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsTimedFeeDescription(packageDetails.packages, typeOverride, 'timedFeeSettings', false, false, '/timed') } </TableCell> } {/* Timed Fee */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsPsaDescription(packageDetails.packages, typeOverride, 'psaDiscountGroup', false,  false,'/psa') } </TableCell> } {/* PSA Bands */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayPsaDate(packageDetails.packages, typeOverride, 'psaStart', false,  false,'/psa') } </TableCell> } {/* PSA Bands */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayPsaDate(packageDetails.packages, typeOverride, 'psaEnd', false,  false,'/psa') } </TableCell> } {/* PSA Bands */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsDay(packageDetails.packages, typeOverride, 'orderPsaInvoicePaymentDays', false,  false, '/payment') } </TableCell> } {/* Order PSA Invoice */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsDay(packageDetails.packages, typeOverride, 'orderInvoicePaymentDays', false,  false, '/payment') } </TableCell> } {/* Order Non- PSA Invoice */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsDay(packageDetails.packages, typeOverride, 'monthlyPsaInvoicePaymentDays', false,  false, '/payment') } </TableCell> } {/* Monthly PSA Invoice */}
                                    {  DisplayRow(typeOverride, type) && <TableCell> { displayDetailsDay(packageDetails.packages, typeOverride, 'monthlyInvoicePaymentDays', false,  false, '/payment') } </TableCell> } {/* Monthly Non- PSA Invoice */}
                                    {/*<TableCell> SYSTEM </TableCell> /!* Added By *!/*/}
                                    {/*<TableCell> 01/06/19 </TableCell> /!* Added On *!/*/}
                                </TableRow>
                                ) )}
                                {/* Override Level Package(s) */}

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

                <Box m={3}></Box>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Box m={2}>
                            <Typography variant={'h5'}> TIMED FEES </Typography>
                        </Box>
                        <Table>
                            <TableBody>
                                <DisplayDetailedTimedFeeSettings packages={packageDetails.packages} classes={classes}/>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

                <Box m={3}></Box>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Box m={2}>
                            <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                                <Typography variant={'h5'}> PSA DISCOUNTS </Typography>
                            </Grid>
                            <Grid container alignItems="flex-end" justify="flex-end" direction="row">
                                { (rentalCount > 0) &&
                                <Chip variant="outlined" style={{backgroundColor: '#FEDB00'}} label={'Current PSA'}/>}
                            </Grid>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell colSpan={4}> Name </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} style={{ fontSize: 'larger', color: '#000'}}> {
                                        ( packageDetails.packageSetGroup.psaDiscountGroup)
                                            ? packageDetails.packageSetGroup.psaDiscountGroup.description : null} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Lower Bound</TableCell>
                                    <TableCell>Upper Bound</TableCell>
                                    <TableCell>Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (packageDetails.packageSetGroup.psaDiscountGroup)
                                        ?
                                        packageDetails.packageSetGroup.psaDiscountGroup.psaDiscounts.map(
                                            item => {
                                                let currentPsaDiscount = rentalCount ? highlightCurrentPsaDiscount(rentalCount, item.lowerBound, item.upperBound) : false;
                                                return (
                                                    <TableRow key={item.uuid}
                                                              className={currentPsaDiscount
                                                                  ? classes.highlightCurrentPsaDiscountRow : null}>
                                                        <TableCell>{item.description}</TableCell>
                                                        <TableCell>{item.lowerBound}</TableCell>
                                                        <TableCell>{item.upperBound}</TableCell>
                                                        <TableCell>{item.percentage}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        )
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4}>No PSA Discounts</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

export default PackageDetailsContent;
