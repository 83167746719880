import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SearchInput, FunctionField, Pagination
} from 'react-admin';
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import Flag from "react-world-flags";
import CountryArray from "../../components/CountryArray";
import ShowButton from "../../components/button/ShowButton";
import KegstarListActions from "../../components/list/KegstarListActions";
import KegstarFilter from "../../components/list/KegstarFilter";
import {makeStyles} from "@material-ui/core";

const ListFilters = props => (
    <KegstarFilter {...props}>
        <SearchInput className={'searchInputBaseClass'} source="q" alwaysOn style={{width: '20em'}} />
    </KegstarFilter>
);

export const CountryFlag = ({ record, height }) => {
    let name = record.name.toUpperCase();
    try {
        if (typeof CountryArray[name] !== 'undefined' && CountryArray[name] !== null) {
            return <Flag code={ CountryArray[name] } height={(height) ? height : '100%'} />;
        }
    } catch (e) {
        return null;
    }
    return null;
}

const useStyles = makeStyles(theme => ({
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    }
}));

const CountryList = ({ ...props }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'C'} title={'Countries'} subheader={'List of Countries'}/>

                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <List {...props} filters={<ListFilters />}
                          bulkActionButtons={false}
                          sort={{ field: 'id', order: 'ASC' }}
                          filter={{ countryUuid: 'all' }} perPage={250}
                          actions={<KegstarListActions />} exporter={false} pagination={false}
                    >
                        <Datagrid rowClick={'show'} className={localStorage.getItem('userRole')}>
                            <TextField emptyText source="code" label={'Id'}/>
                            <FunctionField render={record => <CountryFlag record={record} height={'35px'} /> } addLabel={false} />
                            <TextField emptyText source="name" label={'Name'} />

                            {/*<FunctionField render={record => <Link href={`/#/packages?filter=%7B"countryUuid"%3A"${record.uuid}"%7D&order=DESC&page=1&perPage=10&sort=createdAt`} alt={''}><SidebarPackagesMenuIcon /></Link>} label={'Packages'} />*/}
                            {/*<FunctionField render={record => <Link href={`/#/packages/details?filter={"countryUuid":"${record.uuid}"}`} alt={''}><PackageDetailsIcon style={{color: '#000'}}/></Link>} label={'Pricing Model'} />*/}
                            <ShowButton variant={'none'} color={'primary'} label={''} />
                        </Datagrid>
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default CountryList;
