import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryWithStore, Error } from 'react-admin';
import KegstarLinearProgress from "../components/layout/KegstarLinearProgress";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    avatar: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
        display: 'inline',
        padding: '5px',
        margin: '5px;',
    },
    organisationHeader: {
        fontSize: '30px',
        fontFamily: 'NettoOT Bold'
    }
}));

export default function OrganisationHeader() {
    const classes = useStyles();

    const { loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'organisations',
        payload: { id: localStorage.getItem('oUuid') }
    });

    if (!loaded) { return <KegstarLinearProgress />; }
    if (error) return <Error />;
    if (!data) return null;

    return (
        <Fragment>
            <div id="organisationHeader" className={classes.organisationHeader}>{data.name}</div>
        </Fragment>
    );
}
