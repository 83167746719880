import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import BillingReviewIcon from '@material-ui/icons/Assessment';
import {useMediaQuery, Theme, makeStyles} from '@material-ui/core';
import { useTranslate, MenuItemLink } from 'react-admin';
import { AppState } from '../types';
import charges from '../charges/mystarindex';
import rentals from '../rentals';
import SidebarInvoicesMenuIcon from "../components/icons/SidebarInvoicesMenuIcon";
import SidebarChargesMenuIcon from "../components/icons/SidebarChargesMenuIcon";
import SidebarRentalsMenuIcon from "../components/icons/SidebarRentalsMenuIcon";
import DashboardIcon from '@material-ui/icons/Dashboard';
import WeeklyIcon from '@material-ui/icons/DateRange';
import MonthlyIcon from '@material-ui/icons/Event';

import { styles } from './Menu';
import SubMenu from "./SubMenu";
import SidebarPackagesMenuIcon from "../components/icons/SidebarPackagesMenuIcon";

const useStyles = makeStyles(styles);

type MenuName = 'menuSummaries';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        menuSummaries: false,
        menuOrders: false,
        menuBilling: false,
        menuReports: false,
        menuAccount: false
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>

            { (process.env.REACT_APP_COUNTRY_UUID_USA !== localStorage.getItem('cUuid')) &&
            <SubMenu
                handleToggle={() => handleToggle('menuSummaries')}
                isOpen={state.menuSummaries}
                sidebarIsOpen={open}
                name="app.menu.mystar.summaries"
                icon={<DashboardIcon style={{color: '#000'}}/>}
                dense={dense}
            >
                <MenuItemLink
                    to={`/summaries/weekly`}
                    primaryText={'Weekly'}
                    leftIcon={<WeeklyIcon style={{color: '#000'}}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />
                <MenuItemLink
                    to={`/summaries/monthly`}
                    primaryText={'Monthly'}
                    leftIcon={<MonthlyIcon style={{color: '#000'}}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />
            </SubMenu>
            }

            <MenuItemLink
                to={`/invoices`}
                primaryText={'Invoices'}
                leftIcon={<SidebarInvoicesMenuIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                className={classes.menuItemLink}
            />

            {/*<MenuItemLink*/}
            {/*    to={`/report/rentals`}*/}
            {/*    primaryText={'Rentals'}*/}
            {/*    leftIcon={<SidebarRentalsMenuIcon />}*/}
            {/*    onClick={onMenuClick}*/}
            {/*    sidebarIsOpen={open}*/}
            {/*    dense={dense}*/}
            {/*    className={classes.menuItemLink}*/}
            {/*/>*/}

            {isXSmall && logout}
        </div>
    );
};

export default Menu;
