import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import OpeningBalanceImage from "../components/images/summaries/OpeningBalanceImage";
import NewCyclesImage from "../components/images/summaries/NewCyclesImage";
import ClosedCyclesImage from "../components/images/summaries/ClosedCyclesImage";
import ExpiredCyclesImage from "../components/images/summaries/ExpiredCyclesImage";
import ClosingBalanceImage from "../components/images/summaries/ClosingBalanceImage";
import KegCycleStatusImage from "../components/images/summaries/KegCycleStatusImage";
import KegCycleImage from "../components/images/summaries/KegCycleImage";
import KegOrderImage from "../components/images/summaries/KegOrderImage";
import AverageCostPerKegImage from "../components/images/summaries/AverageCostPerKegImage";
import ScanRateImage from "../components/images/summaries/ScanRateImage";
import IncreaseImage from '@material-ui/icons/ArrowDropUp';
import DecreaseImage from '@material-ui/icons/ArrowDropDown';

import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import {styles} from "./SummaryStyles";
import AverageCycleTimeLineGraph from "./AverageCycleTimeLineGraph";
import CycleTimeImage from "../components/images/summaries/CycleTimeImage";
import {Link} from "react-admin";
import {Link as ALink} from "@material-ui/core";

import {getRentalsFromMonthlySummaryLink} from "./MonthlyDates";

const useStyles = makeStyles(styles);

const MonthlySummaryContent = ({record, organisation, date}) => {

    const classes = useStyles();

    return (
        <Fragment>
            <Card className={classes.cardContainer}>
                <Paper className={classes.paperContainer}>
                    <Box m={2}>
                        <div><Divider className={classes.divider}/></div>
                        {/*<div style={{fontSize: '30px', fontWeight: 'bold'}}>*/}
                        {/*    { record.name }*/}
                        {/*</div>*/}
                        <div style={{fontSize: '30px'}}>
                            { record.period }
                        </div>
                    </Box>
                    <Box m={12}></Box>
                </Paper>

                <CardContent className={classes.cardContentContainer}>
                    <Grid container>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                            <Typography variant={'h5'} style={{fontWeight: 'bold'}}>Keg Balance</Typography>
                            <Box m={8}></Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={2} className={'App-gridContainerSummariesTotal'}>
                            <Table className={classes.table}>
                                <TableRow>
                                    <TableCell className={classes.tableCellIcon}><OpeningBalanceImage /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellTitle}>Opening Balance</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellBalanceSummary}>{ record.billingCycles.total.openingBalance.toLocaleString() }</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} className={'App-gridContainerSummariesTotal'}>
                            <Table className={classes.table}>
                                <TableRow>
                                    <TableCell className={classes.tableCellIcon}><NewCyclesImage /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellTitle} >New Cycles</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellBalanceSummary}>{ record.billingCycles.total.newCycles.toLocaleString() }</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} className={'App-gridContainerSummariesTotal'}>
                            <Table className={classes.table}>
                                <TableRow>
                                    <TableCell className={classes.tableCellIcon}><ClosedCyclesImage /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellTitle}>Closed Cycles</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellBalanceSummary}>{ record.billingCycles.total.closedCycles.toLocaleString() }</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} className={'App-gridContainerSummariesTotal'}>
                            <Table className={classes.table}>
                                <TableRow>
                                    <TableCell className={classes.tableCellIcon}><ExpiredCyclesImage /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellTitle}>Expired Cycles</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellBalanceSummary}>{ record.billingCycles.total.expiredCycles.toLocaleString() }</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} className={'App-gridContainerSummariesTotal'}>
                            <Table className={classes.table}>
                                <TableRow>
                                    <TableCell className={classes.tableCellIcon}><ClosingBalanceImage /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellTitle}>Closing Balance</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellBalanceSummary}>{ record.billingCycles.total.closingBalance.toLocaleString() }</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                    </Grid>

                    <Box m={3}></Box>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            { (record.billingCycles.perSku.length > 0) &&
                            <Table className={classes.table} id={'kegBalanceTable'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Keg Type</TableCell>
                                        <TableCell className={classes.tableCellLabel}>Opening Balance</TableCell>
                                        <TableCell className={classes.tableCellLabel}>New Cycles</TableCell>
                                        <TableCell className={classes.tableCellLabel}>Closed Cycles</TableCell>
                                        <TableCell className={classes.tableCellLabel}>Expired Cycles</TableCell>
                                        <TableCell className={classes.tableCellLabel}>Closing Balance</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    { record.billingCycles.perSku.map(
                                        perSku => (
                                            <TableRow>
                                                <TableCell className={classes.tableCell}>{ perSku.skuDescription }</TableCell>
                                                <TableCell className={classes.tableCell}>{ perSku.openingBalance.toLocaleString() }</TableCell>
                                                <TableCell className={classes.tableCell}>{ perSku.newCycles.toLocaleString() }</TableCell>
                                                <TableCell className={classes.tableCell}>{ perSku.closedCycles.toLocaleString() }</TableCell>
                                                <TableCell className={classes.tableCell}>{ perSku.expiredCycles.toLocaleString() }</TableCell>
                                                <TableCell className={classes.tableCell}>{ perSku.closingBalance.toLocaleString() }</TableCell>
                                            </TableRow>
                                        )
                                    )}

                                </TableBody>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellBalanceTotal}>Total</TableCell>
                                        <TableCell className={classes.tableCellBalanceTotal}>{ record.billingCycles.total.openingBalance.toLocaleString() }</TableCell>
                                        <TableCell className={classes.tableCellBalanceTotal}>{ record.billingCycles.total.newCycles.toLocaleString() }</TableCell>
                                        <TableCell className={classes.tableCellBalanceTotal}>{ record.billingCycles.total.closedCycles.toLocaleString() }</TableCell>
                                        <TableCell className={classes.tableCellBalanceTotal}>{ record.billingCycles.total.expiredCycles.toLocaleString() }</TableCell>
                                        <TableCell className={classes.tableCellBalanceTotal}>{ record.billingCycles.total.closingBalance.toLocaleString() }</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            }
                        </Grid>
                    </Grid>

                    <Box m={8}></Box>

                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                            <Typography variant={'h5'} style={{fontWeight: 'bold'}}>Keg Cycles</Typography>
                        </Grid>
                    </Grid>
                    <Box m={8}></Box>

                    <Grid container spacing={2}>

                        <Grid item xs sm={6} md={6}>

                            <Grid container spacing={2}>

                                <Grid item xs>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}>
                                                    <div className={classes.averageLabels}>Average Cycle Time</div>
                                                    <span style={{float: 'right'}}><CycleTimeImage /></span></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellSubTitle} colSpan={3}>This month <br/>
                                                    <AverageCycleTimeLineGraph color={ '#DE3329' } record={record.averageCycleTime}
                                                                               value={ record.averageCycleTime.currentMonthInDays } maxValue={30} label={ record.averageCycleTime.currentMonthInDays.toString() + ' days'} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellSubTitle} colSpan={3}>Last month <br/>
                                                    <AverageCycleTimeLineGraph color={'#6AB335'} record={record.averageCycleTime}
                                                                               value={ record.averageCycleTime.previousMonthInDays }
                                                                               maxValue={30} label={record.averageCycleTime.previousMonthInDays + ' days'} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellSubTitle} colSpan={3}>Year to date <br/>
                                                    <AverageCycleTimeLineGraph record={record.averageCycleTime}
                                                                               value={ record.averageCycleTime.previousYearInDays } maxValue={356} label={record.averageCycleTime.previousYearInDays + ' days'} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Box m={3}></Box>
                                    {/*<Box m={1}>*/}
                                    {/*    <Typography gutterBottom>Your current average keg cycle time exceeds last month.</Typography>*/}
                                    {/*</Box>*/}

                                    {/* @todo Uncomment when summaries are available */}
                                    {/*<Box m={3}></Box>*/}
                                    {/*<Table className={classes.table}>*/}
                                    {/*    <TableBody>*/}
                                    {/*        <TableRow>*/}
                                    {/*            <TableCell className={classes.tableCellTitle} colSpan={3}>Average Cost Per Keg<span style={{float: 'right'}}><AverageCostPerKegImage /></span></TableCell>*/}
                                    {/*        </TableRow>*/}
                                    {/*        <TableRow>*/}
                                    {/*            <TableCell className={classes.tableCellTitle} colSpan={3}><span style={{color: 'green', fontSize: '80px'}}>{ record.organisationCurrency }{ record.averageKegCost.currentMonth.toLocaleString() }</span> <span>{ record.averageKegCost.currentMonthDelta.toLocaleString() }</span> </TableCell>*/}
                                    {/*        </TableRow>*/}
                                    {/*    </TableBody>*/}
                                    {/*</Table>*/}

                                    {/*<Box m={3}></Box>*/}
                                    {/*<Box m={1}>*/}
                                    {/*    <Typography gutterBottom>Your average cost per keg changed by { record.organisationCurrency }{ record.averageKegCost.currentMonthDelta.toLocaleString() } this month.</Typography>*/}
                                    {/*</Box>*/}
                                    {/* @todo Uncomment when summaries are available */}

                                </Grid>

                            </Grid>
                        </Grid>
                        {  ( process.env.REACT_APP_COUNTRY_UUID_USA !== localStorage.getItem('cUuid') &&
                            ( process.env.REACT_APP_COUNTRY_UUID_UK !== localStorage.getItem('cUuid') ) ) &&
                            <Grid item xs sm={6} md={6}>
                                <Table className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tableCellTitle} colSpan={3}>Keg Cycle
                                                Status<span style={{float: 'right'}}><KegCycleImage/></span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tableCellBannerImage}
                                                       colSpan={3}><KegCycleStatusImage/></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}>At the brewery</TableCell>
                                            <TableCell className={classes.tableCell}>In transit</TableCell>
                                            <TableCell className={classes.tableCell}>Pouring at venues</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tableCellKegCycleStatusData}>{record.rentals.atProducer.toLocaleString()}</TableCell>
                                            <TableCell
                                                className={classes.tableCellKegCycleStatusData}>{record.rentals.atDistributor.toLocaleString()}</TableCell>
                                            <TableCell
                                                className={classes.tableCellKegCycleStatusData}>{record.rentals.atVenue.toLocaleString()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Box m={3}></Box>
                            </Grid>
                        }
                    </Grid>

                    <Box m={8}></Box>

                    <Grid container spacing={2}>

                        <Grid item xs sm={6} md={6}>
                            <Divider className={classes.divider} />
                            <Typography variant={'h5'} style={{fontWeight: 'bold'}}>Scan Rates</Typography>
                            <Box m={8}></Box>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}>
                                                    <div className={classes.scanLabels}>Your Scan Rate</div>
                                                    <span style={{float: 'right'}}><ScanRateImage /></span></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}>
                                                    <span style={deltaStyle(record.scans.producerDeltaSign)}><span style={{fontSize: '80px'}}>{ record.scans.producerScanRate.toLocaleString() }% </span></span>
                                                    <div style={{display: 'inline'}}>
                                                        <DisplayDeltaArrows deltaSign={record.scans.producerDeltaSign} />
                                                        { Math.abs(record.scans.producerScanRateDelta) }%
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Box m={3}></Box>
                                    <Box m={1}>
                                        <Typography gutterBottom>Your Scan Rate has moved by <span style={deltaStyle(record.scans.producerDeltaSign)}>{ Math.abs(record.scans.producerScanRateDelta) }%</span> this month.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}>
                                                    <div className={classes.scanLabels}>Distributor Scan Rate</div>
                                                    <span style={{float: 'right'}}><ScanRateImage /></span></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}>
                                                    <span style={deltaStyle(record.scans.distributorDeltaSign)}><span style={{fontSize: '80px'}}>{ record.scans.distributorScanRate.toLocaleString() }% </span></span>
                                                    <div style={{display: 'inline'}}>
                                                        <DisplayDeltaArrows deltaSign={record.scans.distributorDeltaSign} />
                                                        { Math.abs(record.scans.distributorScanRateDelta) }%
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Box m={3}></Box>
                                    <Box m={1}>
                                        <Typography gutterBottom>Your Scan Rate has moved by <span style={deltaStyle(record.scans.distributorDeltaSign)}>{ Math.abs(record.scans.distributorScanRateDelta) }%</span> this month.</Typography>
                                    </Box>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item xs sm={6} md={6}>
                            <Divider className={classes.divider} />
                            <Typography variant={'h5'} style={{fontWeight: 'bold'}}>Keg Orders</Typography>
                            <Box m={8}></Box>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}>
                                                    <div className={classes.scanLabels}>Kegs This Month</div>
                                                    <span style={{float: 'right'}}><KegOrderImage /></span></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}><span style={{color: 'green', fontSize: '80px'}}>{ record.orders.completedOrders.toLocaleString() }</span> kegs</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Box m={3}></Box>
                                    <Box m={1}>
                                        <Typography gutterBottom>You’ve ordered <span style={{color: 'green', fontWeight: 'bold'}}>{ record.orders.completedOrders.toLocaleString() }</span> kegs this month.</Typography>
                                    </Box>
                                    {/*<Box m={1}>*/}
                                    {/*    <Typography gutterBottom>*/}
                                    {/*        <span style={{color: 'blue', fontWeight: 'bold'}}>*/}
                                    {/*                <Link to={ getRentalsFromMonthlySummaryLink(organisation, date) } >*/}
                                    {/*                    > Find out more*/}
                                    {/*                </Link>*/}
                                    {/*        </span>*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}
                                </Grid>
                                <Grid item xs>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}>
                                                    <div className={classes.scanLabels}>Kegs Next Month</div>
                                                    <span style={{float: 'right'}}><KegOrderImage /></span></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellTitle} colSpan={3}><span style={{color: 'green', fontSize: '80px'}}>{ record.orders.pendingOrders.toLocaleString() }</span> kegs</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Box m={3}></Box>
                                    <Box m={1}>
                                        <Typography gutterBottom>You’ve forecast <span style={{color: 'green', fontWeight: 'bold'}}>{ record.orders.pendingOrders.toLocaleString() }</span> kegs for next month.</Typography>
                                    </Box>
                                    <Box m={1}>
                                        <Typography gutterBottom>
                                            <span style={{color: 'blue', fontWeight: 'bold'}}>
                                                    <ALink href={process.env.REACT_APP_MYSTAR_URL+'/orders/new'} rel="noreferrer">
                                                        > Place an order
                                                    </ALink>
                                            </span>
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>

        </Fragment>
    );
};

const DisplayDeltaArrows = ({deltaSign}) => {
    let image = null;
    if (deltaSign === 'Increase') {
        image = <IncreaseImage style={{color: 'green', padding: 0, margin: '-5px'}} fontSize={'large'} />;
    } else if (deltaSign === 'Decrease') {
        image = <DecreaseImage style={{color: 'red', padding: 0, margin: '-5px'}} fontSize={'large'} />;
    }
    return image;
}

const deltaStyle = (deltaSign) => {
    let style = null;
    if (deltaSign === 'Increase') {
        style={color: 'green'};
    } else if (deltaSign === 'Decrease') {
        style={color: 'red'};
    }
    return style;
}

export default MonthlySummaryContent;
