import React, {Fragment, useEffect, useState} from "react";
import MonthlySummaryContent from "./MonthlySummaryContent";
import {Error, usePermissions} from "react-admin";
import axios from "axios";
import KegstarDataLoader from "../components/loaders/KegstarDataLoader";
import Grid from "@material-ui/core/Grid";
import MonthlySummaryListSelect from "./MonthlySummaryListSelect";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {isCustomer} from "../security/Role";

const GetMonthlySummaryData = ({organisation, date}) => {

    let apiParameter = '';

    if(date) {
        apiParameter = 'monthly/'+date;
    } else {
        apiParameter = 'current-month';
    }

    let url = process.env.REACT_APP_API_URL + `/organisation/${organisation}/summary-reports/`+apiParameter;

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            try {
                const result = await axios(url, { headers: { 'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-api-key': localStorage.getItem('apiKey')
                    } });

                setData(result.data);
            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [url]);

    if (isLoading) return <KegstarDataLoader />;
    // if (isError) return <Error />;
    if (!data) return null;

    return (
        <MonthlySummaryContent record={data} organisation={organisation} date={date} />
    )
};
const useStyles = makeStyles(theme => ({
    gridContainer: {
        backgroundColor: theme.palette.secondary.light,
    },
    divider: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        margin: 0, padding: 0
    }
}));
const MonthlySummary = ({organisation, dateOfTheMonth}) => {
    const classes = useStyles();
    const [date, setDate] = useState(dateOfTheMonth);
    const {permissions} = usePermissions();
    return (
        <Fragment>
            <Box m={isCustomer(permissions) ? 2 : 0}>
                <Box m={1}></Box>
                <div><Divider className={classes.divider}/></div>
                <div style={{fontSize: '30px',fontWeight: 'bold', marginBottom: '1rem'}}>
                    {'Monthly Summary'}
                </div>
            </Box>
            <Grid container alignItems="flex-start" justify="flex-start" direction="row"  className={classes.gridContainer}>

                <MonthlySummaryListSelect organisation={organisation} setDate={setDate} />

            </Grid>
            <GetMonthlySummaryData organisation={organisation} date={date} />
        </Fragment>
    );
};

export default MonthlySummary;
