import React, {Fragment} from 'react';
import {
    ListController,
    ListView,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    Filter,
    FunctionField, DateInput
} from 'react-admin';

import {PackageDetailsPanel} from "../components/PackageDetailsPanel";
import KegnovaDatePickerInput from "../components/KegnovaDatePickerInput";
import {makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import {PackageListRowStyle} from "../components/Utilities";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    packageTypeFilterSmall: {
        width: '12em'
    },
    packageTypeFilterLarge: {
        width: '20em'
    }
}));

const ListFilters = props => {
    const classes = useStyles();
    return (
        <Filter {...props}>

            <ReferenceInput source="countryUuid" reference="countries" className={classes.packageTypeFilterSmall} label={'Country'}
                            sort={{ field: 'id', order: 'ASC' }} >
                <KegstarAutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="organisationUuid" reference="organisations" className={classes.packageTypeFilterLarge} label={'Organisation'} >
                <KegstarAutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="locationUuid" reference="locations" className={classes.packageTypeFilterLarge} label={'Location'} >
                <KegstarAutocompleteInput optionText="locationName" />
            </ReferenceInput>
            <ReferenceInput source="skuUuid" reference="skus" label={'Sku'}>
                <KegstarAutocompleteInput optionText="name" />
            </ReferenceInput>

            {/*<KegnovaDatePickerInput source="createdAt[from]" label={'Created Date From'} />*/}
            {/*<KegnovaDatePickerInput source="createdAt[to]" label={'Created Date To'} />*/}

            <DateInput source="createdAt" label={'Created Date'} />

        </Filter>
)
}

const PackageSetGroupList = (props) => {

    return(
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'P'} title={'Packages'} subheader={'List of Packages'}/>
                <CardContent>
                    {/* Content Start */}
                    <ListController {...props} sort={{ field: 'createdAt', order: 'DESC' }} >
                        {controllerProps =>
                            <ListView {...props} {...controllerProps} filters={<ListFilters />} bulkActionButtons={false} >
                                <Datagrid rowClick="expand" expand={<FunctionField render={record => <PackageDetailsPanel record={record} /> } addLabel={false} />}
                                          rowStyle={PackageListRowStyle}>

                                    <TextField emptyText source={'displayId'} label={'Package Id'}/>
                                    <DateField source="startDate"   />
                                    <DateField source="endDate"  />

                                    <ReferenceField source="skuUuid" reference="skus" link={false} label={'Sku'} allowEmpty>
                                        <TextField emptyText source="name" />
                                    </ReferenceField>

                                    <ReferenceField source="countryUuid" reference="countries" link="show" label={'Country'} >
                                        <TextField emptyText source="name"/>
                                    </ReferenceField>

                                    <ReferenceField source="organisationUuid" reference="organisations" link="show" label={'Organisation'} >
                                        <TextField emptyText source="name"/>
                                    </ReferenceField>

                                    <ReferenceField source="locationUuid" reference="locations" link="show" label={'Location'} >
                                        <TextField emptyText source="locationName"/>
                                    </ReferenceField>

                                </Datagrid>
                            </ListView>
                        }
                    </ListController>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
}

export default PackageSetGroupList;
