import React, { Fragment } from 'react';
import {
    Datagrid, List, ReferenceInput, Filter, ReferenceField, DateField, NumberField,
    FunctionField, AutocompleteInput, SelectInput, usePermissions
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import InvoiceShow from "./MystarInvoiceShow";
import {InvoiceTypeName, InvoiceListRowStyle} from "../../components/Utilities";
import Link from "@material-ui/core/Link";
import {InvoicePDFButton} from "../../components/InvoicePDFButton";
import KegnovaDatePickerInput from "../../components/KegnovaDatePickerInput";
import ShowButton from "../../components/button/ShowButton";
import KegstarListActions from "../../components/list/KegstarListActions";
import KegstarFilter from "../../components/list/KegstarFilter";
import KegstarAutocompleteInput from "../../components/input/KegstarAutocompleteInput";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    organisationFilter: {
        width: '20em'
    }
}));

const ListFilters = ({classes, permissions, ...props}) => (
    <KegstarFilter {...props} >

        <ReferenceInput source="orderUuid" reference="orders" label={'Order'} className={classes.organisationFilter} allowEmpty>
            <KegstarAutocompleteInput optionText={'tracksOrderId'} matchSuggestion={(val) => { return (val) ? val.toString() : '' }} />
        </ReferenceInput>

        <KegnovaDatePickerInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn/>
        <KegnovaDatePickerInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn />

        <KegnovaDatePickerInput source="dueDate[from]" label={'Due Date From'} />
        <KegnovaDatePickerInput source="dueDate[to]" label={'Due Date To'}  />

        <SelectInput source="type" label={'Invoice Type'} choices={[
            { id: 1, name: 'Order Invoice' },
            { id: 2, name: 'Monthly Invoice' }
        ]} options={{variant:'outlined'}}/>

    </KegstarFilter>
);

const InvoiceList = props => {
    const { permissions } = usePermissions();
    const classes = useStyles();
    return (
        <Fragment>
            <List {...props} sort={{ field: 'createdAt', order: 'DESC', countryUuid: localStorage.getItem('cUuid') }} filters={<ListFilters classes={classes} />} bulkActionButtons={false}
                  filter={{organisationUuid: localStorage.getItem('oUuid') }} actions={<KegstarListActions />}
                  // filter={{ 'createdAt.from': "2020-01-01T00:00:00+00:00" }}
            >
                <Datagrid expand={<InvoiceShow />} rowStyle={InvoiceListRowStyle}>
                    <FunctionField render={record => (InvoiceTypeName(record.type)) } label={'Invoice Type'} />

                    <ReferenceField source="orderUuid" reference="orders" label={'Order No.'} link={false} sortable={false}>
                        <FunctionField
                                    render={record => <Link href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`}
                                    target="_blank" rel="noreferrer"> {record.tracksOrderId}
                        </Link> } />
                    </ReferenceField>
                    <DateField source="createdAt" label={'Invoice Date'} />
                    <DateField source="dueDate" label={'Due Date'}  allowEmpty />
                    <NumberField source={'subTotal'} label={'Total Before Discount'} />
                    <NumberField source={'discountAmount'} label={'Total Discount'} />
                    <NumberField source={'netTotal'} label={'Total (Exc. Tax)'} />
                    <FunctionField render={record => <InvoicePDFButton record={record} /> } addLabel={false} />
                    <ShowButton variant={'none'} label={false} color={'primary'} />
                </Datagrid>
            </List>
        </Fragment>
    );
};

export default InvoiceList;
