import React, {Fragment} from 'react';
import {FunctionField, Link} from 'react-admin';
import { Show, SimpleShowLayout, ReferenceField} from 'react-admin';
import {KegnovaTextField} from "../components/KegnovaTextField";
import KegnovaDatePickerField from "../components/KegnovaDatePickerField";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {LocationDetails} from "../components/LocationDetails";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import SidebarPackagesMenuIcon from "../components/icons/SidebarPackagesMenuIcon";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
    },
    showPackage: {
        float: 'right',
        margin: 0, padding: 0
    },
    showPackageButton: {
        borderRadius: '0px'
    }
}));

const RentalShowDetails = ({record}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs >
                <Box m={1}>
                    <Typography variant={'h4'}>RENTALS</Typography>
                    <Divider style={{width: '100%'}} />
                </Box>
                <div className={classes.showPackage} >
                    <Link to={`/organisations/${record.organisationUuid}/show/packages`}><Button variant={'outlined'} className={classes.showPackageButton} > <SidebarPackagesMenuIcon /> &nbsp; &nbsp; Show Package Summary </Button></Link>
                </div>

                <KegnovaTextField value={record.displayId} label={'Rental Id'} linkType={false} multiline={false} fullWidth={true} />
                <KegnovaTextField value={record.organisationName} label={'Organisation'} linkType={false} multiline={false} fullWidth={true} />

                <ReferenceField resource="orders" reference="orders" source="orderUuid"
                                basePath="/orders" link={false} addLabel={false} record={record}>
                    <FunctionField render={record => <KegnovaTextField value={record.tracksOrderId} label={'Order Id'} linkType={false} style={{width: '100%'}} multiline={true} /> } addLabel={false} />
                </ReferenceField>
                <KegnovaDatePickerField value={record.startDate} label={'Cycle Start Date'} linkType={false} multiline={false} fullWidth={true} />
                <KegnovaDatePickerField value={record.endDate} label={'Cycle End Date'} linkType={false} multiline={false} fullWidth={true} />
                <KegnovaTextField value={(record.isChargeable === true || record.isChargeable === 'true') ? 'Yes' : 'No'} label={'Chargeable'} linkType={false} multiline={false} fullWidth={true}  style={{maxWidth: '85px'}} />

            </Grid>
            <Grid item xs >
                <Box m={1}>
                    <Typography variant={'h4'}>KEG ROLE</Typography>
                    <Divider style={{width: '100%'}} />
                </Box>
                <KegnovaTextField value={record.kegSerialNumber} label={'Serial Number'} linkType={false} multiline={false} fullWidth={true} />
                <KegnovaTextField value={record.skuName} label={'SKU'} linkType={false} multiline={false} fullWidth={true} />
                <ReferenceField resource="locations" reference="locations" source="startKegRole.location.uuid"
                                basePath="/locations" link={false} addLabel={false} record={record}>
                    <FunctionField render={record => <KegnovaTextField value={record.locationName} label={'Start Location'} linkType={'show'} reference={'locations'} record={record} style={{width: '100%'}} multiline={true} /> } addLabel={false} />
                </ReferenceField>

            </Grid>

        </Grid>

    )
}

const RentalShow = ({ translate, ...props }) => {

    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'R'} title={'Rental'} subheader={'Details'} />
                <CardContent>

                    {/* Content Start */}
                    <Show {...props} >
                        <SimpleShowLayout className={classes.root}>
                            <FunctionField render={record => <RentalShowDetails record={record} /> } addLabel={false} />
                        </SimpleShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default RentalShow;
