import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {};

class FeeTypeCheckboxes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            issueFee: false,
            dailyRentalFee: false,
            cleaningFee: false,
            timedFee: false,
            interCountryFee: false,
            taproomVenueFee: false,
            taproomDistributorFee: false,
            freightCapitalMetroFee: false,
            freightRegionalMetroFee: false,
            freightRegionalFee: false,
        };
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        this.props.action(name);
    };

    render() {

        return (
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.issueFee}
                            onChange={this.handleChange('issueFee')}
                            value="issueFee"
                            color="primary"
                        />
                    }
                    label="Issue Fee"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.dailyRentalFee}
                            onChange={this.handleChange('dailyRentalFee')}
                            value="dailyRentalFee"
                            color="primary"
                        />
                    }
                    label='Daily Rental Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.cleaningFee}
                            onChange={this.handleChange('cleaningFee')}
                            value="cleaningFee"
                            color="primary"
                        />
                    }
                    label='Cleaning Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.timedFee}
                            onChange={this.handleChange('timedFee')}
                            value="timedFee"
                            color="primary"
                        />
                    }
                    label='Timed Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.interCountryFee}
                            onChange={this.handleChange('interCountryFee')}
                            value="interCountryFee"
                            color="primary"
                        />
                    }
                    label='Inter Country Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.taproomVenueFee}
                            onChange={this.handleChange('taproomVenueFee')}
                            value="taproomVenueFee"
                            color="primary"
                        />
                    }
                    label='Taproom Venue Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.taproomDistributorFee}
                            onChange={this.handleChange('taproomDistributorFee')}
                            value="taproomDistributorFee"
                            color="primary"
                        />
                    }
                    label='Taproom Distributor Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.freightCapitalMetroFee}
                            onChange={this.handleChange('freightCapitalMetroFee')}
                            value="freightCapitalMetroFee"
                            color="primary"
                        />
                    }
                    label='Freight Capital Metro Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.freightRegionalMetroFee}
                            onChange={this.handleChange('freightRegionalMetroFee')}
                            value="freightRegionalMetroFee"
                            color="primary"
                        />
                    }
                    label='Freight Regional Metro Fee'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.freightRegionalFee}
                            onChange={this.handleChange('freightRegionalFee')}
                            value="freightRegionalFee"
                            color="primary"
                        />
                    }
                    label='Freight Regional Fee'
                />

            </FormGroup>
        );
    }
}

FeeTypeCheckboxes.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeeTypeCheckboxes);
