import React, {Fragment} from "react";
import { styles } from './SidebarBaseMenuIcon';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(styles);

const SidebarInvoicesMenuIcon = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <img src={require('../../assets/icons/SideMenu_Invoices.svg')} alt={'alt'} className={classes.iconSmallerWidth} style={{paddingLeft: '3px'}} />
        </Fragment>
    )
}

export default SidebarInvoicesMenuIcon;
