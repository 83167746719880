import PackageType, {PackageSummaryCellHighlight} from "../PackageType";
import TableCell from "@material-ui/core/TableCell";
import React, {Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import {CountryIdArray} from "../../components/CountryArray";
import {getLocationRole} from "../../helpers/Utilities";
import {
    Link
} from 'react-admin';

/**************** Package Details Functions ****************/
export const displayCountryName = (packages, level, perSku, sku) => {
    if(perSku){
        packages = packages[sku];
    }
    let countryName = '-';

    Object.keys(packages).map( key => {
        if (packages[key]['isCountryPackage']) {
            countryName = CountryIdArray[packages[key]['countryUuid']];
        }
    } );

    return countryName;
}

export const displayRowNameHighlight = (override, classes) => {

    if(override === 'organisation') {
        return classes.organisationLegend;
    } else if (override === 'location') {
        return classes.locationLegend;
    } else {
        return classes.countryLegend;
    }

}
export const displayRowName = (override, type, name) => {

    if(override === type) {
        return name;
    } else {
        return override.charAt(0).toUpperCase() + override.slice(1) + ' Level'
    }

}

const isOverride = (packageRecord, override) => {
    if (packageRecord['isCountryPackage'] && override === 'country') {
        return true;
    } else if (packageRecord['isOrganisationPackage'] && override === 'organisation') {
        return true;
    } else if (packageRecord['isLocationPackage'] && override === 'location') {
        return true;
    }
    return false;
}

export const displayPackageDate = (packages, level, dateColumn, perSku, sku) => {
    if(perSku){
        packages = packages[sku];
    }
    let value = '-';
    let isLevelPackage = false;

    Object.keys(packages).map( key => {
        if (isOverride(packages[key], level)) {
            if(packages[key][dateColumn]){
                isLevelPackage = true
                // value = <Link to={`/packages/${packages[key]['id']}`}> { new Date(packages[key][dateColumn]).toLocaleDateString() } </Link>;
                value = new Date(packages[key][dateColumn]).toLocaleDateString();
                return;
            }
        }

        if(packages[key][dateColumn] && !isLevelPackage){
            // value = <Link to={`/packages/${packages[key]['id']}`}> { new Date(packages[key][dateColumn]).toLocaleDateString() } </Link>;
            value = new Date(packages[key][dateColumn]).toLocaleDateString();
        }

    } );

    return value;
}

export const displayPsaDate = (packages, level, dateColumn, perSku, sku) => {
    if(perSku){
        packages = packages[sku];
    }
    let value = '-';
    let isLevelPackage = false;

    Object.keys(packages).map( key => {
        if (isOverride(packages[key], level)) {
            if(packages[key][dateColumn]){
                isLevelPackage = true
                value = new Date(packages[key][dateColumn]).toLocaleDateString();
                return;
            }
        }

        if(packages[key][dateColumn] && !isLevelPackage){
            value = new Date(packages[key][dateColumn]).toLocaleDateString();
        }

    } );

    return value;
}

export const displayDetailsFee = (packages, level, fee, perSku, sku, feeType = '') => {

    if(perSku){
        packages = packages[sku];
    }
    let value = '-';
    let isLevelPackage = false;

    Object.keys(packages).map( key => {
        if (isOverride(packages[key], level)) {
            if(packages[key][fee]){
                isLevelPackage = true;
                // value = <Link to={`/packages/${packages[key]['id']}${feeType}`}>{packages[key][fee]}</Link>;
                value = packages[key][fee];
                return;
            }
        }

        if(packages[key][fee] && !isLevelPackage){
            // value = <Link to={`/packages/${packages[key]['id']}${feeType}`}>{packages[key][fee]}</Link>;
            value = packages[key][fee];
        }

    } );

    return value;
}

export const displayDetailsDay = (packages, level, day, perSku, sku, feeType = '') => {
    if(perSku){
        packages = packages[sku];
    }
    let value = '-';
    let isLevelPackage = false;

    Object.keys(packages).map( key => {

        if (isOverride(packages[key], level)) {
            if(packages[key][day]){
                isLevelPackage = true;
                // value = <Link to={`/packages/${packages[key]['id']}${feeType}`}>{packages[key][day]}</Link>;
                value = packages[key][day];
                return;
            }
        }

        if(packages[key][day] && !isLevelPackage){
            // value = <Link to={`/packages/${packages[key]['id']}${feeType}`}>{packages[key][day]}</Link>;
            value = packages[key][day];
        }
    } );

    return value;
}

export const displayDetailsMinimumFreightFee = (packages, level, feeSetting, perSku, sku, feeType = null) => {
    if(perSku){
        packages = packages[sku];
    }
    let value = null;
    let isLevelPackage = false;

    Object.keys(packages).map(key => {
        if (isOverride(packages[key], level)) {
            if(packages[key][feeSetting]) {
                if( packages[key][feeSetting]['minimumFee'] && packages[key][feeSetting]['minimumFee'] > 0) {
                    // return <Link to={`/packages/${packages[key]['id']}/${feeType}`}>{ packages[key][feeSetting]['minimumFee'] }</Link>;
                    isLevelPackage = true;
                    value = packages[key][feeSetting]['minimumFee'];
                    return;
                }
            }
        }

        if(packages[key][feeSetting] && !isLevelPackage) {
            if( packages[key][feeSetting]['minimumFee'] && packages[key][feeSetting]['minimumFee'] > 0) {
                // value = <Link to={`/packages/${packages[key]['id']}/${feeType}`}>{ packages[key][feeSetting]['minimumFee'] }</Link>;
                value = packages[key][feeSetting]['minimumFee'];
            }
        }
    });

    return value;
}

export const displayDetailsTimedFeeDescription = (packages, level, feeSetting, perSku, sku) => {
    if(perSku){
        packages = packages[sku];
    }
    let value = '-';
    let isLevelPackage = false;

    Object.keys(packages).map(key => {
        if (isOverride(packages[key], level)) {
            if(packages[key][feeSetting]) {
                if( packages[key][feeSetting]['timedFeeGroup']) {
                    if (packages[key][feeSetting]['timedFeeGroup']['description']) {
                        isLevelPackage = true;
                        value = packages[key][feeSetting]['timedFeeGroup']['description'];
                    }
                }
            }
        }

        if(packages[key][feeSetting] && !isLevelPackage) {
            if( packages[key][feeSetting]['timedFeeGroup']) {
                if (packages[key][feeSetting]['timedFeeGroup']['description']) {
                    value = packages[key][feeSetting]['timedFeeGroup']['description'];
                }
            }
        }
    });

    return value;
}

export const displayDetailsPsaDescription = (packages, level, group, perSku, sku) => {
    if(perSku){
        packages = packages[sku];
    }
    let value = '-';
    let isLevelPackage = false;

    Object.keys(packages).map(key => {
        if (isOverride(packages[key], level)) {
            if(packages[key][group]) {
                if (packages[key][group]['description']) {
                    isLevelPackage = true;
                    value = packages[key][group]['description'];
                    return;
                }
            }
        }

        if(packages[key][group] && !isLevelPackage) {
            if (packages[key][group]['description']) {
                value = packages[key][group]['description'];
            }
        }
    });

    return value;
}

export const DisplayDetailedTimedFeeSettings = ({packages, classes}) => {

    const timedFeeSettings = getTimedFeeSettings(packages);

    return (
        <Fragment>
            <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCellLabel}> Description </TableCell>
                <TableCell> Billed At </TableCell>
                <TableCell> Timed Fees </TableCell>
                <TableCell> Stopped At </TableCell>
            </TableRow>
            <TableRow>
                <TableCell> { timedFeeSettings.description }</TableCell>
                <TableCell> { timedFeeSettings.timedFees.billedAtDay } days</TableCell>
                <TableCell> { timedFeeSettings.timedFees.amount } </TableCell>
                <TableCell> { timedFeeSettings.stopAtRole } </TableCell>
            </TableRow>
        </Fragment>
    )

}
export const DisplayRow = (typeOverride, type) => {
    // You're in location page
    if(type === 'location') {
        return true;
    } else if (type === 'organisation') {
        if(typeOverride === 'location') {
            return false;
        }
    }

    return true;
}
const getTimedFeeSettings = (packages) => {
    let description = null;
    let timedFees = null;
    let stopAtRole = null;

    Object.keys(packages).map(key => {

        if(packages[key].timedFeeSettings) {
            stopAtRole = packages[key].timedFeeSettings.stopAtRole;

            if(packages[key].timedFeeSettings.timedFeeGroup) {
                if(packages[key].timedFeeSettings.timedFeeGroup) {

                    description = packages[key].timedFeeSettings.timedFeeGroup.description;
                    Object.keys(packages[key].timedFeeSettings.timedFeeGroup.timedFees).map(i => {
                        timedFees = packages[key].timedFeeSettings.timedFeeGroup.timedFees[i];
                    });

                }
            }
        }
    });

    return {'description': description, 'timedFees': timedFees, 'stopAtRole': getLocationRole(stopAtRole) };
}


/**************** Package Details Functions ****************/




/**************** Package Summary Functions ****************/
export const getRentalFees = (packageSummary) => {

    const packagesPerSku = packageSummary.packagesPerSku;
    let rentalFeesMap = [];

    Object.keys(packagesPerSku).map(key => {
        rentalFeesMap[key] = [];
        packagesPerSku[key].map(
            item => {
                rentalFeesMap[key].push({
                    'issueFee': item.issueFee,
                    'dailyRentalFee': item.dailyRentalFee,
                    'cleaningFee': item.cleaningFee,
                    'taproomVenueFee': item.taproomVenueFee,
                    'taproomDistributorFee': item.taproomDistributorFee,
                    'billedAtDay360': getTimedFee(item, 360),
                    'overriddenBy': PackageType(item)
                });
            });
        }
    );

    return rentalFeesMap;
}

export const getTimedFee = (packagePerSku, billedAtDay) => {

    let value = null;

        if(packagePerSku.timedFeeSettings) {
            if(packagePerSku.timedFeeSettings.timedFeeGroup) {
                if(packagePerSku.timedFeeSettings.timedFeeGroup.timedFees) {
                    Object.keys(packagePerSku.timedFeeSettings.timedFeeGroup.timedFees).map(i => {
                        if(packagePerSku.timedFeeSettings.timedFeeGroup.timedFees[i].billedAtDay === billedAtDay){
                            if(packagePerSku.timedFeeSettings.timedFeeGroup.timedFees[i].amount){
                                value = packagePerSku.timedFeeSettings.timedFeeGroup.timedFees[i].amount;
                            }
                        }
                    });

                }
            }
        }

    return value;
}

export const displayRentalFee = (rentalFees, sku, feeType) => {

    let value = null;

    if(typeof rentalFees[sku] !== 'undefined') {
        Object.keys(rentalFees[sku]).map(key => {

            if(rentalFees[sku][key][feeType] !== null) {
                value = rentalFees[sku][key][feeType];
            }
        });
    }
    return value;
}

export const displayMinimumFreightFee = (packages, feeType) => {

    let value = null;

    Object.keys(packages).map(key => {

        if(packages[key].freightCapitalMetroFeeSettings) {
            if( packages[key].freightCapitalMetroFeeSettings[feeType] && packages[key].freightCapitalMetroFeeSettings[feeType] > 0) {
                value = packages[key].freightCapitalMetroFeeSettings[feeType];
            }
        }
    });

    return (value) ? value.toLocaleString(
        undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4 }) : null;
}

export const DisplayTimedFees = ({packages, classes}) => {
    let timedFees = null;

    Object.keys(packages).map(key => {

        if(packages[key].timedFeeSettings) {
            if(packages[key].timedFeeSettings.timedFeeGroup) {
                if(packages[key].timedFeeSettings.timedFeeGroup.timedFees) {

                    timedFees = packages[key].timedFeeSettings.timedFeeGroup.timedFees;

                }
            }
        }
    });

    return (
        <Fragment>
            {
                timedFees.map(
                    item => (
                        <TableRow>
                            <TableCell> { item.billedAtDay } days</TableCell>
                            <TableCell className={PackageSummaryCellHighlight(classes, packages, 'timedFee')}>
                                {/*{ packageSummary.packageSetGroup.timedFee }*/}
                                { item.amount }
                            </TableCell>
                        </TableRow>
                    )
                )
            }
        </Fragment>
    )

}

export const highlightCurrentPsaDiscount = (rentalCount, lowerBound, upperBound) => {
    if (!rentalCount) return false;
    if( (rentalCount >= lowerBound) && (rentalCount <= upperBound) ) {
        return true;
    } else {
        return false;
    }
}

/**************** Package Summary Functions ****************/
