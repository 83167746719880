import React, {Fragment} from 'react';
import {Pagination, FunctionField, Link} from 'react-admin';
import { Show, SimpleShowLayout, TextField, ReferenceField, DateField, ReferenceManyField, Datagrid} from 'react-admin';
import {Grid, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {KegnovaTextField} from "../components/KegnovaTextField";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import ShowButton from "../components/button/ShowButton";
import KegstarReferenceField from "../components/field/KegstarReferenceField";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff'
    }
}));

const PackageSetGroup = ({ record }) => {
    return (
        <div>
            <KegnovaTextField value={record.id} label={'Id'} linkType={false} />
        </div>
    )
};

const KegDetails = ({record}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <Grid container spacing={4} >
                <Grid item xs>

                    <Box><Typography color={'primary'} className={classes.subTitle}>DETAILS</Typography></Box>
                    <KegstarReferenceField source="skuUuid" reference="skus" linkType={false} addLabel={false} record={record} basePath={'/skus'}>
                        <FunctionField render={data => <KegnovaTextField value={data.name} label={'Sku'} /> } addLabel={false} />
                    </KegstarReferenceField>
                    <KegnovaTextField value={record.serialNumber} label={'Serial Number'} />
                    <KegnovaTextField value={record.manufacturer} label={'Manufacturer'} />
                    <KegnovaTextField value={record.colour} label={'Colour'} />
                    <DateField value={record.manufacturedAt} label={'Manufactured At'} />
                    <KegnovaTextField value={record.kegTelemetryHistoryId} label={'Keg Telemetry History Id'} />
                    <KegnovaTextField value={record.kegLocationsHistoryId} label={'Keg Locations History Id'} />
                    <KegnovaTextField value={record.kegStatusHistoryId} label={'Keg Status History Id'} />
                    <KegnovaTextField value={record.rfidTag} label={'RFID Tag'} />
                    <Box m={3}></Box>

                </Grid>

                <Grid item xs>
                    <Box><Typography color={'primary'} className={classes.subTitle}>RENTALS</Typography></Box>
                    <ReferenceManyField
                        record={record}
                        reference="rentals"
                        target={'kegUuid'} label="" pagination={<Pagination />} basePath={'/rentals'}
                    >
                        <Datagrid>
                            <FunctionField render={record =>
                                <Link to={`/report/rentals?order=DESC&page=1&perPage=10&sort=id&filter={"id":${record.displayId}}`}>
                                    {record.displayId}
                                </Link>
                            } label={'Rental'} />
                            <KegstarReferenceField source="organisationUuid" reference="organisations" label={'Organisation'} linkType="show" basePath={'/organisations'} record={record} >
                                <TextField emptyText source="name" />
                            </KegstarReferenceField>
                            <DateField source="startDate"  />
                            <DateField source="endDate"   />

                        </Datagrid>
                    </ReferenceManyField>

                </Grid>
            </Grid>
        </Fragment>
    );
}

const KegShow = ({ ...props }) => {
    const classes = useStyles();
    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'K'} title={'Keg'} subheader={'Details'}/>
                <CardContent>
                    {/* Content Start */}
                    <Show {...props} >
                        <SimpleShowLayout>

                            <KegDetails />

                        </SimpleShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default KegShow;
