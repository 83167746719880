import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const InvoicesExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            id,	uuid, createdAt,	status,	modifiedAt, displayId, packageSetGroupUuid, countryUuid,	orderUuid,	organisationUuid, countryCode, type, invoiceStatus, dueDate, cutoffDate, modifiedAtText, actionedAt,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'createdAtText', 'countryName', 'organisationName', 'netsuiteDocNumber', 'cutoffDate', 'dueDateText', 'subTotal', 'discountAmount',	'netTotal', 'paymentDays',
            'tracksOrderId', 'invoiceTypeText', 'packageSetGroupDisplayId', 'invoiceStatusText', 'actionedAtText'
        ], // order fields in the export
        rename:[
            'Invoice Gen. Date', 'Country', 'Organisation', 'Netsuite Doc Number', 'Cutoff Date', 'Due Date', 'Sub Total', 'Discount', 'Total', 'Payment Days',
            'Tracks Order Id', 'Invoice Type', 'Package Set Group', 'Invoice Status', 'Order Actioned At'
        ]
    }, (err, csv) => {
        downloadCSV(csv, 'Invoices'); // download as 'posts.csv` file
    });

};

export default InvoicesExport;
