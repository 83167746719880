import React, {Fragment} from 'react';
import {
    Show, TextField, TabbedShowLayout,
    DateField, Tab, ReferenceField, NumberField, ReferenceManyField,
    Datagrid, FunctionField, Pagination
} from 'react-admin';
import {Grid, makeStyles} from "@material-ui/core";
import {KegnovaTextField} from "../../components/KegnovaTextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {ChargeDetailsPanel} from "../../components/ChargeDetailsPanel";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));


const InvoiceItemDetails = ({record}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Grid container spacing={4} >
                <Grid item xs>
                    <Box><Typography color={'primary'} className={classes.subTitle}>DETAILS</Typography></Box>
                    <KegnovaTextField value={record.feeType} label={'Fee Type'} />
                    <KegnovaTextField value={record.chargeType} label={'Charge Type'} />
                    <KegnovaTextField value={record.quantity} label={'Quantity'} />
                    <KegnovaTextField value={record.unitAmount} label={'Unit Amount'} />
                    <KegnovaTextField value={record.total} label={'Total'} />
                    <KegnovaTextField value={record.information} label={'Information'} />
                    <KegnovaTextField value={record.chargeGenerationOrigin} label={'Charge Generation Origin'} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

const InvoiceItemShow = ({ ...props }) => {
    const classes = useStyles();
    return (
        <Show {...props}>
            <TabbedShowLayout >

                <Tab label="Invoice Item" className={classes.container}>
                    <ReferenceField source="draftInvoiceUuid" reference="invoices/draft" linkType={'show'} label={''}>
                        <FunctionField render={data => <KegnovaTextField value={data.id} label={'Invoice'} linkType={'show'} style={{width: '350px'}} /> } addLabel={false} />
                    </ReferenceField>
                    <FunctionField render={record => <InvoiceItemDetails record={record} /> } addLabel={false} />
                </Tab>

                <Tab label="Charges" >
                    <ReferenceManyField target="draftInvoiceItemUuid" reference="charges" addLabel={false} >
                        <Datagrid expand={<FunctionField render={record => <ChargeDetailsPanel record={record} /> } addLabel={false} />}
                                  pagination={<Pagination />} style={{paddingBottom: '10em', marginBottom: '10em'}}>

                            <TextField emptyText source="displayId" label={'Id'} sortable={false}/>

                            <ReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Type'} link={false} allowEmpty>
                                <ReferenceField source="draftInvoiceUuid" reference="invoices/draft" addLabel={false} link={false} >
                                    <FunctionField render={record => (record.type===1) ? 'Order' : 'Monthly' } addLabel={false} link={false} />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Doc Number'} link={false} allowEmpty>
                                <ReferenceField source="draftInvoiceUuid" reference="invoices/draft" addLabel={false} link={false}>
                                    <TextField emptyText source="netsuiteDocNumber" />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Date'} link={false} allowEmpty>
                                <ReferenceField source="draftInvoiceUuid" reference="invoices/draft" addLabel={false} link={false}>
                                    <DateField source="createdAt" />
                                </ReferenceField>
                            </ReferenceField>

                            <TextField emptyText source="description" label={'Description'} />
                            <TextField emptyText source="kegSerialNumber" label={'Asset Code'} />
                            <ReferenceField source="orderUuid" reference="orders" label={'Tracks Order Id'} link={false} >
                                <FunctionField
                                    render={record => <Link href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`}
                                                            target="_blank" rel="noreferrer"> {record.tracksOrderId}
                                    </Link> } />
                            </ReferenceField>
                            <DateField source="applicableDate"  label={'Applicable Date'}/>
                            <ReferenceField source="skuUuid" reference="skus" label={'Sku'} link={false} allowEmpty>
                                <TextField emptyText source="name" />
                            </ReferenceField>
                            <ReferenceField source="rentalUuid" reference="rentals" label={'Cycle Start Date'} link={false} allowEmpty>
                                <DateField source="startDate"  />
                            </ReferenceField>
                            <ReferenceField source="rentalUuid" reference="rentals" label={'Cycle Stop Date'} link={false} allowEmpty>
                                <DateField source="endDate"  />
                            </ReferenceField>
                            <ReferenceField source="orderUuid" reference="orders" label={'Cycle Stop Location'} link={false} allowEmpty>
                                <ReferenceField source="toLocationUuid" reference="locations" addLabel={false} link={'show'}>
                                    <TextField emptyText source="locationName" />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="orderUuid" reference="orders" label={'Current Location'} link={false} allowEmpty>
                                <ReferenceField source="fromLocationUuid" reference="locations" addLabel={false} link={'show'}>
                                    <TextField emptyText source="locationName" />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="orderUuid" reference="orders" label={"Location's Organisation"} link={false} allowEmpty>
                                <ReferenceField source="fromOrganisationUuid" reference="organisations" addLabel={false} link={'show'}>
                                    <TextField emptyText source="name" />
                                </ReferenceField>
                            </ReferenceField>
                            <NumberField source={'quantity'}/>
                            <NumberField source={'unitAmount'}/>
                            <NumberField source={'total'}/>


                        </Datagrid>
                    </ReferenceManyField>

                </Tab>

            </TabbedShowLayout>

        </Show>
    );
}

export default InvoiceItemShow;

