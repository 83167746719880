import React, {Fragment} from "react";
import {Error, LinearProgress, useQuery} from "react-admin";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import ButtonDownloadIcon from "./icons/ButtonDownloadIcon";
import KegstarCircularProgress from "./layout/KegstarCircularProgress";

import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {width: '30px', height: '40px', backgroundColor: theme.palette.primary.light, borderRadius: '0px'}
}));

export const InvoicePDFButton = ( {record} ) => {

    const classes = useStyles();

    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'invoices',
        payload: {
            pagination: { page: 1 , perPage: 5 }, sort:{ field: 'createdAt', order: 'DESC' }, filter: {draftInvoiceUuid: record.uuid}
        }
    });

    if (loading) return <KegstarCircularProgress />;
    // if (error) return <Error />;
    if (!data) return null;
    if (!data[0]) return null;

    return (
        <Fragment>
            <Link href={data[0].invoiceUrl} target="_blank" rel="noreferrer">
                <Button variant={'none'} className={classes.button}>
                    <ButtonDownloadIcon />
                </Button>
            </Link>
        </Fragment>
    )
};
