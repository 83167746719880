import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    app: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        header: {
          auth: {
              login: 'Login',
              logout: 'Logout'
          }
        },
        dashboard: {
            loadingPrimary: 'Brewing...',
            loadingSecondary: 'Please wait'
        },
        menu: {
            account: {
                name: 'Accounts',
                organisations: 'Organisations',
            },
            billing: {
                name: 'Billing',
                invoices: 'Invoices'
            },
            tracking: {
                name: 'Tracking',
                orders: 'Orders'
            },
            packages: {
                name: 'Packages',
                packagesetgroup: 'Package Group'
            },
            mystar: {
                overview: 'Overview',
                summaries: 'Summaries',
                orders: 'Orders',
                kegs: 'Kegs',
                billing: 'Billing',
                reports: 'Reports',
                account: 'Account',
                support: {
                    needHelp: 'Need Help?',
                    contactUs: 'Contact Us',
                    tips: 'Kegstar Tips',
                }
            }
        },
    },
    resources: {
        countries: {
            name: 'Country |||| Countries',
            fields: {
                marketId: 'Market',
                name: 'Name',
                id: 'Id',
                uuid: 'Uuid',
                status: 'Status',
                createdAt: {
                    date: 'Created At'
                },
                modifiedAt: {
                    date: 'Modified At'
                },
            },
        },

        organisations: {
            name: 'Organisation |||| Organisations',
            fields: {
                id: 'Id',
                uuid: 'Uuid',
                createdAt: {
                    date: 'Created At'
                },
                modifiedAt: {
                    date: 'Modified At'
                },
                countryUuid: 'Country',
                countryName: 'Country Name',
                businessNumber: 'Business Number',
                liquorLicence: 'Liquor Licence',
                billingAddress: 'Billing Address',
                billingDetails: 'Billing Details',
                isPsaCustomer: 'PSA Customer',
                psaStart: {
                    date: 'PSA Start'
                },
                psaEnd: {
                    date: 'PSA End'
                },
                timezone: 'Timezone'

            },
        },

        orders: {
            name: 'Order |||| Orders',
            fields: {
                id: 'Id',
                uuid: 'Uuid',
                createdAt: {
                    date: 'Created At'
                },
                modifiedAt: {
                    date: 'Modified At'
                },
                displayId: 'Id',
                fromOrganisationUuid: 'From Organisation',
                toOrganisationUuid: 'To Organisation',
                fromLocationUuid: 'From Location',
                toLocationUuid: 'To Location',
                billingDetails: 'Billing Details',
                skuUuid: 'Sku',
                kegsRequested: 'Kegs Requested',
                requestedAt: 'Requested At',
                actionedAt: 'Actioned At',
                cleanKegs: 'Clean Kegs',
                poNumber: 'PO Number',
                source: 'Source',
                shippingMethod: 'Shipping Method',
                specialInstruction: 'Special Instruction',
                note: 'Note',
                freightPrice: 'Freight Price',
                freightQuantity: 'Freight Quantity',
                onKegLease: 'On KegLease',
            },
        },


    },
};
