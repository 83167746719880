import PackageList from './PackageList';
import PackageShow from "./PackageShow";
import PackageCreate from "./PackageCreate";
import PackageEdit from "./PackageEdit";
import SidebarPackagesMenuIcon from "../components/icons/SidebarPackagesMenuIcon";
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: PackageList,
    show: PackageShow,
    create: PackageCreate,
    edit: PackageEdit,
    icon: SidebarPackagesMenuIcon,
    unauthorised: UnauthorisedPage
};
