import RentalList from './RentalList';
import RentalShow from './RentalShow';
import RentalIcon from '@material-ui/icons/Timelapse';


export default {
    list: RentalList,
    show: RentalShow,
    icon: RentalIcon,
};
