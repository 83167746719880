import React, {Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Show, SimpleShowLayout,
    ReferenceField, TextField, useQuery, Loading, Error,
    LinearProgress, Link } from 'react-admin';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Divider, makeStyles, useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {InvoiceStatusName} from "../components/Utilities";
import {InvoicePDFButton} from "../components/InvoicePDFButton";
import {Link as ALink} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import BackButton from "../components/HistoryButtons";

const useStyles = makeStyles(theme => ({
    container: { minWidth: '35em', marginLeft: '1em' },
    leftAlignedCell: { textAlign: 'left'},
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
    paper: {
        boxShadow: 'none'
    }
}));

const GetDraftInvoice = ( record ) => {

    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'invoices/draft',
        payload: {id: record.draftInvoiceUuid}
    });

    if (!data) return null;

    return (data);
};

const NetsuiteInvoiceDocNumber = ({ record }) => {

    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'invoices',
        payload: {
            pagination: { page: 1 , perPage: 5 }, sort:{ field: 'createdAt', order: 'DESC' }, filter: {draftInvoiceUuid: record.uuid}
        }
    });

    if (loading) return <LinearProgress />;
    if (error) return <Error />;
    if (!data) return null;
    if (!data[0]) return null;

    return (
        <div>
            <Typography
                variant="caption" color={'textSecondary'}
            >
                Invoice Number
            </Typography>
            <Typography gutterBottom>
                { data[0].netsuiteInvDocNumber }
            </Typography>
        </div>
    )
};
const OrderNumber = ({ record }) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'orders',
        payload: {id: record.orderUuid}
    });

    if (loading) return <LinearProgress />;
    if (error) return <Error />;
    if (!data) return null;
    if (!data.tracksOrderId) return null;
    return (
        <div>
            <Typography
            variant="caption" color={'textSecondary'}
            >
                Order No.
            </Typography>
            <Typography gutterBottom>
                <ALink href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${data.tracksOrderId}/details`}
                      target="_blank" rel="noreferrer"> {data.tracksOrderId}
                </ALink>
            </Typography>
        </div>
    )
};

const OrderFromLocation = ({ record }) => {
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'orders',
        payload: {id: record.orderUuid}
    });

    if (loading) return <LinearProgress />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <div className={classes.root}>
            <Typography
                variant="caption" color={'textSecondary'}
            >
                From
            </Typography>
            <Typography>
                <ReferenceField
                    reference="organisations"
                    source="fromOrganisationUuid"
                    basePath="/organisations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="name" style={{fontSize: 'larger'}} />
                </ReferenceField>
            </Typography>
            <Typography gutterBottom>
                <ReferenceField
                    reference="locations"
                    source="fromLocationUuid"
                    basePath="/locations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="streetAddress" color={'textSecondary'} />
                </ReferenceField>
            </Typography>
        </div>
    )
};

const OrderToLocation = ({ record }) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'orders',
        payload: {id: record.orderUuid}
    });

    if (loading) return <LinearProgress />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <div>
            <Typography
                variant="caption" color={'textSecondary'}
            >
                To
            </Typography>
            <Typography >
                <ReferenceField
                    reference="organisations"
                    source="toOrganisationUuid"
                    basePath="/organisations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="name" style={{fontSize: 'larger'}} />
                </ReferenceField>
            </Typography>
            <Typography gutterBottom>
                <ReferenceField
                    reference="locations"
                    source="toLocationUuid"
                    basePath="/locations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="streetAddress" color={'textSecondary'} />
                </ReferenceField>
            </Typography>
        </div>
    )
};

const InvoiceItems = ({record}) => {

    const classes = useStyles();
    const theme = useTheme();
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'invoiceitems/draft',
        payload: {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'createdAt', order: 'DESC' },
            filter: {draftInvoiceUuid: record.uuid}
        }
    });

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell >
                            SKU
                        </TableCell>
                        <TableCell className={classes.leftAlignedCell}>
                            Item
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            Rate
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            Quantity
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            Amount
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(
                        item =>
                            (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <ReferenceField
                                            reference="skus"
                                            source="skuUuid"
                                            basePath="/skus"
                                            record={item}
                                            linkType={false}
                                        >
                                            <TextField emptyText source="name" />
                                        </ReferenceField>
                                    </TableCell>
                                    <TableCell className={classes.leftAlignedCell}>
                                        <Link to={`/invoiceitems/draft/${item.id}/show/1`} >
                                            { item.description }
                                        </Link>
                                    </TableCell>
                                    <TableCell className={classes.rightAlignedCell}>
                                        {item.unitAmount.toLocaleString(
                                            undefined, { minimumFractionDigits: 2 })}
                                    </TableCell>
                                    <TableCell className={classes.rightAlignedCell}>
                                        {item.quantity}
                                    </TableCell>
                                    <TableCell className={classes.rightAlignedCell}>
                                        {item.total.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                    )}
                    <TableRow style={{borderTop: '3px solid #ececec'}}>
                        <TableCell colSpan={3} />
                        <TableCell className={classes.rightAlignedCell} style={{fontWeight: 'bolder', fontSize: '1rem'}}>
                             Total Before Discount
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {record.subTotal.toLocaleString(
                                undefined, { minimumFractionDigits: 2 })}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell className={classes.rightAlignedCell} style={{fontWeight: 'bolder', fontSize: '1rem'}}>
                            Total Discount
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {record.discountAmount.toLocaleString(
                                undefined, { minimumFractionDigits: 2 })}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell className={classes.rightAlignedCell} style={{fontWeight: 'bolder', fontSize: '1rem', backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main}}>
                            Total (Exc. Tax)
                        </TableCell>
                        <TableCell
                            className={classes.rightAlignedCell} style={{fontWeight: 'bold', fontSize: '1.25rem', backgroundColor: theme.palette.primary.main, borderRight: '1px solid', borderRightColor: theme.palette.primary.main, }}
                        >
                            {record.netTotal.toLocaleString(
                                undefined, { minimumFractionDigits: 2 })}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Box m={3}>
                <InvoicePDFButton record={record} />
            </Box>

        </Fragment>
    )
};

const Invoice = (draftInvoiceRecord) => {

    const record = draftInvoiceRecord.draftInvoiceRecord;

    return (
        <Fragment>
            <Grid container spacing={16}>
                <Grid item xs>
                    <Typography variant="title">
                        <ReferenceField
                            reference="organisations"
                            source="organisationUuid"
                            basePath="/organisations"
                            record={record}
                            linkType={false}
                        >
                            <TextField emptyText source="name"  style={{fontWeight: 'bold', fontSize: '1.5rem'}} />
                        </ReferenceField>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <NetsuiteInvoiceDocNumber record={record} />
                </Grid>
                <Grid item xs>
                    { (record.orderUuid) ? <OrderNumber record={record}/> : null }
                </Grid>
            </Grid>

            <Grid container spacing={16}>
                <Grid item xs>
                    <Divider />
                </Grid>
            </Grid>

            <Grid container spacing={16}>
                <Grid item xs>
                    { (record.orderUuid) ? <OrderFromLocation record={record}/> : null }
                </Grid>
                <Grid item xs>
                    { (record.orderUuid) ? <OrderToLocation record={record}/> : null }
                </Grid>
            </Grid>

            <Grid container spacing={16}>
                <Grid item xs>
                    <Divider />
                </Grid>
            </Grid>

            <Grid container spacing={16}>
                <Grid item xs>
                    <Typography
                        variant="caption" color={'textSecondary'}
                    >
                        Created At
                    </Typography>
                    <Typography gutterBottom>
                        {new Date(record.createdAt).toLocaleDateString()}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography
                        variant="caption" color={'textSecondary'}
                    >
                        Status
                    </Typography>
                    <Typography gutterBottom>
                        { InvoiceStatusName(record.invoiceStatus) }
                    </Typography>
                </Grid>
            </Grid>

            <div>
                <InvoiceItems record={record} />
            </div>
        </Fragment>
    )
};

const InvoiceShow = ({record, ...props}) => {

    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const draftInvoiceRecord = GetDraftInvoice(record);

    return(
        <div>
            <Show {...props} className={'invoice-record'} actions={<BackButton />}>
                <SimpleShowLayout>
                    { draftInvoiceRecord && <Invoice classes={classes} draftInvoiceRecord={draftInvoiceRecord} />}
                </SimpleShowLayout>
            </Show>
        </div>
    );
};

export default InvoiceShow;
