import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import {makeStyles} from "@material-ui/core";

export const styles = theme => ({
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'inline'
    }
});
const useStyles = makeStyles(styles);

const KegnovaDatePickerField =  ({ name, value, label, readOnly, style })  => {
    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <div className={classes.dateField} style={style}>
                <KeyboardDatePicker
                    name={name}
                    emptyLabel={''}
                    inputVariant={'outlined'}
                    margin={'normal'}
                    label={label}
                    format="dd/MM/yyyy"
                    value={value}
                    readOnly={(readOnly) ? readOnly : false}

                />
            </div>
        </MuiPickersUtilsProvider>
    );
}

export default KegnovaDatePickerField;
