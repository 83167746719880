import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MenuCloseIcon from '@material-ui/icons/Clear';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toggleSidebar } from 'ra-core';
import LoadingIndicator from './KegstarLoadingIndicator';
import DefaultUserMenu from './KegstarUserMenu';
import HideOnScroll from './KegstarHideOnScroll';
import KegstarSearch from "./KegstarSearch";
import {usePermissions} from "react-admin";
import {isCustomer} from "../security/Role";
import ThemeChanger from "../configuration/ThemeChanger";

const useStyles = makeStyles(
    theme => ({
        toolbar: {
            paddingRight: 24,
            paddingTop: '9px',
            paddingBottom: '9px'
        },
        menuButton: {
            marginLeft: '0.5em',
            marginRight: '0.5em',
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    }),
    { name: 'RaAppBar' }
);

const KegstarAppBar = ({
                    children,
                    classes: classesOverride,
                    className,
                    logo,
                    logout,
                    open,
                    title,
                    userMenu,
                    ...rest
                }) => {
    const classes = useStyles({ classes: classesOverride });
    const dispatch = useDispatch();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    return (
        <HideOnScroll>
            <MuiAppBar className={className} color="secondary" {...rest}>
                <Toolbar
                    disableGutters
                    variant={isXSmall ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => dispatch(toggleSidebar())}
                        className={classNames(classes.menuButton)}
                    >

                        { ((open) && isXSmall) && <MenuCloseIcon
                            classes={{
                                root: classes.menuButtonIconClosed
                            }}
                        /> }

                        { (!(open) && isXSmall) && <MenuIcon
                            classes={{
                                root: open
                                    ? classes.menuButtonIconOpen
                                    : classes.menuButtonIconClosed,
                            }}
                        /> }

                        { (!(open) && !(isXSmall) ) && <MenuCloseIcon
                            classes={{
                                root: classes.menuButtonIconClosed
                            }}
                        /> }

                        { (open && !isXSmall) && <MenuIcon
                            classes={{
                                root: classes.menuButtonIconOpen,
                            }}
                        /> }

                    </IconButton>
                    {Children.count(children) === 0 ? (
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.title}
                            id="react-admin-title"
                        />
                    ) : (
                        children
                    )}

                    { (permissions && !isCustomer(permissions)) && <KegstarSearch /> }

                    <LoadingIndicator />
                    {cloneElement(userMenu, { logout })}
                </Toolbar>
            </MuiAppBar>
        </HideOnScroll>
    );
};

KegstarAppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    userMenu: PropTypes.element,
};

KegstarAppBar.defaultProps = {
    userMenu: <DefaultUserMenu />,
};

export default KegstarAppBar;
