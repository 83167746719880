import RentalList from './RentalList';
import RentalShow from './RentalShow';
import RentalIcon from '@material-ui/icons/Timelapse';
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: RentalList,
    show: RentalShow,
    icon: RentalIcon,
    unauthorised: UnauthorisedPage
};
