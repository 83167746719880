import React from 'react';
import { Route } from 'react-router-dom';
import Weekly from '../summaries/Weekly';
import Monthly from '../summaries/Monthly';
import Configuration from "../configuration/Configuration";
import UnauthorisedPage from "../components/UnauthorisedPage";

export default [
    <Route exact path="/summaries/weekly" render={() =><Weekly />} />,
    <Route path="/summaries/weekly/:date" children={<Weekly />} />,
    <Route exact path="/summaries/monthly" render={() => <Monthly />} />,
    <Route path="/summaries/monthly/:date" children={<Monthly />} />,
    <Route path="/unauthorised" render={() => <UnauthorisedPage />} />,
    // <Route exact path="/configuration" component={Configuration} />,
];
