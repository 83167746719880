import TextField from "@material-ui/core/TextField";
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core";
import OpenIcon from '@material-ui/icons/OpenInNew';
import ViewIcon from '@material-ui/icons/Pageview';

import {Link} from 'react-admin';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    linkField: {
        color: theme.palette.primary.main,
    },
    linkButton: {
        display: 'inline-flex',
    },
    fieldLabel: {
        color: theme.palette.primary.main,
        verticalAlign: 'top',
    },
    emptyField: {
        color: '#333333',
    },
}));

const FieldLabel = ({label}) => {
    const classes = useStyles();
    return (
        <span>
            {label} <OpenIcon className={classes.fieldLabel} />
        </span>
    )
}

export const KegnovaTextField = ({value, label, linkType, multiline, fullWidth, style
                                     , record, reference
                                    }) => {

    const classes = useStyles();
    let inputPropsClassName = {};
    let fieldLabel = label;
    if(linkType==='show'){
        inputPropsClassName = classes.linkField;
    }
    if(!value) {
        inputPropsClassName = classes.emptyField;
        value = 'None';
    }
    return(
        <Fragment>



            { (linkType==='show' && (reference) && (record)) ?

                <Link to={`/${reference}/${record.id}/show`} >
                    <TextField
                        label={fieldLabel}
                        className={classes.textField}
                        defaultValue={value}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                            className: inputPropsClassName
                        }}
                        multiline={(multiline) ? multiline : false}
                        fullWidth={(fullWidth) ? fullWidth : false}
                        variant="outlined"
                        style={style}
                    />
                </Link>

            :
                <TextField
                label={fieldLabel}
                className={classes.textField}
                defaultValue={value}
                margin="normal"
                InputProps={{
                readOnly: true,
                className: inputPropsClassName
            }}
                multiline={(multiline) ? multiline : false}
                fullWidth={(fullWidth) ? fullWidth : false}
                variant="outlined"
                style={style}
                />
            }

        </Fragment>
    )
}
