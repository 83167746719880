import React from 'react';
import Chip from "@material-ui/core/es/Chip";
import Avatar from "@material-ui/core/Avatar";
import CardIcon from "../dashboard/CardIcon";
import ErrorIcon from '@material-ui/icons/Error';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {isCustomer} from "../security/Role";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PackageTypeFilterValidation from "../components/dialogs/PackageTypeFilterValidation";

export const showYesNo = (val) => {
    return (val===1) ? 'Yes' : 'No';
};

export const showCountryName = (country) => {
    switch(country) {
        case '1':
            return 'Australia';
        case '2':
            return 'New Zealand';
        case '3':
            return 'United Kingdom';
        case '4':
            return 'United States';

        default:
            return null;
    }
}

export const getLocationRole = (role) => {
    let value = 0;
    switch(role) {
        case 1:
            value = 'Warehouse';
            break;
        case 2:
            value = 'Producer';
            break;
        case 3:
            value = 'Distributor';
            break;
        case 4:
            value = 'Venue';
            break;
        case 5:
            value = 'Collector';
            break;
        case 6:
            value = 'Contract Brewer';
            break;
    }
    return value;
}

export const getLocationRoleColor = (source) => {
    let color = 'gray';
    switch(source) {
        case 'isWarehouse':
            color = '#7B7B7B';
            break;
        case 'isProducer':
            color = '#689F38';
            break;
        case 'isDistributor':
            color = '#0288D1';
            break;
        case 'isVenue':
            color = '#F57C00';
            break;
        case 'isCollector':
            color = '#FEDB00';
            break;
        case 'isContractBrewer':
            color = '#D32F2F';
            break;
    }
    return color
}

export const showRoleField = (source, record) => {
    let caption = source.replace("is", "");
    let color = getLocationRoleColor(source);
    if(record) {
        return (
            <Chip
                label={caption}
                style={{backgroundColor: color, color: '#fff'}}
            />
        )
    }
}

export const letterAvatar = (source) => {
    let caption = source.replace("is", "");
    return caption.charAt(0);
}

export const LocationRoleAvatar = ({record}) => {
    let source = '';
    let dimension = '30px';
    let fontSize = '18px';

    if(record.isWarehouse) {
        source = 'isWarehouse';
    }
    if(record.isProducer) {
        source = 'isProducer';
    }
    if(record.isDistributor) {
        source = 'isDistributor';
    }
    if(record.isVenue) {
        source = 'isVenue';
    }
    if(record.isCollector) {
        source = 'isCollector';
    }
    if(record.isContractBrewer) {
        source = 'isContractBrewer';
    }

    return (
        <Grid container justify="center" alignItems="center">
            { (record.isWarehouse) && <Avatar style={{color: 'white', backgroundColor: getLocationRoleColor('isWarehouse'), width: dimension, height: dimension, fontSize: fontSize}}>{letterAvatar('isWarehouse')}</Avatar> }
            { (record.isProducer) && <Avatar style={{color: 'white', backgroundColor: getLocationRoleColor('isProducer'), width: dimension, height: dimension, fontSize: fontSize}}>{letterAvatar('isProducer')}</Avatar> }
            { (record.isDistributor) && <Avatar style={{color: 'white', backgroundColor: getLocationRoleColor('isDistributor'), width: dimension, height: dimension, fontSize: fontSize}}>{letterAvatar('isDistributor')}</Avatar> }
            { (record.isVenue) && <Avatar style={{color: 'white', backgroundColor: getLocationRoleColor('isVenue'), width: dimension, height: dimension, fontSize: fontSize}}>{letterAvatar('isVenue')}</Avatar> }
            { (record.isCollector) && <Avatar style={{color: 'white', backgroundColor: getLocationRoleColor('isCollector'), width: dimension, height: dimension, fontSize: fontSize}}>{letterAvatar('isCollector')}</Avatar> }
            { (record.isContractBrewer) && <Avatar style={{color: 'white', backgroundColor: getLocationRoleColor('isContractBrewer'), width: dimension, height: dimension, fontSize: fontSize}}>{letterAvatar('isContractBrewer')}</Avatar> }
        </Grid>
    )

}

export const ShowRoles = ({record}) => (
    <div>
        {showRoleField('isWarehouse', record.isWarehouse)}
        {showRoleField('isProducer', record.isProducer)}
        {showRoleField('isDistributor', record.isDistributor)}
        {showRoleField('isVenue', record.isVenue)}
        {showRoleField('isCollector', record.isCollector)}
        {showRoleField('isContractBrewer', record.isContractBrewer)}
    </div>
);

export const DashboardCardError = ({ message, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={ErrorIcon} bgColor={'red'} />
        <Card className={classes.card}>
            <Typography variant="headline" component="h1">
                {translate('app.dashboard.errors.cardTitleError')}
            </Typography>
            <Typography variant="subheading" component="h3">
                {message}
            </Typography>
        </Card>
    </div>
);

export function isCustomerFilters (permissions) {
    let filters = null;
    if ( isCustomer(permissions) ) {
        filters = {organisationUuid: localStorage.getItem('oUuid')};
    }
    return filters;
}

export function setOrganisation(record) {
    localStorage.setItem('oUuid', record.id);
    sessionStorage.setItem('organisationName', record.name);
    return '/organisations/'+record.id+'/show';
}

export const packageTypeValidation = (formData) => {
    if( (formData.countryUuid && formData.organisationUuid)
        || (formData.countryUuid && formData.locationUuid)
        || (formData.organisationUuid && formData.locationUuid)
        || (formData.countryUuid && formData.organisationUuid && formData.locationUuid)
    ) {
        return true
    } else {
        return false;
    }
};

export const getReadableDate = (date) => {
    let readableDate = new Date(date);
    let locale = 'en';
    let timezone = (localStorage.getItem('timezone')) ? localStorage.getItem('timezone') : null;

    if(timezone.indexOf("Australia") >= 0){
        locale = 'en-AU';
    }
    if(timezone.indexOf("Pacific") >= 0){
        locale = 'en-NZ';
    }
    if(timezone.indexOf("Europe") >= 0){
        locale = 'en-UK';
    }
    if(timezone.indexOf("America") >= 0){
        locale = 'en-US';
    }

    let options = {
        year: "numeric",
        month: "long",
        day: "2-digit"
    };
    return readableDate.toLocaleDateString(locale, options) //en is language option, you may specify..
}
