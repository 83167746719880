import {isCustomer, isAdmin, isStaff} from "../security/Role";
import React, {Fragment, useState, useEffect} from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import {Error, LinearProgress, ReferenceField, TextField, useQuery} from "react-admin";
import Typography from "@material-ui/core/Typography";
import axios from 'axios';

export const InvoiceTypeName = (type) => {
    switch (type) {
        case 1:
            return 'Order Invoice';
        case 2:
            return 'Monthly Invoice';
        default:
            return null;
    }
}

export const InvoiceStatusName = (status) => {
    switch (status) {
        case 1:
            return <Chip label={'Draft'} style={{backgroundColor: '#434343', color: '#fff'}} />;
        case 2:
            return <Chip label={'Submitted'} style={{backgroundColor: '#0288D1', color: '#fff'}} />;
        case 3:
            return <Chip label={'Syncing'} style={{backgroundColor: '#FBC02D', color: '#fff'}} />;
        case 4:
            return <Chip label={'Syncing Failed'} style={{backgroundColor: '#F44336', color: '#fff'}} />;
        case 5:
            return <Chip label={'Synced'} style={{backgroundColor: '#689F38', color: '#fff'}} />;
        default:
            return null;
    }
}

export const InvoiceListRowStyle = (record, index, defaultStyle = {}) => {
    if (record.invoiceStatus === 1)
        return {
            ...defaultStyle,
            borderLeftColor: '#434343',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.invoiceStatus === 2)
        return {
            ...defaultStyle,
            borderLeftColor: '#0288D1',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.invoiceStatus === 3)
        return {
            ...defaultStyle,
            borderLeftColor: '#FBC02D',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.invoiceStatus === 4)
        return {
            ...defaultStyle,
            borderLeftColor: '#F44336',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.invoiceStatus === 5)
        return {
            ...defaultStyle,
            borderLeftColor: '#689F38',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    return defaultStyle;
};

export const PackageListRowStyle = (record, index, defaultStyle = {}) => {
    if (record.isCountryPackage === true)
        return {
            ...defaultStyle,
            borderLeftColor: '#E04E39',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.isOrganisationPackage === true)
        return {
            ...defaultStyle,
            borderLeftColor: '#00A3E0',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.isLocationPackage === true)
        return {
            ...defaultStyle,
            borderLeftColor: '#6CC24A',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };

    return defaultStyle;
};

export const FullNameAvatar = () => {
    let firstName = (localStorage.getItem('firstName')) ? localStorage.getItem('firstName') : '';
    let lastName = (localStorage.getItem('lastName')) ? localStorage.getItem('lastName') : '';
    let abbr = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

    return (
        <Fragment>
            <Avatar>{abbr}</Avatar>
        </Fragment>
    );
}

export const FullUserName = () => {
    let firstName = ( localStorage.getItem('firstName')) ?  localStorage.getItem('firstName') : '';
    let lastName = (localStorage.getItem('lastName')) ? localStorage.getItem('lastName') : '';
    return (
        <Fragment>
            {firstName} {lastName}
        </Fragment>
    );
}

export function UserRole(roles) {
    let role = null;
    if(!roles) return false;
    if(roles.indexOf('ROLE_CUSTOMER') > -1){
        role = 'Customer'
    } else if(roles.indexOf('ROLE_STAFF') > -1){
        role = 'Operations'
    } else if(roles.indexOf('ROLE_ADMIN') > -1){
        role = 'Administrator'
    } else {
        role = 'Customer';
    }
    localStorage.setItem('userRole', role);
    return role;
}

export function showInvoiceField() {
    let result = false;

    if(process.env.REACT_APP_COUNTRY_UUID_USA !== localStorage.getItem('cUuid') && process.env.REACT_APP_COUNTRY_UUID_UK !== localStorage.getItem('cUuid')) {
        result = true;
    }

    return result;
}

export const FormatChargeDescription = (camelCase) => camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase()).replace(' charge','');

export const ChargeFeeTypes = [
    { id: 1, name: 'Issue Fee' },
    { id: 2, name: 'Daily Rental Fee' },
    { id: 3, name: 'Timed Fee' },
    { id: 4, name: 'Cleaning Fee' },
    { id: 5, name: 'Freight Capital Metro Fee' },
    { id: 6, name: 'Inter-Country Fee' },
    { id: 7, name: 'Taproom Venue Fee' },
    { id: 8, name: 'Taproom Distributor Fee' },
    { id: 9, name: 'Freight Regional Metro Fee' },
    { id: 10, name: 'Freight Regional Fee' },
    { id: 11, name: 'Manual Fee' },
    { id: 12, name: 'Freight Super Regional Fee' },
    { id: 13, name: 'Collection Regional Fee' },
    { id: 14, name: 'Collection Super Regional Fee' }
];

export default function CustomFilters (permissions) {
    if ( isCustomer(permissions) ) {
        return {organisationUuid: localStorage.getItem('oUuid')};
    }
    if ( isAdmin(permissions) || isStaff(permissions) ) {
        return {countryUuid: localStorage.getItem('cUuid')};
    }
}

export const FormatHeaderLabel = (camelCase) => {
    let camelCaseFormatted =  camelCase.charAt(0).toUpperCase() + camelCase.slice(1)
    return camelCaseFormatted.replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace('Display ','');
}


// export const GetPackageDetails = ({ checkedDate, country, organisation, location, sku }) => {
//     const { data, loading, error } = useQuery({
//         type: 'getManyReference',
//         resource: 'packages/details',
//         payload: {checkedDate: checkedDate,
//                     country: country,
//                     organisation: organisation,
//                     location: location,
//                     sku: sku}
//     });
//
//     if (loading) return <LinearProgress />;
//     if (error) return <Error />;
//     if (!data) return null;
//
//     return data;
// };

// export function GetPackageDetails (checkedDate, country, organisation, location, sku) {

// export const GetPackageDetails = ({packageType, uuid}) => {
//     let checkedDate = "2019-01-01 00:00:00";
//     // let apiUrl = process.env.REACT_APP_API_URL+'/packages/details?checkedDate='+checkedDate;
//     let apiUrl = 'http://localhost:81/api/packages/details?checkedDate='+checkedDate;
//
//     switch (packageType) {
//         case 'country':
//             apiUrl += '&country='+uuid;
//             break;
//         case 'organisation':
//             apiUrl += '&organisation='+uuid;
//             break;
//         case 'location':
//             apiUrl += '&location='+uuid;
//             break;
//     }
//
//     const [data, setData] = useState(null);
//     const [url, setUrl] = useState(apiUrl);
//     const [isLoading, setIsLoading] = useState(false);
//     const [isError, setIsError] = useState(false);
//     useEffect(() => {
//         const fetchData = async () => {
//             setIsError(false);
//             setIsLoading(true);
//             try {
//                 const result = await axios(url);
//                 setData(result.data);
//             } catch (error) {
//                 setIsError(true);
//             }
//             setIsLoading(false);
//         };
//         fetchData();
//     }, [url]);
//
//     return (
//         <Fragment>
//
//             {isLoading ? (
//                 <div>
//                     <LinearProgress />
//                 </div>
//             ) : (
//                  console.log(data)
//             )}
//         </Fragment>
//     );
//
// };
