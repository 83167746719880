import CountryList from './CountryList';
import CountryShow from "./CountryShow";
import CountryIcon from '@material-ui/icons/Flag';
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: CountryList,
    show: CountryShow,
    icon: CountryIcon,
    unauthorised: UnauthorisedPage
};
