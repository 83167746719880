import React, { forwardRef, Fragment } from 'react';
import {UserMenu, MenuItemLink, useTranslate, Layout} from 'react-admin';
import MystarAppBar from "./KegstarAppbar";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import NotificationsIcon from '../components/icons/AppbarNotificationIcon';
import { makeStyles } from '@material-ui/core/styles';
import OrganisationHeader from './OrganisationHeader';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {FullNameAvatar, FullUserName, UserRole} from "../components/Utilities";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import Link from "@material-ui/core/Link";
import CardActions from "@material-ui/core/CardActions";
import UserProfileIcon from '../components/icons/AppbarUserProfileIcon';
import MystarAppLogo from "./MystarAppLogo";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    cardContent: {
        backgroundColor: '#fff'
    }
}));

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Fragment>
            <Card>
                <CardHeader
                    avatar={
                        <FullNameAvatar />
                    }
                    action={
                        null
                    }
                    title={<FullUserName />}
                    subheader={UserRole(localStorage.getItem('role'))}
                />
                <CardMedia>
                </CardMedia>
                {/*<Divider component="li" />*/}
                {/*<CardContent className={classes.cardContent} >*/}
                {/*    <Typography variant="caption" color="textPrimary">TODAY</Typography>*/}
                {/*    <Typography variant={'h6'} color="textSecondary">*/}
                {/*        {moment().format('dddd')}*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="caption" color="textSecondary">{moment().format('DD')} {moment().format('MMMM')} {moment().format('YYYY')}</Typography>*/}
                {/*</CardContent>*/}
                <Divider component="li" />
                <CardContent className={classes.cardContent} >
                    {/*<Typography variant="caption" color="textPrimary">GO TO </Typography>*/}
                    <div style={{backgroundColor: '#000'}}>
                        <Box m={1}>
                            <Link href={process.env.REACT_APP_MYSTAR_URL} rel="noreferrer"><img src={ require('../assets/logos/logo-mystar.svg') } alt={''} style={{width: '100px'}}/></Link>
                            {/*<Link href={'/#/mystar'}><img src={ require('../assets/logos/logo-mystar.svg') } alt={''} style={{width: '100px'}}/></Link>*/}
                        </Box>
                    </div>
                </CardContent>
                <Divider component="li" />
                <CardActions disableSpacing>
                </CardActions>
            </Card>

        </Fragment>
    );
});

const CustomUserMenu = (props: any) => (
    <Fragment>
        {/*<Button><NotificationsIcon /></Button>*/}
        <UserMenu {...props} icon={<UserProfileIcon />}>
            <ConfigurationMenu />
        </UserMenu>
    </Fragment>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery('(min-width: 600px)');

    return (
        <MystarAppBar {...props} userMenu={<CustomUserMenu />}>
            <MystarAppLogo />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                // id="react-admin-title"
            />

            { isDesktop && ((localStorage.getItem('oUuid')) && <OrganisationHeader />) }

            <span className={classes.spacer} />


        </MystarAppBar>
    );
};

export default CustomAppBar;
