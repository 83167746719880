export const CountryArray = {
    'AFGHANISTAN': 'AF',
    'ALBANIA': 'AL',
    'ALGERIA': 'DZ',
    'AMERICAN SAMOA': 'AS',
    'ANDORRA': 'AD',
    'ANGOLA': 'AO',
    'ANGUILLA': 'AI',
    'ANTARCTICA': 'AQ',
    'ANTIGUA AND BARBUDA': 'AG',
    'ARGENTINA': 'AR',
    'ARMENIA': 'AM',
    'ARUBA': 'AW',
    'AUSTRALIA': 'AU',
    'AUSTRIA': 'AT',
    'AZERBAIJAN': 'AZ',
    'BAHAMAS, THE': 'BS',
    'BAHRAIN': 'BH',
    'BANGLADESH': 'BD',
    'BARBADOS': 'BB',
    'BELARUS': 'BY',
    'BELGIUM': 'BE',
    'BELIZE': 'BZ',
    'BENIN': 'BJ',
    'BERMUDA': 'BM',
    'BHUTAN': 'BT',
    'BOLIVIA': 'BO',
    'BOSNIA AND HERZEGOVINA': 'BA',
    'BOTSWANA': 'BW',
    'BOUVET ISLAND': 'BV',
    'BRAZIL': 'BR',
    'BRITISH INDIAN OCEAN TERRITORY': 'IO',
    'BRUNEI': 'BN',
    'BULGARIA': 'BG',
    'BURKINA FASO': 'BF',
    'BURMA': 'MM',
    'BURUNDI': 'BI',
    'CAMBODIA': 'KH',
    'CAMEROON': 'CM',
    'CANADA': 'CA',
    'CAPE VERDE': 'CV',
    'CAYMAN ISLANDS': 'KY',
    'CENTRAL AFRICAN REPUBLIC': 'CF',
    'CHAD': 'TD',
    'CHILE': 'CL',
    'CHINA': 'CN',
    'CHRISTMAS ISLAND': 'CX',

    'COCOS (KEELING) ISLANDS': 'CC',
    'COLOMBIA': 'CO',
    'COMOROS': 'KM',
    'CONGO (BRAZZAVILLE) ': 'CG',
    'CONGO (KINSHASA)': 'CD',
    'COOK ISLANDS': 'CK',

    'COSTA RICA': 'CR',
    'CÔTE D’IVOIRE': 'CI',
    'CROATIA': 'HR',
    'CUBA': 'CU',
    'CURAÇAO': 'CW',
    'CYPRUS': 'CY',
    'CZECH REPUBLIC': 'CZ',
    'DENMARK': 'DK',

    'DJIBOUTI': 'DJ',
    'DOMINICA': 'DM',
    'DOMINICAN REPUBLIC': 'DO',
    'ECUADOR': 'EC',
    'EGYPT': 'EG',
    'EL SALVADOR': 'SV',
    'EQUATORIAL GUINEA': 'GQ',
    'ERITREA': 'ER',
    'ESTONIA': 'EE',
    'ETHIOPIA': 'ET',

    'FALKLAND ISLANDS (ISLAS MALVINAS)': 'FK',
    'FAROE ISLANDS': 'FO',
    'FIJI': 'FJ',
    'FINLAND': 'FI',
    'FRANCE': 'FR',
    'FRENCH GUIANA': 'GF',
    'FRENCH POLYNESIA': 'PF',
    'FRENCH SOUTHERN AND ANTARCTIC LANDS': 'TF',
    'GABON': 'GA',
    'GAMBIA, THE': 'GM',

    'GEORGIA': 'GE',
    'GERMANY': 'DE',
    'GHANA': 'GH',
    'GIBRALTAR': 'GI',

    'GREECE': 'GR',
    'GREENLAND': 'GL',
    'GRENADA': 'GD',
    'GUADELOUPE': 'GP',
    'GUAM': 'GU',
    'GUATEMALA': 'GT',
    'GUERNSEY': 'GG',
    'GUINEA': 'GN',
    'GUINEA-BISSAU': 'GW',
    'GUYANA': 'GY',
    'HAITI': 'HT',
    'HEARD ISLAND AND MCDONALD ISLANDS': 'HM',
    'HONDURAS': 'HN',

    'HONG KONG SAR CHINA': 'HK',

    'HUNGARY': 'HU',
    'ICELAND': 'IS',
    'INDIA': 'IN',
    'INDONESIA': 'ID',
    'IRAN': 'IR',
    'IRAQ': 'IQ',
    'IRELAND': 'IE',
    'ISLE OF MAN': 'IM',
    'ISRAEL': 'IL',
    'ITALY': 'IT',
    'JAMAICA': 'JM',

    'JAPAN': 'JP',

    'JERSEY': 'JE',

    'JORDAN': 'JO',

    'KAZAKHSTAN': 'KZ',
    'KENYA': 'KE',

    'KIRIBATI': 'KI',
    'KOREA, NORTH': 'KP',
    'KOREA, SOUTH': 'KR',

    'KUWAIT': 'KW',
    'KYRGYZSTAN': 'KG',
    'LAOS': 'LA',
    'LATVIA': 'LV',
    'LEBANON': 'LB',
    'LESOTHO': 'LS',
    'LIBERIA': 'LR',
    'LIBYA': 'LY',
    'LIECHTENSTEIN': 'LI',
    'LITHUANIA': 'LT',
    'LUXEMBOURG': 'LU',
    'MACAU': 'MO',
    'MACEDONIA': 'MK',
    'MADAGASCAR': 'MG',
    'MALAWI': 'MW',
    'MALAYSIA': 'MY',
    'MALDIVES': 'MV',
    'MALI': 'ML',
    'MALTA': 'MT',
    'MARSHALL ISLANDS': 'MH',
    'MARTINIQUE': 'MQ',
    'MAURITANIA': 'MR',
    'MAURITIUS': 'MU',
    'MAYOTTE': 'YT',
    'MEXICO': 'MX',
    'MICRONESIA, FEDERATED STATES OF': 'FM',

    'MOLDOVA': 'MD',
    'MONACO': 'MC',
    'MONGOLIA': 'MN',
    'MONTENEGRO': 'ME',
    'MONTSERRAT': 'MS',
    'MOROCCO': 'MA',
    'MOZAMBIQUE': 'MZ',
    'NAMIBIA': 'NA',
    'NAURU': 'NR',

    'NEPAL': 'NP',
    'NETHERLANDS': 'NL',
    'NEW CALEDONIA': 'NC',
    'NEW ZEALAND': 'NZ',
    'NICARAGUA': 'NI',
    'NIGER': 'NE',
    'NIGERIA': 'NG',
    'NIUE': 'NU',
    'NORFOLK ISLAND': 'NF',
    'NORTHERN MARIANA ISLANDS': 'MP',
    'NORWAY': 'NO',
    'OMAN': 'OM',
    'PAKISTAN': 'PK',
    'PALAU': 'PW',

    'PANAMA': 'PA',
    'PAPUA NEW GUINEA': 'PG',

    'PARAGUAY': 'PY',
    'PERU': 'PE',
    'PHILIPPINES': 'PH',
    'PITCAIRN ISLANDS': 'PN',
    'POLAND': 'PL',
    'PORTUGAL': 'PT',
    'PUERTO RICO': 'PR',
    'QATAR': 'QA',
    'REUNION': 'RE',
    'ROMANIA': 'RO',
    'RUSSIA': 'RU',
    'RWANDA': 'RW',
    'SAINT BARTHELEMY': 'BL',
    'SAINT HELENA, ASCENSION, AND TRISTAN DA CUNHA': 'SH',
    'SAINT KITTS AND NEVIS': 'KN',
    'SAINT LUCIA': 'LC',
    'SAINT MARTIN': 'MF',
    'SAINT PIERRE AND MIQUELON': 'PM',
    'SAINT VINCENT AND THE GRENADINES': 'VC',
    'SAMOA': 'WS',
    'SAN MARINO': 'SM',
    'SAO TOME AND PRINCIPE': 'ST',
    'SAUDI ARABIA': 'SA',
    'SENEGAL': 'SN',
    'SERBIA': 'RS',
    'SEYCHELLES': 'SC',
    'SIERRA LEONE': 'SL',
    'SINGAPORE': 'SG',
    'SINT MAARTEN': 'SX',
    'SLOVAKIA': 'SK',
    'SLOVENIA': 'SI',
    'SOLOMON ISLANDS': 'SB',
    'SOMALIA': 'SO',
    'SOUTH AFRICA': 'ZA',
    'SOUTH GEORGIA AND SOUTH SANDWICH ISLANDS': 'GS',
    'SOUTH SUDAN': 'SS',
    'SPAIN': 'ES',

    'SRI LANKA': 'LK',
    'SUDAN': 'SD',
    'SURINAME': 'SR',

    'SWAZILAND': 'SZ',
    'SWEDEN': 'SE',
    'SWITZERLAND': 'CH',
    'SYRIA': 'SY',
    'TAIWAN': 'TW',
    'TAJIKISTAN': 'TJ',
    'TANZANIA': 'TZ',
    'THAILAND': 'TH',
    'TIMOR-LESTE': 'TL',
    'TOGO': 'TG',
    'TOKELAU': 'TK',
    'TONGA': 'TO',
    'TRINIDAD AND TOBAGO': 'TT',

    'TUNISIA': 'TN',
    'TURKEY': 'TR',
    'TURKMENISTAN': 'TM',
    'TURKS AND CAICOS ISLANDS': 'TC',
    'TUVALU': 'TV',
    'UGANDA': 'UG',
    'UKRAINE': 'UA',
    'UNITED ARAB EMIRATES': 'AE',
    'UNITED KINGDOM': 'GB',
    'UNITED STATES': 'US',
    'URUGUAY': 'UY',
    'UZBEKISTAN': 'UZ',
    'VANUATU': 'VU',
    'VATICAN CITY': 'VA',
    'VENEZUELA': 'VE',
    'VIETNAM': 'VN',
    'VIRGIN ISLANDS, BRITISH': 'VG',
    'VIRGIN ISLANDS, UNITED STATES ': 'VI',

    'WALLIS AND FUTUNA': 'WF',

    'WESTERN SAHARA': 'EH',
    'YEMEN': 'YE',
    'ZAMBIA': 'ZM',
    'ZIMBABWE': 'ZW'
}

export const CountryIdArray = {
    '52aa17f5-c15f-4ab1-8ee6-8ca7ab481136': 'Australia',
    '81359d1d-c771-428f-8d34-ac9a853c99fe': 'New Zealand',
    '96b787e1-581a-4dba-95a5-35f1080ffa76': 'United Kingdom',
    'a8e590c3-4853-4780-9a9e-b42ce8fac73f': 'United States',
    '88e22b4f-219c-4b21-99c6-275954d4b627': 'Belgium',
    'cc6cf78a-3e7c-43de-8846-22d6ef100980': 'Canada',
    '9f7be1fe-3b33-4bd5-92ee-d1612238d35f': 'Germany',
    '0bdfac89-0687-46bc-b898-efc246b5cdca': 'Ireland',
    '212536b1-aea8-4639-b33b-f36376018498': 'Netherlands',
    'c8c0b354-4b7b-4afa-9457-95fabc47913e': 'Norway',
    '4643ad75-037e-46a7-aa9e-c02614f311d5': 'Switzerland',
}

export default CountryArray;
