import React, { useState, useEffect, useCallback } from 'react';
import {useVersion, useDataProvider, usePermissions} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import DashboardBanner from "./DashboardBanner";
import {isCustomer, isAdmin} from "../security/Role";
import MystarDashboard from "./MystarDashboard";

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Dashboard = () => {
    const { permissions } = usePermissions();
    const [state, setState] = useState({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const fetchData = useCallback(async () => {
        const { data: response } = await dataProvider.getList('datawarehouse', {
            filter: { status: 'pending' },
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        });
        const data1 = response.reduce(nb => ++nb, 0);
        const data2 = response.slice(0, Math.min(10, response.length));
        setState(state => ({ ...state, data1, data2 }));
    }, [dataProvider]);

    useEffect(() => {
        // fetchData();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        data1,
        data2
    } = state;
    return (
        <div>
            { isCustomer(permissions) && <MystarDashboard /> }
            { isAdmin(permissions) && <DashboardBanner /> }
        </div>
    )

    // return isXSmall ? (
    //     <div>
    //
    //     </div>
    // ) : isSmall ? (
    //     <div style={styles.flexColumn}>
    //
    //     </div>
    // ) : (
    //     <div>
    //         { isCustomer(permissions) && <MystarDashboard /> }
    //         { isAdmin(permissions) && <DashboardBanner /> }
    //     </div>
    // );
};

export default Dashboard;
