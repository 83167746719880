import grey from "@material-ui/core/colors/grey";

const DefaultLayoutTheme = {
    palette: {
        primary: {
            light: '#ECA154',
            main: '#6CC24A',
            dark: grey[900],
            contrastText: '#fff',
        },
        secondary: {
            light: grey[900],
            main: '#000',
            dark: '#101010',
            contrastText: '#fff',
        },
    },
    typography: {
        title: {
            fontWeight: 400,
        },
    },
    sidebar: {
        width: 240,
        closedWidth: 55,
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiLayout: {
            root: {
                backgroundColor: '#000'
            }
        },
    },
};

export default DefaultLayoutTheme;
