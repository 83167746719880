import {usePermissions} from "react-admin";

/**
 * @return {boolean}
 */
export function Admin () {
    return (localStorage.getItem('userRole') === 'Administrator');
}
export function isAdmin(permissions) {
    return (permissions && (permissions.indexOf('ROLE_ADMIN') > -1));
}
export function isOperations(permissions) {
    return (permissions && ( (permissions.indexOf('ROLE_ADMIN') > -1) || permissions.indexOf('ROLE_STAFF') > -1 ));
}
/**
 * @return {boolean}
 */
export function Staff () {
    return (localStorage.getItem('userRole') === 'Operations');
}
export function isStaff(permissions) {
    return (permissions && (permissions.indexOf('ROLE_STAFF') > -1));
}
/**
 * @return {boolean}
 */
export function Customer () {
    return (localStorage.getItem('userRole') === 'Customer');
}
export function isCustomer(permissions) {
    return (permissions && (permissions.indexOf('ROLE_CUSTOMER') > -1));
}
export function Guest () {
    return (!(localStorage.getItem('userRole')));
}
export function hasRole(permissions) {
    return (permissions) ? permissions : false;
}
export function currentOrganisation(permissions, organisationUuid) {
    return (permissions) ? permissions : false;
}
