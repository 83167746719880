import React, {Fragment, useCallback} from "react";
import WarningIcon from '@material-ui/icons/Warning';
import Box from "@material-ui/core/Box";
import {useHistory} from "react-router";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

const UnauthorisedPage = ( ) => {

    const history = useHistory();

    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <Fragment>
            <Box m={5}>
            </Box>
            <Grid container alignItems="center" justify="center">
                <Box m={1}><WarningIcon style={{fontSize: '70px'}} color={'error'} /></Box>
            </Grid>
            <Grid container alignItems="center" justify="center">
                <Typography variant={'h5'}>{ ' You are not authorised to view this page' }</Typography>
            </Grid>
            <Grid container alignItems="center" justify="center">
                <Box m={5}>
                    <Button onClick={handleBack} variant={'outlined'}>
                        Go Back
                    </Button>
                </Box>
            </Grid>
        </Fragment>
    )

}

export default UnauthorisedPage;
