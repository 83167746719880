import OrganisationIcon from '@material-ui/icons/Business';

import OrganisationList from './OrganisationList';
import OrganisationShow from './OrganisationShow';
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: OrganisationList,
    show: OrganisationShow,
    icon: OrganisationIcon,
    unauthorised: UnauthorisedPage,
};
