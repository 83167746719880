import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import WeeklyIcon from '@material-ui/icons/DateRange';
import {Typography} from "@material-ui/core";
import {getReadableDate} from "../helpers/Utilities";
import KegstarCircularProgress from "../components/layout/KegstarCircularProgress";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    selectInput: {
        width: '200px',
        backgroundColor: theme.palette.primary.light,
        padding: theme.spacing(1)
    },
    label: {marginBottom: '5px', marginLeft: '2px'}
}));
const WeeklySummaryListSelect = ({organisation, setDate}) => {
    const classes = useStyles();

    let apiUrl = process.env.REACT_APP_API_URL + `/organisation/${organisation}/summary-reports/weekly/list`;

    const [data, setData] = useState(null);
    const [dateValue, setDateValue] = useState('null');

    const [url, setUrl] = useState(apiUrl);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            try {
                const result = await axios(url, { headers: { 'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-api-key': localStorage.getItem('apiKey')
                    } });
                setData(result.data);
            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [url]);

    if (isLoading) return <KegstarCircularProgress />;
    // if (isError) return <Error />;
    if (!data) return null;
    if (data.length <= 0) return null;

    const handleChange = event => {
        setDateValue(event.target.value);
        setDate(event.target.value);
    };

    return (
        <Fragment>
            <FormControl variant="filled" className={classes.formControl}>
                <Typography variant={'subtitle2'} id="weekly-summary" className={classes.label}> Select a weekly summary date</Typography>
                <Select
                    labelId="weekly-summary-date-label"
                    id="weekly-summary-date"
                    value={dateValue}
                    className={classes.selectInput}
                    onChange={handleChange}
                    IconComponent={() => <WeeklyIcon />}
                    renderValue={(selected) => {
                        if (selected === null || selected === 'undefined' || selected === 'null' ) {
                            return 'Current Week';
                        } else {
                            return selected;
                        }
                    }}
                >
                    {data.map(
                        (item, key) =>
                            (
                                (key!==0) && <MenuItem key={item.split(' ')[0]} value={item.split(' ')[0]}>{ (key===0) ? getReadableDate(item.split(' ')[0]) + ' (Current)' : getReadableDate(item.split(' ')[0]) }</MenuItem>
                            )
                    )}

                </Select>
            </FormControl>
        </Fragment>
    )
};

export default WeeklySummaryListSelect;
