import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./SummaryImageStyles";
const useStyles = makeStyles(styles);

const KegCycleStatusImage = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <div>
                <img src={require('../../../assets/images/KegCycleStatus.png')} alt={'alt'} style={{width: '100%'}} className={classes.image}/>
            </div>
        </Fragment>
    )
}

export default KegCycleStatusImage;
