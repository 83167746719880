import React, {Fragment} from "react";
import {Grid, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {KegnovaTextField} from "./KegnovaTextField";

const useStyles = makeStyles(theme => ({
}));

export const ChargeDetailsPanel = ({record}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <Grid container spacing={4} >
                <Grid item xs>
                    <Box><Typography color={'primary'} className={classes.subTitle}>DETAILS</Typography></Box>
                    <KegnovaTextField value={record.description} label={'Description'} multiline={true} style={{width: '250px'}}/>
                    <KegnovaTextField value={record.feeType} label={'Fee Type'} />
                    <KegnovaTextField value={record.quantity} label={'Quantity'} />
                    <KegnovaTextField value={record.unitAmount} label={'Unit Amount'} />
                    <KegnovaTextField value={record.total} label={'Total'} />
                </Grid>
            </Grid>
        </Fragment>
    );
}
