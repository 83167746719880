import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from "@material-ui/core/styles";
import RefreshIconButton from '../components/RefreshIconButton';
import KegstarAppLoader from "../components/loaders/KegstarAppLoader";

const useStyles = makeStyles( theme => ({
        loader: {
            margin: 14,
        },
        button: {
            filter: theme.overrides.iconFilter.filter
        },
    })
    ,
    { name: 'RaLoadingIndicator' }
);


const KegstarLoadingIndicator = ({ classes: classesOverride, className, ...rest }) => {
    const loading = useSelector(state => state.admin.loading > 0);
    const classes = useStyles({ classes: classesOverride });
    return loading ? (
        <KegstarAppLoader />
    ) : (
        <RefreshIconButton className={classes.button} />
    );
};

KegstarLoadingIndicator.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.string,
};

export default KegstarLoadingIndicator;
