import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    BooleanInput, FunctionField, SearchInput, DateInput
} from 'react-admin';
import Link from "@material-ui/core/Link";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import KegstarReferenceInput from "../components/input/KegstarReferenceInput";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";
import {makeStyles} from "@material-ui/core";

const ListFilters = props => (
    <KegstarFilter {...props} >
        <SearchInput className={'searchInputBaseClass'} source={'q'} label={'Order No.'} alwaysOn />
        <KegstarReferenceInput source="fromLocationUuid" reference="locations" alwaysOn>
            <KegstarAutocompleteInput optionText="locationName" />
        </KegstarReferenceInput>
        <KegstarReferenceInput source="toLocationUuid" reference="locations" alwaysOn>
            <KegstarAutocompleteInput optionText="locationName" />
        </KegstarReferenceInput>
        <KegstarReferenceInput source="fromOrganisationUuid" reference="organisations" alwaysOn>
            <KegstarAutocompleteInput optionText="name" />
        </KegstarReferenceInput>
        <KegstarReferenceInput source="toOrganisationUuid" reference="organisations" alwaysOn>
            <KegstarAutocompleteInput optionText="name" />
        </KegstarReferenceInput>
        <KegstarReferenceInput source="skuUuid" reference="skus" label={'Sku'} >
            <KegstarAutocompleteInput optionText="name" />
        </KegstarReferenceInput>
        <DateInput source={'requestedAt[from]'} label={'Requested At From'}  alwaysOn />
        <DateInput source={'requestedAt[to]'} label={'Requested At To'}  alwaysOn />
        <DateInput source={'actionedAt[from]'} label={'Actioned At From'}  />
        <DateInput source={'actionedAt[to]'} label={'Actioned At To'}   />
        <BooleanInput source="onKegLease" label={'KegLease'} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
        <BooleanInput source="cleanKegs" label={'Clean Kegs'} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
    </KegstarFilter>
);

const useStyles = makeStyles(theme => ({
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
}));

const OrderList = ({permissions, ...props}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'O'} title={'Orders'} subheader={'List of Orders'}/>
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <List {...props} filters={<ListFilters />} perPage={25} sort={{ field: 'id', order: 'DESC' }}
                          filter={{countryUuid: localStorage.getItem('cUuid')}}
                          bulkActionButtons={false}
                          actions={<KegstarListActions />}
                          exporter={false}
                    >
                        <Datagrid >
                            <FunctionField render={record => <Link href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`} target="_blank" rel="noreferrer">
                                {record.tracksOrderId}
                            </Link> } label={'Order No'}/>
                            <KegstarReferenceField source="skuUuid" reference="skus" link={false} label={'Sku'} >
                                <TextField emptyText source="name" />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="fromLocationUuid" reference="locations" link={false} label={'From Location'} >
                                <TextField emptyText source="locationName" />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="toLocationUuid" reference="locations" link={false} label={'To Location'} >
                                <TextField emptyText source="locationName" />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="fromOrganisationUuid" reference="organisations" link={false} label={'From Organisation'} >
                                <TextField emptyText source="name" />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="toOrganisationUuid" reference="organisations" link={false} label={'To Organisation'}>
                                <TextField emptyText source="name" />
                            </KegstarReferenceField>
                            <NumberField source="kegsRequested" label={'Kegs Requested'} />
                            <DateField source="requestedAt" />
                            <DateField source="actionedAt" />
                            <BooleanField source={'cleanKegs'} label={'Clean Kegs'}/>
                            <TextField source={'shippingMethod'} label={'Shipping Method'}/>
                            <BooleanField source={'onKegLease'} label={'KegLease'} />
                            <NumberField source="freightQuantity" label={'Freight Quantity'} />
                            <NumberField source="freightPrice" label={'Freight Price'} />
                            <TextField emptyText source={'source'} label={'Source'} />
                            <TextField emptyText source={'poNumber'} label={'PO Number'} />
                            <TextField emptyText source={'note'} label={'Note'} />
                            <TextField emptyText source={'specialInstruction'} label={'Special Instruction'} />
                            {/*<ShowButton variant={'none'} color={'primary'} label={''} />*/}
                        </Datagrid>
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default OrderList;
