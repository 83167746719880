import React, {Fragment, useEffect, useState} from "react";
import WeeklySummaryContent from "./WeeklySummaryContent";
import {Error, usePermissions} from "react-admin";
import axios from "axios";
import KegstarDataLoader from "../components/loaders/KegstarDataLoader";
import WeeklySummaryListSelect from "./WeeklySummaryListSelect";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {isCustomer} from "../security/Role";

const GetWeeklySummaryData = ({organisation, date}) => {

    let apiParameter = '';

    if(date) {
        apiParameter = 'weekly/'+date;
    } else {
        apiParameter = 'current-week';
    }

    let url = process.env.REACT_APP_API_URL + `/organisation/${organisation}/summary-reports/`+apiParameter;

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            try {
                const result = await axios(url, { headers: { 'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-api-key': localStorage.getItem('apiKey')
                    } });

                setData(result.data);
            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [url]);

    if (isLoading) return <KegstarDataLoader />;
    // if (isError) return <Error />;
    if (!data) return null;

    return (
        <WeeklySummaryContent record={data} organisation={organisation} date={date} />
    )
};
const useStyles = makeStyles(theme => ({
    gridContainer: {
        backgroundColor: theme.palette.secondary.light,
    },
    divider: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        margin: 0, padding: 0
    }
}));
const WeeklySummary = ({organisation, dateOfTheWeek}) => {
    const classes = useStyles();
    const [date, setDate] = useState(dateOfTheWeek);
    const {permissions} = usePermissions();
    return (
        <Fragment>
            <Box m={isCustomer(permissions) ? 2 : 0}>
                <Box m={1}></Box>
                <div><Divider className={classes.divider}/></div>
                <div style={{fontSize: '30px',fontWeight: 'bold', marginBottom: '1rem'}}>
                    {'Weekly Summary'}
                </div>
            </Box>
            <Grid container alignItems="flex-start" justify="flex-start" direction="row" className={classes.gridContainer}>

                    <WeeklySummaryListSelect organisation={organisation} setDate={setDate} />
                     {/*<Button > Go to Monthly Summary</Button>*/}

            </Grid>
            <GetWeeklySummaryData organisation={organisation} date={date} />
        </Fragment>
    )

};

export default WeeklySummary;
