import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    DateInput,
    SearchInput
} from 'react-admin';
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import ShowButton from "../components/button/ShowButton";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import KegstarReferenceInput from "../components/input/KegstarReferenceInput";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";
import {makeStyles} from "@material-ui/core";

const ListFilters = props => (
    <KegstarFilter {...props}>
        <SearchInput className={'searchInputBaseClass'} source="q" alwaysOn />
        <TextInput source={'serialNumber'} label={'Serial Number'} alwaysOn />
        <KegstarReferenceInput source="skuUuid" reference="skus" label={'Sku'} alwaysOn>
            <KegstarAutocompleteInput source="name" />
        </KegstarReferenceInput>

        {/*<KegnovaDatePickerInput source="manufacturedAt" label={'Manufactured Date'} />*/}
        <DateInput source="manufacturedAt" label={'Manufactured Date'} alwaysOn/>

    </KegstarFilter>
);

const useStyles = makeStyles(theme => ({
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
}));

const KegList = ({ ...props }) => {
    const classes = useStyles();
    
    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'K'} title={'Kegs'} subheader={'List of Kegs'}/>
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <List {...props} filters={<ListFilters />} perPage={25} bulkActionButtons={false} sort={{ field: 'createdAt', order: 'DESC' }}
                           actions={<KegstarListActions />} exporter={false}
                    >
                        <Datagrid rowClick={'show'}>
                            <TextField emptyText source="serialNumber" label={'Serial Number'}/>
                            <KegstarReferenceField source="skuUuid" reference="skus" label={'Sku'} linkType={false} >
                                <TextField emptyText source="name" />
                            </KegstarReferenceField>
                            <TextField emptyText source="rfidTag" label={'RFID Tag'} />
                            <TextField emptyText source="manufacturer" label={'Manufacturer'} />
                            <DateField source="manufacturedAt" label={'Manufactured At'} />
                            <ShowButton variant={'none'} color={'primary'} label={''} />
                        </Datagrid>
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default KegList;
