import React, {Fragment, useCallback, useState} from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
    getRentalFees,
    displayRentalFee,
    highlightCurrentPsaDiscount,
    DisplayTimedFees, displayMinimumFreightFee, displayTimedFee
} from "./PackageFunctions";
import {PackageSummaryCellHighlight, PackageRentalsCellHighlight} from "../PackageType";
import Chip from "@material-ui/core/Chip";
import WarningIcon from '@material-ui/icons/Warning';
import {styles} from "../PackageStyles";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import PackageDetailsContent from "./PackageDetailsContent";
import Divider from "@material-ui/core/Divider";
import SidebarPackagesMenuIcon from "../../components/icons/SidebarPackagesMenuIcon";
import {useLocation, useParams, useRouteMatch} from "react-router";

const useStyles = makeStyles(styles);


const PackageSummaryContent = ({ type, name, packageSummary, isError }) => {
    const classes = useStyles();
    let location = useLocation();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    let initialView = false;

    if(urlParams.get('updated') || urlParams.get('view')) {
        initialView = true;
    }

    const [showPackageDetails, setShowPackageDetails] = useState(initialView);
    const handleClickPackageDetails = useCallback(() => {
        setShowPackageDetails((showPackageDetails) ? !showPackageDetails : !showPackageDetails);
    }, [
        showPackageDetails,
        setShowPackageDetails
    ]);

    if (isError) {
        return (
            <Fragment>
                <Paper style={{color: 'rgb(102, 60, 0)', backgroundColor: 'rgb(255, 244, 229)', padding: '1em', display: 'flex'}}>
                    <div><WarningIcon /></div>
                    <div style={{padding: '3px', paddingLeft: '10px'}}> Unable to retrieve package information. Please try again later.</div>
                </Paper>
            </Fragment>
        )
    }

    const skuHeaders = ['KS20A', 'KS20S','KS30A','KS30D','KS30S','KS50A','KS50D','KS50S','KS1/2D','KS1/6D','KS9GC'];
    const rentalFees = getRentalFees(packageSummary);
    const rentalCount = (packageSummary.rentalCounts) ? packageSummary.rentalCounts.previousQuarter : false;

    return (
        <Fragment>
            <Box m={2}>
                <Button onClick={handleClickPackageDetails} variant={'outlined'} className={classes.showPackageButton} > <SidebarPackagesMenuIcon /> &nbsp; &nbsp; Show Package { (showPackageDetails) ? 'Summary' : 'Details' } </Button>
            </Box>
            { showPackageDetails && <PackageDetailsContent type={type} name={name} packageDetails={packageSummary} setShowPackageDetails={setShowPackageDetails} /> }
            { !showPackageDetails &&
                <Fragment>
                    <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                        <Grid item xs>
                            <Box m={2}>
                                <Divider className={classes.divider} />
                                <Typography variant={'h5'}> PACKAGE SUMMARY </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                        <Box m={1}>
                            <Chip className={classes.legend} label={'LEGEND'}/>
                            <Chip variant="outlined" className={classes.countryLegend} label={'Country'}/>
                            <Chip variant="outlined" className={classes.organisationLegend} label={'Organisation'}/>
                            <Chip variant="outlined" className={classes.locationLegend} label={'Location'}/>
                        </Box>
                        {/*<PackageExplorerFilter setCheckedDate={setCheckedDate} setSku={setSku} />*/}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box m={2}>
                                <Typography variant={'h5'}> RENTALS </Typography>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tableCellLabel}>SKU</TableCell>
                                        {skuHeaders.map(skuHeader => (<TableCell> {skuHeader} </TableCell>))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Issue Fee</TableCell>
                                        {skuHeaders.map(skuHeader => (<TableCell
                                            className={PackageRentalsCellHighlight(classes, rentalFees, skuHeader, 'issueFee')}>
                                            {displayRentalFee(rentalFees, skuHeader, 'issueFee')} </TableCell>))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Daily Rental Fee</TableCell>
                                        {skuHeaders.map(skuHeader => (<TableCell
                                            className={PackageRentalsCellHighlight(classes, rentalFees, skuHeader, 'dailyRentalFee')}>
                                            {displayRentalFee(rentalFees, skuHeader, 'dailyRentalFee')} </TableCell>))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Cleaning Fee</TableCell>
                                        {skuHeaders.map(skuHeader => (<TableCell
                                            className={PackageRentalsCellHighlight(classes, rentalFees, skuHeader, 'cleaningFee')}>
                                            {displayRentalFee(rentalFees, skuHeader, 'cleaningFee')} </TableCell>))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Taproom to Venue Fee</TableCell>
                                        {skuHeaders.map(skuHeader => (<TableCell
                                            className={PackageRentalsCellHighlight(classes, rentalFees, skuHeader, 'taproomVenueFee')}>
                                            {displayRentalFee(rentalFees, skuHeader, 'taproomVenueFee')} </TableCell>))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Taproom to Distributor Fee</TableCell>
                                        {skuHeaders.map(skuHeader => (<TableCell
                                            className={PackageRentalsCellHighlight(classes, rentalFees, skuHeader, 'taproomDistributorFee')}>
                                            {displayRentalFee(rentalFees, skuHeader, 'taproomDistributorFee')} </TableCell>))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Timed Fees - 360 days</TableCell>
                                        {skuHeaders.map(skuHeader => (<TableCell
                                            className={PackageRentalsCellHighlight(classes, rentalFees, skuHeader, 'billedAtDay360')}
                                        >
                                            { displayRentalFee(rentalFees, skuHeader, 'billedAtDay360')} </TableCell>)) }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                    <Box m={3}></Box>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Box m={2}>
                                <Typography variant={'h5'}> FREIGHT </Typography>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell>Fee Type</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Minimum Freight Fee</TableCell>
                                        <TableCell>
                                            {displayMinimumFreightFee(packageSummary.packages, 'minimumFee')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Freight Fee - Capital
                                            Metro</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'freightCapitalMetroFee')}>
                                            {packageSummary.packageSetGroup.freightCapitalMetroFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Freight Fee -  Regional
                                            Metro</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'freightRegionalMetroFee')}>
                                            {packageSummary.packageSetGroup.freightRegionalMetroFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Freight Fee - Regional</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'freightRegionalFee')}>
                                            {packageSummary.packageSetGroup.freightRegionalFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Freight Fee - Super
                                            Regional</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'freightSuperRegionalFee')}>
                                            {packageSummary.packageSetGroup.freightSuperRegionalFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Customer Pickup Fee</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'freightPickUpFee')}>
                                            {packageSummary.packageSetGroup.freightPickUpFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Pallet Fee</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'palletFee')}>
                                            {packageSummary.packageSetGroup.palletFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Express Fee</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'expressFee')}>
                                            {packageSummary.packageSetGroup.expressFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Non-Mystar Fee</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'nonMystarFee')}>
                                            {packageSummary.packageSetGroup.nonMystarFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Inter- Country Fee</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'interCountryFee')}>
                                            {packageSummary.packageSetGroup.interCountryFee}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={2}>
                            <Box m={2}></Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box m={2}>
                                <Typography variant={'h5'}> COLLECTION </Typography>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell>Fee Type</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Collection Fee - Regional</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'collectionRegionalFee')}>
                                            {packageSummary.packageSetGroup.collectionRegionalFee}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Collection Fee - Super
                                            Regional</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'collectionSuperRegionalFee')}>
                                            {packageSummary.packageSetGroup.collectionSuperRegionalFee}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                    <Box m={3}></Box>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Box m={2}>
                                <Typography variant={'h5'}> TIMED FEES </Typography>
                            </Box>
                            <Table>
                                <TableRow className={classes.tableRow}>
                                    <TableCell>Billed At</TableCell>
                                    <TableCell>Timed Fees</TableCell>
                                </TableRow>
                                <TableBody>
                                    <DisplayTimedFees packages={packageSummary.packages} classes={classes}/>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                    <Box m={3}></Box>

                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box m={2}>
                                <Typography variant={'h5'}> PAYMENT TERMS </Typography>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell>Invoice Type</TableCell>
                                        <TableCell>Order PSA Invoice</TableCell>
                                        <TableCell>Order Non-PSA Invoice</TableCell>
                                        <TableCell>Monthly PSA Invoice</TableCell>
                                        <TableCell>Monthly Non-PSA Invoice</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellLabel}>Number of Days</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'orderPsaInvoicePaymentDays')}>
                                            {packageSummary.packageSetGroup.orderPsaInvoicePaymentDays}</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'orderInvoicePaymentDays')}>
                                            {packageSummary.packageSetGroup.orderInvoicePaymentDays}</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'monthlyPsaInvoicePaymentDays')}>
                                            {packageSummary.packageSetGroup.monthlyPsaInvoicePaymentDays}</TableCell>
                                        <TableCell
                                            className={PackageSummaryCellHighlight(classes, packageSummary.packages, 'monthlyInvoicePaymentDays')}>
                                            {packageSummary.packageSetGroup.monthlyInvoicePaymentDays}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                    <Box m={3}></Box>

                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box m={2}>
                                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                                    <Typography variant={'h5'}> PSA DISCOUNTS </Typography>
                                </Grid>
                                <Grid container alignItems="flex-end" justify="flex-end" direction="row">
                                    { (rentalCount > 0) &&
                                    <Chip variant="outlined" style={{backgroundColor: '#FEDB00'}} label={'Current PSA'}/>}
                                </Grid>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Lower Bound</TableCell>
                                        <TableCell>Upper Bound</TableCell>
                                        <TableCell>Percentage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (packageSummary.packageSetGroup.psaDiscountGroup)
                                            ?
                                            packageSummary.packageSetGroup.psaDiscountGroup.psaDiscounts.map(
                                                item => {
                                                    let currentPsaDiscount = rentalCount ? highlightCurrentPsaDiscount(rentalCount, item.lowerBound, item.upperBound) : false;
                                                    return (
                                                        <TableRow key={item.uuid}
                                                                  className={currentPsaDiscount
                                                                      ? classes.highlightCurrentPsaDiscountRow : null}>
                                                            <TableCell>{item.description}</TableCell>
                                                            <TableCell>{item.lowerBound}</TableCell>
                                                            <TableCell>{item.upperBound}</TableCell>
                                                            <TableCell>{item.percentage}</TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            )
                                            :
                                            <TableRow>
                                                <TableCell colSpan={4}>No PSA Discounts</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Fragment>

            }

        </Fragment>
    )
}

export default PackageSummaryContent;
