const PackageType = (record) => {

    if(record.isCountryPackage) {
        return 'country'
    } else if (record.isOrganisationPackage) {
        return 'organisation'
    } else if (record.isLocationPackage) {
        return 'location'
    }

    return 'country'
}

export const PackageRentalsCellHighlight = (classes, rentalFees, sku, feeType) => {

    let overriddenBy = null;

    if(typeof rentalFees[sku] !== 'undefined') {
        Object.keys(rentalFees[sku]).map(key => {
            if(rentalFees[sku][key][feeType] !== null) {
                overriddenBy = rentalFees[sku][key].overriddenBy;
            }
        });
    }

    if(overriddenBy === 'country') {
        return null
    } else if(overriddenBy === 'organisation') {
        return classes.highlightCellOrganisation
    } else if(overriddenBy === 'location') {
        return classes.highlightCellLocation
    }

    return null;
}

export const PackageSummaryCellHighlight = (classes, record, freightFeeType) => {

    let className = null;

    for (let i=0; i<record.length; i++) {
        if(record[i]['isCountryPackage'] && typeof record[i][freightFeeType] !== 'undefined' && record[i][freightFeeType] !== null) {
            className = null;
        } else if (record[i]['isOrganisationPackage'] && typeof record[i][freightFeeType] !== 'undefined' && record[i][freightFeeType] !== null) {
            className =  classes.highlightCellOrganisation
        } else if (record[i]['isLocationPackage'] && typeof record[i][freightFeeType] !== 'undefined' && record[i][freightFeeType] !== null) {
            className =  classes.highlightCellLocation
        }
    }

    return className;
}

export default PackageType;
