import React, {Fragment} from 'react';
import {
    ListController,
    ListView,
    Datagrid,
    TextField,
    DateField,
    FunctionField, DateInput, Link, EditButton
} from 'react-admin';
import SidebarPackagesMenuIcon from "../components/icons/SidebarPackagesMenuIcon";

import {makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import {PackageListRowStyle} from "../components/Utilities";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import PackageTypeFilterValidation from '../components/dialogs/PackageTypeFilterValidation'
import {packageTypeValidation} from "../helpers/Utilities";
import KegstarReferenceInput from "../components/input/KegstarReferenceInput";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    viewPackage: {
      textAlign: 'center'
    },
    packageTypeFilterSmall: {
        width: '12em'
    },
    packageTypeFilterLarge: {
        width: '20em'
    }
}));

const ListFilters = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const validatePackageType = (value, allValues) => {
        let status = packageTypeValidation(allValues);
        if(status) {
            setOpen(true);
            return 'Select 1 package type - Country, Organisation or Location';
        } else {
            setOpen(false);
            return [];
        }
    };

    return (
        <div>
            <KegstarFilter {...props}>
                <KegstarReferenceInput source="countryUuid" reference="packages/countries" filter={{countryUuid: 'all'}} perPage={250} label={'Country'}
                                       sort={{ field: 'id', order: 'ASC' }} onChange={ (record) => { localStorage.setItem('cUuid', record) } } alwaysOn
                >
                    <KegstarAutocompleteInput optionText="name" />
                </KegstarReferenceInput>
                <KegstarReferenceInput source="organisationUuid" reference="organisations" className={classes.packageTypeFilterLarge} label={'Organisation'} validate={validatePackageType} alwaysOn>
                    <KegstarAutocompleteInput optionText="name" />
                </KegstarReferenceInput>
                <KegstarReferenceInput source="locationUuid" reference="locations" className={classes.packageTypeFilterLarge} label={'Location'} validate={validatePackageType} alwaysOn>
                    <KegstarAutocompleteInput optionText="locationName" />
                </KegstarReferenceInput>
                <KegstarReferenceInput source="skuUuid" reference="skus" label={'Sku'}>
                    <KegstarAutocompleteInput optionText="name" />
                </KegstarReferenceInput>

                {/*<KegnovaDatePickerInput source="createdAt" label={'Package Date'} />*/}
                <DateInput source="startDate[from]" label={'Start Date From'} alwaysOn />
                <DateInput source="startDate[to]" label={'Start Date To'}  />
                <DateInput source="endDate[from]" label={'End Date From'} alwaysOn/>
                <DateInput source="endDate[to]" label={'End Date To'} />

            </KegstarFilter>
            { open && <PackageTypeFilterValidation /> }
        </div>
    )
}

const PackageList = (props) => {
    const classes = useStyles();

    return(
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'P'} title={'Packages'} subheader={'List of Packages'}/>
                <CardContent style={{backgroundColor: '#EDEEEF'}}>

                    {/* Content Start */}
                    <ListController {...props} sort={{ field: 'createdAt', order: 'DESC' }} >
                        {controllerProps =>
                            <ListView {...props} {...controllerProps} filters={<ListFilters />} bulkActionButtons={false}
                                       actions={<KegstarListActions />}
                                      filter={{countryUuid: localStorage.getItem('cUuid')} }
                            >
                                <Datagrid
                                    // expand={<FunctionField render={record => <PackageDetailsPanel record={record} /> } addLabel={false} />}
                                    rowStyle={PackageListRowStyle} >

                                    <FunctionField render={record => <div className={classes.viewPackage} ><PackageViewLink record={record} /> </div>}
                                    style={{textAlign: 'center'}}
                                                   addLabel={false} />

                                    <TextField emptyText source={'displayId'} label={'Id'}/>
                                    <DateField source="startDate"  />
                                    <DateField source="endDate" />

                                    <KegstarReferenceField source="skuUuid" reference="skus" link={false} label={'Sku'} allowEmpty>
                                        <TextField emptyText source="name" />
                                    </KegstarReferenceField>

                                    <KegstarReferenceField source="countryUuid" reference="countries" link="show" label={'Country'} >
                                        <TextField emptyText source="name"/>
                                    </KegstarReferenceField>

                                    <KegstarReferenceField source="organisationUuid" reference="organisations" link="show" label={'Organisation'} >
                                        <TextField emptyText source="name"/>
                                    </KegstarReferenceField>

                                    <KegstarReferenceField source="locationUuid" reference="locations" link="show" label={'Location'} >
                                        <TextField emptyText source="locationName"/>
                                    </KegstarReferenceField>

                                    <EditButton basePath={'/packages'} />
                                </Datagrid>
                            </ListView>
                        }
                    </ListController>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
}

const PackageViewLink = ({record}) => {

    let url = null;

    if(record.isCountryPackage) {
        url = '/packages/countries/03fbbfe1-35ae-4ca0-8e37-b21ca07dc39a/show';
    } else if (record.isOrganisationPackage) {
        url = '/organisations/03fbbfe1-35ae-4ca0-8e37-b21ca07dc39a/show/packages';
    } else if (record.isLocationPackage) {
        url = '/locations/03fbbfe1-35ae-4ca0-8e37-b21ca07dc39a/show/packages';
    }

    return <Link to={url}> <SidebarPackagesMenuIcon /> </Link>

}

export default PackageList;
