export const styles = (theme => ({
    root: {
        flexGrow: 1,
    },
    paperContainer: {
        backgroundColor: theme.palette.secondary.light
    },
    cardContainer: {
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 0
    },
    cardContentContainer: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 0
    },
    divider: {border: '1px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
    averageLabels: {
        display: 'inline',
        float: 'left',
        verticalAlign: '-webkit-baseline-middle'
    },
    scanLabels: {
        display: 'inline',
        float: 'left'
    },
    table: {
        backgroundColor: theme.palette.primary.light
    },
    tableCell: {
        border: '2px solid ' + theme.palette.secondary.light
    },
    tableCellIcon: {
        border: '2px solid ' + theme.palette.secondary.light

    },
    tableCellTitle: {
        border: '2px solid ' + theme.palette.secondary.light,
        fontFamily: 'NettoOT Bold',
        fontSize: 'larger',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    tableCellSubTitle: {
        border: '2px solid ' + theme.palette.secondary.light

    },
    tableCellBannerImage: {
        border: '2px solid ' + theme.palette.secondary.light,
        padding: '0px',
        paddingRight: '0px !important'
    },
    tableCellBalanceSummary: {
        border: '2px solid ' + theme.palette.secondary.light,
        fontFamily: 'NettoOT Bold',
        fontSize: '60px',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    tableCellLabel: {
        border: '2px solid ' + theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary.light,
        fontSize: 'medium'
    },
    tableCellBalanceTotal: {
        border: '2px solid ' + theme.palette.secondary.light,
        fontFamily: 'NettoOT Bold'
    },
    tableCellMiniIcon: {
        border: '2px solid ' + theme.palette.secondary.light,
        borderLeft: 0,
        textAlign: 'right'
    },
    tableCellKegCycleStatusData: {
        border: '2px solid ' + theme.palette.secondary.light,
        fontFamily: 'NettoOT Bold',
        fontSize: '60px'
    },
}));

