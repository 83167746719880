import MystarInvoiceItemList from './MystarInvoiceItemList';
import MystarInvoiceItemShow from './MystarInvoiceItemShow';
import InvoiceItemIcon from '@material-ui/icons/ListAlt';
import UnauthorisedPage from "../../components/UnauthorisedPage";

export default {
    list: MystarInvoiceItemList,
    show: MystarInvoiceItemShow,
    icon: InvoiceItemIcon,
    unauthorised: UnauthorisedPage
};
