import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const ChargesExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            id,
            uuid,
            rentalUuid,
            organisationUuid,	countryUuid,	countryName,	chargeType	,feeType, draftInvoiceItemUuid,
            invoiceCreatedAt, applicableDate, billingCycleStartDate, billingCycleEndDate, feeTypeText, invoiceItemType,
            createdAtText, netsuiteInvoiceCreatedAt, createdAt, draftInvoiceUuid, invoiceType, organisationName, organisationTimezone, skuName,

            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: ['invoiceDocNumber', 'netsuiteInvoiceCreatedAtText', 'chargeDescription', 'applicableDateText',
            'kegSerialNumber', 'billingCycleStartDateText', 'billingCycleEndDateText', 'quantity', 'unitAmount', 'total', 'invoiceTypeText', 'chargeTypeText', 'stopKegRoleLocationName'] // order fields in the export
        ,
        rename:[
            'Invoice No.', 'Invoice Date', 'Description', 'Applicable Date',
            'Asset Code/Serial Number', 'Cycle Start Date', 'Cycle End Date', 'Quantity', 'Unit Amount', 'Total', 'Invoice Type', 'Type', 'Cycle Stop Location'
    ]
    }, (err, csv) => {
        downloadCSV(csv, 'Charges'); // download as 'posts.csv` file
    });

};

export default ChargesExport;
