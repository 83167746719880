import React, {Fragment} from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Avatar from "@material-ui/core/Avatar";
import HistoryButtons from "../HistoryButtons";
import CardHeader from "@material-ui/core/CardHeader";
import {makeStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from "../KegstarBreadcrumbs";

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.background.default
    },
    divider: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        margin: 0, padding: 0
    }
}));

const Title = ({title}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <div style={{paddingTop: '20px'}}>
                <div><Divider className={classes.divider}/></div>
                <div style={{fontSize: '30px',fontWeight: 'bold'}}>
                    {title}
                </div>
            </div>

        </Fragment>
    )
}

const History = () => {

    return (
        <Fragment>
            <HistoryButtons />
        </Fragment>
    )
}

const KegstarCardHeader = ({avatar, title, subheader, ...props}) => {
    const classes = useStyles();

    return (
        <CardHeader
            // avatar={
            //     <Avatar className={classes.avatar}>{avatar}</Avatar>
            // }
            className={classes.header}
            // action={
            //
            // }
            title={<Title title={title}/>}
            // titleTypographyProps={{variant:'h5'}}
            subheader={<Breadcrumbs {...props} />}
        />
    );
}

export default KegstarCardHeader;
