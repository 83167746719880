import React, {Fragment} from 'react';
import {
    Show, SimpleShowLayout, TextField, ReferenceField,
    ReferenceManyField, Datagrid, FunctionField, List
} from 'react-admin';
import Card from "@material-ui/core/Card";
import {CardContent, CardHeader, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import LocationMap from "../locations/LocationMap";
import Link from "@material-ui/core/Link";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import {KegnovaTextField} from "../components/KegnovaTextField";
import KegnovaDatePickerField from "../components/KegnovaDatePickerField";
import KegstarReferenceManyField from "../components/field/KegstarReferenceManyField";
import KegstarReferenceField from "../components/field/KegstarReferenceField";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
}));

const FromToLocation = ({record}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={8} className={classes.root} >
            <Grid item xs>
                <Card>
                    <CardHeader title="From Location" subheader="Location where the order is coming from"
                                titleTypographyProps={{variant: 'title', color: 'primary' }} />
                    <CardContent className={classes.root} >
                        <KegstarReferenceManyField
                            reference="locations"
                            source={'fromLocationUuid'}
                            target="uuid"
                            basePath="/locations"
                            record={record}
                            linkType={false}
                        >
                            <Datagrid>
                                <TextField emptyText source="locationName" label={'Location'} style={{fontWeight: 'bold', fontSize: '1.25rem'}} />
                                <TextField emptyText source="streetAddress" label={'Address'} />
                                <FunctionField source="locationMap" label="Map"
                                               render={record => <LocationMap {...record} mapHeight={250}
                                                                              mapWidth={300} />}/>

                            </Datagrid>
                        </KegstarReferenceManyField>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs>
                <Card>
                    <CardHeader title="To Location" subheader="The location destination of the order"
                                titleTypographyProps={{variant: 'title', color: 'primary'  }} />
                    <CardContent className={classes.root} >
                        <KegstarReferenceManyField
                            reference="locations"
                            source={'toLocationUuid'}
                            target="uuid"
                            basePath="/locations"
                            record={record}
                            linkType={false}
                        >
                            <Datagrid>
                                <TextField emptyText source="locationName" label={'Location'} style={{fontWeight: 'bold', fontSize: '1.25rem'}} />
                                <TextField emptyText source="streetAddress" label={'Address'} />
                                <FunctionField source="locationMap" label="Map"
                                               render={record => <LocationMap {...record} mapHeight={250}
                                                                              mapWidth={300} />}/>

                            </Datagrid>
                        </KegstarReferenceManyField>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
};

const FromToOrganisation = ({record}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={8} className={classes.root}>
            <Grid item xs>
                <Card>
                    <CardHeader title="From Organisation" subheader="Origin Organisation"
                                titleTypographyProps={{variant: 'title', color: 'primary'  }} />
                    <CardContent>
                        <KegstarReferenceField
                            reference="organisations"
                            source={'fromOrganisationUuid'}
                            target="uuid"
                            basePath="/organisations"
                            record={record}
                            link="show"
                        >
                            <TextField emptyText source="name" label={'Organisation'} style={{fontWeight: 'bold', fontSize: '1.25rem'}} />
                        </KegstarReferenceField>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs>
                <Card>
                    <CardHeader title="To Organisation" subheader="Destination Organisation"
                                titleTypographyProps={{variant: 'title', color: 'primary'  }} />
                    <CardContent >
                        <KegstarReferenceField
                            reference="organisations"
                            source={'toOrganisationUuid'}
                            target="uuid"
                            basePath="/organisations"
                            record={record}
                            link="show"
                        >
                            <TextField emptyText source="name" label={'Organisation'} style={{fontWeight: 'bold', fontSize: '1.25rem'}} />
                        </KegstarReferenceField>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
};

const OrderNumber = ({record}) => (
    <Grid container spacing={8}>
        <Grid item xs>
            <Typography variant={'title'} color={'primary'}>
                <Link href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`}
                      target="_blank" rel="noreferrer"> Order #{record.tracksOrderId}
                </Link>
            </Typography>
        </Grid>
    </Grid>

);

const OrderDetails = ({record}) => (
    <Fragment>
        <Grid container spacing={8}>
            <Grid item xs>

                <KegnovaTextField value={record.kegsRequested} label={'No. of Kegs Requested'} />
                <KegstarReferenceField
                    reference="skus"
                    source={'skuUuid'}
                    target="uuid"
                    basePath="/skus"
                    record={record}
                    link={false}
                    addLabel={false}
                >
                    <FunctionField render={record => <KegnovaTextField value={record.name} label={'Sku'} /> } addLabel={false} />
                </KegstarReferenceField>
                <KegnovaTextField value={record.shippingMethod} label={'Shipping Method'} />
                <KegnovaTextField value={record.source} label={'Source'} />
                <KegnovaTextField value={(record.cleanKegs) ? 'Yes' : 'No'} label={'Clean Kegs'} />
                <KegnovaTextField value={record.poNumber} label={'PO Number'} />
                <KegnovaTextField value={record.freightQuantity} label={'Freight Quantity'} />
                <KegnovaTextField value={(record.onKegLease) ? 'Yes' : 'No'} label={'On Keglease'} />
                <KegnovaDatePickerField name={'requestedAt'} value={record.requestedAt} label={'Requested At'} readOnly={true} />
                <KegnovaDatePickerField name={'actionedAt'} value={record.actionedAt} label={'Actioned At'} readOnly={true} />

            </Grid>
        </Grid>

    </Fragment>
);

const OrderShow = ({ record, ...props }) => {

    const classes = useStyles();
    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'O'} title={'Order'} subheader={'Details'}/>
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <Show {...props}
                    >
                        <SimpleShowLayout className={classes.root}>
                            <OrderNumber />
                            <Divider style={{marginTop: '1em', marginBottom: '1em'}}/>
                            <OrderDetails />
                            <FromToLocation />
                            <FromToOrganisation />
                        </SimpleShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default OrderShow;
