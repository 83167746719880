import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    appLogo: {
        width: '22px',
        paddingRight: '5px'
    },
    logo: {
        padding: '5px',
        paddingTop: '10px'
    },
    kegstarLogo: {
        width: '110px'
    }
}));

export default function MystarAppLogo() {
    const classes = useStyles();

    return (
        <Fragment>

            <div className={classes.logo}>
                <img src={ require('../assets/logos/Kegstar-logo.svg') } className={classes.kegstarLogo} alt={''} />
            </div>

        </Fragment>
    );
}
