import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles( theme => ({
        button: {
            width: '25px',
            filter: theme.overrides.iconFilter.filter
        },
    })
);
const KegstarIcon = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <img src={require('../../assets/images/KegIcon.svg')} alt={'alt'} className={classes.button} />
        </Fragment>
    )
}

export default KegstarIcon;
