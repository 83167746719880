import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField,
    TextInput,
    SelectInput, DateInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {ChargeFeeTypes} from "../components/Utilities";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import KegstarReferenceInput from "../components/input/KegstarReferenceInput";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";

export const styles = (theme => ({
    organisationFilter: {
        width: '20em'
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = ({classes, ...props}) => (
    <KegstarFilter {...props}>
        <SelectInput source="feeType" label={'Fee Type'} choices={ChargeFeeTypes} style={{width: '15em'}} alwaysOn />
        <SelectInput source="invoiceType" label={'Invoice Type'} choices={[
            { id: 1, name: 'Order' },
            { id: 2, name: 'Monthly' }
        ]} options={{variant:'outlined'}} alwaysOn />
        <TextInput source={'tracksOrderId'} label={'Order No.'} resettable alwaysOn/>
        <DateInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn/>
        <DateInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn/>
        {/*<KegnovaDatePickerInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn/>*/}
        {/*<KegnovaDatePickerInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn/>*/}

        <DateInput source="invoiceDueDate" label={'Invoice Due Date'} inputVariant={'standard'} alwaysOn/>
        <DateInput source="applicableDate" label={'Applicable Date'} alwaysOn/>
        <DateInput source="rentalStartDate" label={'Cycle Start Date'} alwaysOn/>

        <KegstarReferenceInput source="skuUuid" reference="skus" label={'Sku'} alwaysOn>
            <KegstarAutocompleteInput optionText="name" />
        </KegstarReferenceInput>

    </KegstarFilter>
);

const ChargeList = props => {

    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'C'} title={'Charges'} subheader={'List of charges'}/>
                <CardContent className={classes.cardContent}>
                    {/* Content Start */}
                    <List {...props} filters={<ListFilters classes={classes} />} sort={{ field: 'id', order: 'DESC' }} perPage={10} bulkActionButtons={false}
                          actions={<KegstarListActions />}
                          exporter={false}
                    >
                        <Datagrid>
                            <TextField emptyText source="displayId" label={'Id'} sortable={false}/>
                            <KegstarReferenceField source="organisationUuid" reference="organisations" label={'Organisation'} link={'show'}>
                                <TextField emptyText source="name" />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Type'} link={false} allowEmpty>
                                <KegstarReferenceField source="draftInvoiceUuid" reference="invoices/draft" addLabel={false} link={false}>
                                    <FunctionField render={record => (record.type===1) ? 'Order' : 'Monthly' } addLabel={false} link={false} />
                                </KegstarReferenceField>
                            </KegstarReferenceField>
                            <KegstarReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Number'} link={false} allowEmpty>
                                <KegstarReferenceField source="draftInvoiceUuid" reference="invoices" addLabel={false} link={false}>
                                    <TextField emptyText source="netsuiteInvDocNumber" />
                                </KegstarReferenceField>
                            </KegstarReferenceField>
                            <KegstarReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Date'} link={false} allowEmpty>
                                <KegstarReferenceField source="draftInvoiceUuid" reference="invoices/draft" addLabel={false} link={false}>
                                    <DateField source="createdAt" />
                                </KegstarReferenceField>
                            </KegstarReferenceField>
                            <TextField emptyText source="description" label={'Description'} />
                            <TextField emptyText source="kegSerialNumber" label={'Asset Code'} />
                            <KegstarReferenceField source="orderUuid" reference="orders" label={'Order Id'} link={false} sortable={false}>
                                <FunctionField
                                    render={record => <Link href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`}
                                                            target="_blank" rel="noreferrer"> {record.tracksOrderId}
                                    </Link> } />
                            </KegstarReferenceField>
                            <DateField source="applicableDate" label={'Applicable Date'}   />
                            <KegstarReferenceField source="skuUuid" reference="skus" label={'Sku'} link={false} allowEmpty>
                                <TextField emptyText source="name" />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="rentalUuid" reference="rentals" label={'Cycle Start Date'} link={false} allowEmpty>
                                <DateField source="startDate" />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="rentalUuid" reference="rentals" label={'Cycle Stop Date'} link={false} allowEmpty>
                                <DateField source="endDate"  />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="orderUuid" reference="orders" label={'Cycle Stop Location'} link={false} allowEmpty>
                                <KegstarReferenceField source="toLocationUuid" reference="locations" addLabel={false} link={false} >
                                    <TextField emptyText source="locationName" />
                                </KegstarReferenceField>
                            </KegstarReferenceField>
                            <KegstarReferenceField source="orderUuid" reference="orders" label={'Current Location'} link={false} allowEmpty>
                                <KegstarReferenceField source="fromLocationUuid" reference="locations" addLabel={false} link={false} >
                                    <TextField emptyText source="locationName" />
                                </KegstarReferenceField>
                            </KegstarReferenceField>
                            <KegstarReferenceField source="orderUuid" reference="orders" label={"Location's Organisation"} link={false} allowEmpty>
                                <KegstarReferenceField source="fromOrganisationUuid" reference="organisations" addLabel={false} link={false} >
                                    <TextField emptyText source="name" />
                                </KegstarReferenceField>
                            </KegstarReferenceField>
                            <NumberField source={'quantity'}/>
                            <NumberField source={'unitAmount'}/>
                            <NumberField source={'total'}/>

                        </Datagrid>
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );

}

export default ChargeList;

