import LocationList from './LocationList';
import LocationShow from './LocationShow';
import LocationIcon from '@material-ui/icons/Map';
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: LocationList,
    show: LocationShow,
    icon: LocationIcon,
    unauthorised: UnauthorisedPage
};
