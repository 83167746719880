import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import MystarInvoiceList from './MystarInvoiceList';
import MystarInvoiceShow from './MystarInvoiceShow';
import UnauthorisedPage from "../../components/UnauthorisedPage";

export default {
    list: MystarInvoiceList,
    show: MystarInvoiceShow,
    icon: InvoiceIcon,
    unauthorised: UnauthorisedPage
};
