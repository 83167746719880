import React, {Fragment} from 'react';
import {
    Datagrid,
    List,
    TextField,
    SearchInput,
    SingleFieldList,
    FunctionField,
    TextInput, SelectArrayInput
} from 'react-admin';
import {getLocationRoleColor, letterAvatar, LocationRoleAvatar} from "../helpers/Utilities";
import Chip from "@material-ui/core/Chip";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import ShowButton from "../components/button/ShowButton";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import KegstarReferenceManyField from "../components/field/KegstarReferenceManyField";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core";

const ListFilters = (props)  => (
    <KegstarFilter {...props}>
        <SearchInput className={'searchInputBaseClass'} source="q" alwaysOn style={{width: '27em'}} />
        <TextInput source={'id'} label={'Organisation Id'} resettable alwaysOn />

        <SelectArrayInput source={"organisationRoles"} label="Roles" style={{minWidth: '10em'}}
          choices={[
            { id: 'isWarehouse', name: 'Warehouse'},
            { id: 'isProducer', name: 'Producer' },
            { id: 'isDistributor', name: 'Distributor' },
            { id: 'isVenue', name: 'Venue' },
            { id: 'isCollector', name: 'Collector' },
            { id: 'isContractBrewer', name: 'Contract Brewer' },
        ]} optionText={optionRenderer} resettable alwaysOn/>

    </KegstarFilter>
);

const optionRenderer = choice => {
    return (
        <Fragment>
            <Avatar style={{color: 'white', backgroundColor: getLocationRoleColor(choice.id), width: '25px', height: '25px', fontSize: '16px'}}>{letterAvatar(choice.id)}</Avatar> &nbsp; { choice.name }
        </Fragment>
    )
};

const useStyles = makeStyles(theme => ({
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
}));

const OrganisationList = (props) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'O'} title={'Organisations'} subheader={'List of organisations'}/>
                <CardContent className={classes.cardContent}>
                    <List {...props} sort={{ field: 'id', order: 'DESC' }} filters={<ListFilters/>} bulkActionButtons={false}
                          filter={{countryUuid: localStorage.getItem('cUuid')} }
                          actions={<KegstarListActions />}
                          exporter={false}
                    >
                        <Datagrid rowClick={'show'} className={localStorage.getItem('userRole')}>
                            <TextField source="displayId" label={'Id'} sortable={false}/>
                            <TextField source="name" />

                            <KegstarReferenceManyField label={'Location(s)'} reference="locations" target="organisationUuid" sortable={false} >
                                <SingleFieldList linkType={'show'} style={{maxWidth: '450px'}}>
                                    <FunctionField render={record => <div style={{padding: '5px'}}> <Chip avatar={<LocationRoleAvatar record={record} />}
                                                                                 style={{cursor: 'pointer'}} label={record.locationName}/> </div> }
                                                   addLabel={false} />
                                </SingleFieldList>
                            </KegstarReferenceManyField>
                            <ShowButton variant={'text'} color={'default'} label={''} />
                        </Datagrid>
                    </List>
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default OrganisationList;
