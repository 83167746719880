import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./SummaryImageStyles";
const useStyles = makeStyles(styles);

const ClosedCyclesImage = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <div style={{textAlign: 'center', padding: '8px'}}>
                <img src={require('../../../assets/images/ClosedCycles.svg')} alt={'alt'} style={{width: '63px'}} className={classes.image}  />
            </div>
        </Fragment>
    )
}

export default ClosedCyclesImage;
