import React from "react";
import styled, { keyframes } from 'styled-components';
import { tada } from 'react-animations';

const bounceAnimation = keyframes`${tada}`;

const BouncingWrapper = styled.div`
  animation: 1s ${bounceAnimation} infinite;
`;

const KegstarLoadingScreen = () => {

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)', zIndex: 1000
        }}>
            <BouncingWrapper>
                <img src={ require('../assets/logos/KegstarLogo_Whole.svg') } alt={''} style={{width: '12em'}} />
            </BouncingWrapper>
        </div>
    )
}

export default KegstarLoadingScreen;
