import React, {Fragment} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {
    List,
    TextField,
    FunctionField,
    SearchInput,
    BooleanInput
} from "react-admin";
import LocationGridMap from "../components/LocationGridMap";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ShowRoles} from "../helpers/Utilities";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import Link from "@material-ui/core/Link";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import KegstarReferenceInput from "../components/input/KegstarReferenceInput";
import Grid from "@material-ui/core/Grid/Grid";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";

const cardStyle = {
    width: 340,
    minHeight: 500,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: 'NettoOT',
        fontSize: '20px'
    },
    cardHeader: {
        height: '90px'
    },
    cardItemContent: {
        backgroundColor: theme.palette.primary.light
    },
    organisation: {
        backgroundColor: theme.palette.primary.light,
        height: '40px'
    },
    organisationName: {
        color: theme.palette.primary.dark
    },
    actions: { float: 'right' },
    tracksLink: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
        borderRadius: 0
    },
    locationGrid: {
        backgroundColor: theme.palette.primary.light
    },
    locationLink: {
        textDecoration: 'none !important', color: theme.palette.primary.dark
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    }
}));

const OrganisationName = ({ name }) => {
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="caption" gutterBottom className={classes.organisationName}>Organisation</Typography>
            <Typography variant="body1" gutterBottom className={classes.organisationName}>{name}</Typography>
        </Fragment>
    );
}

export const LocationGrid = ({ ids, data, basePath, record, ...props }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} className={classes.locationGrid}>
            {ids.map(id =>
                <Grid item xs={12} sm={6} md={4} lg={3} key={id} >
                    <Box>
                        <Link href={`/#/locations/${data[id]['uuid']}/show`} className={classes.locationLink}>
                            <CardHeader
                                title={<TextField emptyText record={data[id]} source="locationName" className={classes.title} />}
                                subheader={<TextField emptyText record={data[id]} source="streetAddress" />}
                                className={classes.cardHeader}
                            />
                            <Box m={2}>
                                <ShowRoles record={data[id]} />
                            </Box>
                        </Link>
                    </Box>
                    <Box m={2}>
                        <LocationGridMap latitude={data[id]['latitude']} longitude={data[id]['longitude']} locationName={data[id]['locationName']} mapHeight={180} />
                    </Box>
                    <Box m={2}>
                        <KegstarReferenceField label="Organisation" resource="organisations" record={data[id]}
                                               source="organisationUuid" reference="organisations" basePath={'/organisations'} link={'show'} >
                            <FunctionField render={record => <OrganisationName name={record.name} /> } addLabel={false} />
                        </KegstarReferenceField>
                    </Box>
                    <Box m={2}>
                        <Button variant="outlined" className={classes.tracksLink} href={`${process.env.REACT_APP_TRACKS_URL}/#/organisations/${data[id]['organisationUuid']}/locations/view/${data[id]['id']}`}
                                target="_blank" rel="noreferrer">
                            View on Tracks
                        </Button>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}

const ListFilters = props => {
    const classes = useStyles();
    return (
        <KegstarFilter {...props} className={classes.root}>
            <SearchInput className={'searchInputBaseClass'} source="q" style={{width: '25em'}} resettable alwaysOn/>
            <KegstarReferenceInput source="organisationUuid" reference="organisations" label={'Organisation'}
                            sort={{ field: 'id', order: 'ASC' }} className={classes.searchField} alwaysOn>
                <KegstarAutocompleteInput optionText="name" />
            </KegstarReferenceInput>
            <BooleanInput source="isWarehouse" label={'Warehouses'}  defaultValue={1} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
            <BooleanInput source="isProducer" label={'Producers'} defaultValue={1} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
            <BooleanInput source="isDistributor" label={'Distributors'} defaultValue={1} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
            <BooleanInput source="isVenue" label={'Venues'} defaultValue={1} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
            <BooleanInput source="isCollector" label={'Collectors'} defaultValue={1} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
            <BooleanInput source="isContractBrewer" label={'Contact Brewers'} defaultValue={1} parse={v => (v) ? 1 : 0 } format={v => (v) ? 1 : 0 } />
        </KegstarFilter>
    )
}

LocationGrid.defaultProps = {
    data: {},
    ids: [],
};

const LocationList = (props) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'L'} title={'Locations'} subheader={'List of location'}/>
                <CardContent className={classes.cardContent}>
                    {/* Content Start */}
                    <List title="All Locations" {...props} filters={<ListFilters />} sort={{ field: 'createdAt', order: 'DESC' }}
                          filter={{countryUuid: localStorage.getItem('cUuid')}}
                          perPage={10} bulkActionButtons={false} component={'div'}
                          actions={<KegstarListActions />} exporter={false}
                    >
                        <LocationGrid />
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default LocationList;
