import React, {Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
    Show, SimpleShowLayout,
    ReferenceField, TextField, useQuery, Error,
    LinearProgress
} from 'react-admin';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles, useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ArrowForward from '@material-ui/icons/ArrowForward';
import {FormatChargeDescription} from "../../components/Utilities";
import {InvoicePDFButton} from "../../components/InvoicePDFButton";
import useTheme from "@material-ui/core/styles/useTheme";
import { styles } from './InvoiceShow';
import KegstarDataLoader from "../../components/loaders/KegstarDataLoader";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import BackButton from "../../components/BackButton";
import KegstarReferenceField from "../../components/field/KegstarReferenceField";
import KegstarCircularProgress from "../../components/layout/KegstarCircularProgress";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(styles);

const NetsuiteInvoiceDocNumber = ({ record }) => {
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'invoices',
        payload: {
            pagination: { page: 1 , perPage: 5 }, sort:{ field: 'createdAt', order: 'DESC' }, filter: {draftInvoiceUuid: record.uuid}
        }
    });

    if (loading) return <KegstarCircularProgress />;
    if (error) return <Error />;
    if (!data) return null;
    if (!data[0]) return null;

    return (
        <div>
            <Typography
                variant="caption" color={'textSecondary'} className={classes.labels}
            >
                Invoice No.
            </Typography>
            <Typography gutterBottom>
                { data[0].netsuiteInvDocNumber }
            </Typography>
        </div>
    )
};
const OrderNumber = ({ record }) => {
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'orders',
        payload: {id: record.orderUuid}
    });

    if (loading) return <KegstarCircularProgress />;
    if (error) return <Error />;
    if (!data) return null;
    if (!data.tracksOrderId) return null;
    return (
        <div>
            <Typography
            variant="caption" color={'textSecondary'} className={classes.labels}
            >
                Order No.
            </Typography>
            <Typography gutterBottom>
                {data.tracksOrderId}
            </Typography>
        </div>
    )
};

const OrderFromLocation = ({ record }) => {
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'orders',
        payload: {id: record.orderUuid}
    });

    if (loading) return <KegstarCircularProgress />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <div className={classes.root}>
            <Typography
                variant="caption" color={'textSecondary'} className={classes.labels}
            >
                From
            </Typography>
            <Typography>
                <KegstarReferenceField
                    reference="organisations"
                    source="fromOrganisationUuid"
                    basePath="/organisations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="name" />
                </KegstarReferenceField>
            </Typography>
            <Typography gutterBottom>
                <KegstarReferenceField
                    reference="locations"
                    source="fromLocationUuid"
                    basePath="/locations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="streetAddress" />
                </KegstarReferenceField>
            </Typography>
        </div>
    )
};

const OrderToLocation = ({ record }) => {
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'orders',
        payload: {id: record.orderUuid}
    });

    if (loading) return <KegstarCircularProgress />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <div>
            <Typography
                variant="caption" color={'textSecondary'} className={classes.labels}
            >
                To
            </Typography>
            <Typography >
                <KegstarReferenceField
                    reference="organisations"
                    source="toOrganisationUuid"
                    basePath="/organisations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="name" />
                </KegstarReferenceField>
            </Typography>
            <Typography gutterBottom>
                <KegstarReferenceField
                    reference="locations"
                    source="toLocationUuid"
                    basePath="/locations"
                    record={data}
                    linkType={false}
                >
                    <TextField emptyText source="streetAddress" />
                </KegstarReferenceField>
            </Typography>
        </div>
    )
};

const InvoiceItems = ({ record }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'invoiceitems/draft',
        payload: {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'skuUuid', order: 'ASC' },
            filter: {draftInvoiceUuid: record.uuid}
        }
    });

    if (loading) return <KegstarDataLoader />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell >
                            SKU
                        </TableCell>
                        <TableCell>
                            Item
                        </TableCell>
                        <TableCell style={{textAlign: 'right'}}>
                            Rate
                        </TableCell>
                        <TableCell style={{textAlign: 'right'}}>
                            Quantity
                        </TableCell>
                        <TableCell style={{textAlign: 'right'}}>
                            Total
                        </TableCell>
                        <TableCell style={{width: '10px'}}>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {data.map(
                        item =>
                            (
                                <TableRow key={item.id}>
                                    <TableCell className={classes.tableCell}>
                                        <KegstarReferenceField
                                            reference="skus"
                                            source="skuUuid"
                                            basePath="/skus"
                                            record={item}
                                            linkType={false}
                                        >
                                            <TextField emptyText source="name" />
                                        </KegstarReferenceField>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        { FormatChargeDescription(item.description) }
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign: 'right'}}>
                                        {item.unitAmount.toLocaleString(
                                            undefined, { minimumFractionDigits: 2, maximumFractionDigits: 4 })}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign: 'right'}}>
                                        {item.quantity}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign: 'right'}}>
                                        {item.total.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        )}

                                    </TableCell>


                                    <TableCell style={{width: '10px'}}>
                                        {/*Show links for monthly invoices only for customers */}
                                        { ( record.type === 2 ) && <div style={{display: 'inline'}}>
                                                <Link href={`/#/report/charges?filter=%7B"draftInvoiceItemUuid"%3A"${item.uuid}"%7D&order=DESC&page=1&perPage=10&sort=id`}
                                                      className={classes.viewButton} >
                                                    <ArrowForward />
                                                </Link>
                                            </div>
                                        }
                                    </TableCell>

                                </TableRow>
                            )
                    )}

                    <TableRow style={{backgroundColor: '#EDEEEF'}}>
                        <TableCell colSpan={5} style={{backgroundColor: '#EDEEEF'}}></TableCell>
                    </TableRow>

                    <TableRow style={{borderTop: '3px solid #ececec'}}>
                        <TableCell colSpan={3} />
                        <TableCell className={classes.tableCell} style={{fontWeight: 'bolder', fontSize: '1rem', textAlign: 'right'}}>
                             Total Before Discount
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{fontWeight: 'bold', textAlign: 'right'}}>
                            {record.subTotal.toLocaleString(
                                undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell style={{width: '10px', borderLeft: 'none'}}>
                            &nbsp;
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell className={classes.tableCell} style={{fontWeight: 'bolder', fontSize: '1rem', textAlign: 'right'}}>
                            Total Discount
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{textAlign: 'right'}}>
                            {record.discountAmount.toLocaleString(
                                undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell style={{width: '10px', borderLeft: 'none'}}>
                            &nbsp;
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell className={classes.tableCell} style={{fontWeight: 'bolder', fontSize: '1rem', color: '#0091D9', textAlign: 'right'}}>
                            Total (Exc. Tax)
                        </TableCell>
                        <TableCell
                            className={classes.tableCell} style={{fontWeight: 'bold',
                            fontSize: '1.25rem', color: '#0091D9', textAlign: 'right'}}
                        >
                            {record.netTotal.toLocaleString(
                                undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                        </TableCell>
                        <TableCell style={{width: '10px', borderLeft: 'none'}}>
                            &nbsp;
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </Fragment>
    )
};

const Invoice = ({classes, record, ...props}) => {

    if(record.organisationUuid !== localStorage.getItem('oUuid')) {
        return <Redirect to="/unauthorised" />;
    }

    return (
        <Fragment>
            <div>

                <Grid container spacing={16}>
                    <Grid item xs>
                        <div><hr style={{border: '1px solid #000', margin: 0, padding: 0}}/></div>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={2}>
                        <Typography
                            variant="caption" color={'textSecondary'} className={classes.labels}
                        >
                            Invoice Date
                        </Typography>
                        <Typography gutterBottom>
                            {new Date(record.createdAt).toLocaleDateString()}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        { (record.orderUuid) ? <OrderFromLocation record={record}/> : null }
                    </Grid>
                    <Grid item xs>
                        <Box m={1}>
                            <div style={{float: 'right'}}><InvoicePDFButton record={record} /></div>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={2}>
                        { (record.orderUuid) ? <OrderNumber record={record}/> : null }
                    </Grid>
                    <Grid item xs>
                        { (record.orderUuid) ? <OrderToLocation record={record}/> : null }
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={2}>
                        <NetsuiteInvoiceDocNumber record={record} />
                    </Grid>
                </Grid>

                <Box m={5}> </Box>

                <div>
                    <InvoiceItems record={record} />
                </div>
            </div>
        </Fragment>
    )
};

const InvoiceShow = ({record, ...props}) => {

    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return(
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'I'} title={'Invoice Details'} subheader={'Details'}/>
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <Show {...props} className={'invoice-record'}>
                        <SimpleShowLayout className={classes.gridContainer}>
                            <Invoice classes={classes} record={record}/>
                        </SimpleShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default InvoiceShow;
