import React, {Fragment} from "react";
import styled, { keyframes } from 'styled-components';
import {pulse} from 'react-animations';
import {Typography} from "@material-ui/core";

const loadingAnimation = keyframes`${pulse}`;

const LoadingWrapper = styled.div`
  animation: 1s ${loadingAnimation} infinite;
`;

const KegstarDataLoader = () => {

    return (
        <div style={{width: '100%', height: '100%'}}>
            <div style={{
                position: 'absolute', left: '50%', top: '50%', zIndex: 1,
                transform: 'translate(-50%, -50%)'
            }}>
                <LoadingWrapper>
                    <img src={ require('../../assets/logos/KegstarLogo_Star.svg') } alt={''} style={{width: '80px'}} />
                </LoadingWrapper>
                <Typography>Please wait ...</Typography>
            </div>
        </div>
    )
}

export default KegstarDataLoader;
