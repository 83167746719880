import React, {Fragment} from 'react';
import {
    FunctionField,
} from 'react-admin';
import { Show, TabbedShowLayout, Tab, ReferenceField} from 'react-admin';
import {Grid, makeStyles} from "@material-ui/core";
import {KegnovaTextField} from "../components/KegnovaTextField";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import {useSelector} from "react-redux";
import KegstarDataLoader from "../components/loaders/KegstarDataLoader";
import KegstarReferenceField from "../components/field/KegstarReferenceField";

export const styles = (theme => ({
    subTitle: {
        fontFamily: 'NettoOT',
        paddingBottom: '1em'
    },
}));

const useStyles = makeStyles(styles);

const ChargeDetails = ({record}) => {

    return (
        <Fragment>
            <Grid container spacing={4} >
                <Grid item xs>
                    <KegnovaTextField value={record.kegSerialNumber} label={'Keg'} />
                    <KegnovaTextField value={record.description} label={'Description'} />
                    <KegnovaTextField value={record.feeType} label={'Fee Type'} />
                    <KegnovaTextField value={record.unitAmount} label={'Unit Amount'} />
                    <KegnovaTextField value={record.discountAmount} label={'Discount Amount'} />
                    <KegnovaTextField value={record.quantity} label={'Quantity'} />
                    <KegnovaTextField value={record.netTotal} label={'Total'} />
                    <KegnovaTextField value={record.applicableDate} label={'Applicable Date'} />
                    <KegnovaTextField value={record.information} label={'Information'} />
                    <KegnovaTextField value={record.chargeGenerationOrigin} label={'Charge Generation Origin'} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

const ChargeShow = ({ ...props }) => {


    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'C'} title={'Charge'} subheader={'Details'}/>
                <CardContent>

                    {/* Content Start */}
                    <Show {...props}

                    >
                        <TabbedShowLayout>
                            <Tab label="Charge" >
                                <KegstarReferenceField source="orderUuid" reference="orders" linkType="show" allowEmpty addLabel={false} >
                                    <FunctionField render={data => <KegnovaTextField value={data.displayId} label={'Order'} linkType={'show'} /> } addLabel={false} />
                                </KegstarReferenceField>
                                <KegstarReferenceField source="rentalUuid" reference="rentals" linkType="show" allowEmpty addLabel={false} >
                                    <FunctionField render={data => <KegnovaTextField value={data.displayId} label={'Rental'} linkType={'show'} /> } addLabel={false} />
                                </KegstarReferenceField>
                                <FunctionField render={record => <ChargeDetails record={record} /> } addLabel={false} />
                                <KegstarReferenceField source="relatedChargeUuid" reference="charges" linkType="show" allowEmpty addLabel={false} >
                                    <FunctionField render={data => <KegnovaTextField value={data.displayId} label={'Related Charge'} linkType={'show'} /> } addLabel={false} />
                                </KegstarReferenceField>
                                <KegstarReferenceField source="skuUuid" reference="skus" linkType="show" allowEmpty addLabel={false} >
                                    <FunctionField render={data => <KegnovaTextField value={data.name} label={'Sku'} linkType={'show'} /> } addLabel={false} />
                                </KegstarReferenceField>
                            </Tab>
                        </TabbedShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default ChargeShow;
