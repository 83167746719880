import React, { Fragment } from 'react';
import WeeklySummary from "../summaries/WeeklySummary";

export default function MystarDashboard() {

    return (
        <Fragment>
           <WeeklySummary organisation={localStorage.getItem('oUuid')} />
        </Fragment>
    );
}
