import React, {Fragment} from 'react';
import {FunctionField, translate} from 'react-admin';
import { Show, SimpleShowLayout, ReferenceField} from 'react-admin';
import {KegnovaTextField} from "../../components/KegnovaTextField";
import KegnovaDatePickerField from "../../components/KegnovaDatePickerField";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core";
import {useSelector} from "react-redux";
import KegstarDataLoader from "../../components/loaders/KegstarDataLoader";
import KegstarReferenceField from "../../components/field/KegstarReferenceField";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
    }
}));

const RentalShow = ({ translate, ...props }) => {

    const classes = useStyles();
    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'R'} title={'Rental'} subheader={'Details'}/>
                <CardContent>

                    {/* Content Start */}
                    <Show {...props}
                    >
                        <SimpleShowLayout className={classes.root}>
                            <KegstarReferenceField source="kegUuid" reference="kegs" linkType="show" addLabel={false}>
                                <FunctionField render={data => <KegnovaTextField value={data.serialNumber} label={'Keg'} linkType={'show'} record={data} reference="kegs" /> } addLabel={false} />
                            </KegstarReferenceField>
                            <KegstarReferenceField source="organisationUuid" reference="organisations" linkType="show" addLabel={false}>
                                <FunctionField render={data => <KegnovaTextField value={data.name} label={'Organisation'} style={{width: '350px'}} linkType={'show'} record={data} reference="organisations" /> } addLabel={false} />
                            </KegstarReferenceField>
                            <FunctionField render={record => <KegnovaDatePickerField name={'startDate'} value={record.startDate} label={'Start Date'} readOnly={true} /> } addLabel={false} />
                            <FunctionField render={record => <KegnovaDatePickerField name={'endDate'} value={record.endDate} label={'Start Date'} readOnly={true} /> } addLabel={false} />
                        </SimpleShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default translate(RentalShow);
