import React, { Fragment } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Link} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    appLogo: {
        width: '22px',
        paddingRight: '5px'
    },
    logo: {
        padding: '5px',
        paddingTop: '10px'
    },
    kegstarLogo: {
        width: '110px',
        // @ts-ignore
        filter: theme.overrides.kegstarLogo.filter
    }
}));

export default function AppLogo() {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Fragment>
            <Link href={'/'} >
                <div className={classes.logo}>
                <img src={ require('../assets/logos/Kegstar-logo.svg') } className={classes.kegstarLogo} alt={''} />
                </div>
            </Link>
        </Fragment>
    );
}
