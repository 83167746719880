import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import ContentFilter from '@material-ui/icons/FilterList';
import classnames from 'classnames';
import lodashGet from 'lodash/get';

import KegstarFilterButtonMenuItem from './KegstarFilterButtonMenuItem';
import Button from '../button/Button';

const useStyles = makeStyles(
    theme => ({
        root: { display: 'inline-block' },
        filterButton: {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '0px',
            padding: '10px 5px 10px 5px',
            color: theme.palette.secondary.dark,
            marginLeft: '5px',
            marginRight: '5px',
            borderBottom: '1px solid',
            // @ts-ignore
            borderBottomColor: theme.overrides.listActionButton.borderBottomColor
        }
    }),
    { name: 'RaFilterButton' }
);

const KegstarFilterButton = ({
    filters,
    displayedFilters,
    filterValues,
    showFilter,
    classes: classesOverride,
    className,
    resource,
    ...rest
}) => {
    const [open, setOpen] = useState(false);
    const anchorEl = useRef();
    const classes = useStyles({ classes: classesOverride });

    const hiddenFilters = filters.filter(
        filterElement =>
            !filterElement.props.alwaysOn &&
            typeof lodashGet(filterValues, filterElement.props.source) === 'undefined' &&
            !(typeof lodashGet(displayedFilters, filterElement.props.source) !== 'undefined')
    );

    const handleClickButton = useCallback(
        event => {
            // This prevents ghost click.
            event.preventDefault();
            setOpen(true);
            anchorEl.current = event.currentTarget;
        },
        [anchorEl, setOpen]
    );

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleShow = useCallback(
        ({ source, defaultValue }) => {
            showFilter(source, defaultValue);
            setOpen(false);
        },
        [showFilter, setOpen]
    );

    if (hiddenFilters.length === 0) return null;
    return (
        <div className={classnames(classes.root, className)} {...rest}>
            <Button
                className={classes.filterButton}
                label=""
                onClick={handleClickButton}
            >
                <ContentFilter />
            </Button>
            <Menu
                open={open}
                anchorEl={anchorEl.current}
                onClose={handleRequestClose}
            >
                {hiddenFilters.map(filterElement => (
                    <KegstarFilterButtonMenuItem
                        key={filterElement.props.source}
                        filter={filterElement.props}
                        resource={resource}
                        onShow={handleShow}
                    />
                ))}
            </Menu>
        </div>
    );
};

KegstarFilterButton.propTypes = {
    resource: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.node).isRequired,
    displayedFilters: PropTypes.object.isRequired,
    filterValues: PropTypes.object.isRequired,
    showFilter: PropTypes.func.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
};

export default KegstarFilterButton;
