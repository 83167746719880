import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useTranslate, useLocale, useSetLocale, Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {CHANGE_THEME, changeTheme} from './actions';
import {AppState, ThemeName} from '../types';
import ThemeIcon from '@material-ui/icons/Palette';

const useStyles = makeStyles({
    themeIcon: { margin: 0, padding: 0},
    button: { margin: 0, borderRadius: 0 },
});

export const toggleTheme = () => {
    let theme: ThemeName;
    theme = (localStorage.getItem('theme')) ? localStorage.getItem('theme') as ThemeName : 'light' as ThemeName;

    if(theme === 'light') {
        theme = 'dark';
    } else {
        theme = 'light';
    }

    localStorage.setItem('theme', theme);

    return changeTheme(theme);
}

const ThemeChanger = () => {
    // const translate = useTranslate();
    // const locale = useLocale();
    // const setLocale = useSetLocale();
    const classes = useStyles();
    const theme = useSelector((state: AppState) => state.theme);
    const dispatch = useDispatch();
    return (
        <Fragment>
            <Button
                className={classes.button}
                color={theme === 'dark' ? 'primary' : 'default'}
                onClick={() => dispatch(toggleTheme())}
            >
                <ThemeIcon className={classes.themeIcon} />
            </Button>
        </Fragment>
    );
};

export default ThemeChanger;
