import React, { FunctionComponent } from 'react';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import sanitizeRestProps from './sanitizeRestProps';
import { FieldProps, InjectedFieldProps, fieldPropTypes } from './types';
import {InvoiceTypeName} from "../Utilities";

const InvoiceTypeTextField: FunctionComponent<
    FieldProps & InjectedFieldProps & TypographyProps
> = ({ className, source, record = {}, emptyText,  ...rest }) => {

    let v = get(record, (source) ? source : '');

    const value = InvoiceTypeName(v);

    if (value == null && emptyText) {
        return (
            <Typography
                // @ts-ignore
                component="span"
                variant="body2"
                className={className}
                {...sanitizeRestProps(rest)}
            >
                {emptyText}
            </Typography>
        );
    }

    return (
        <Typography
            // @ts-ignore
            component="span"
            variant="body2"
            className={className}
            {...sanitizeRestProps(rest)}
        >
            {typeof value !== 'string' ? JSON.stringify(value) : value}
        </Typography>
    );
};

// wat? TypeScript looses the displayName if we don't set it explicitly
InvoiceTypeTextField.displayName = 'TextField';

const EnhancedTextField = pure(InvoiceTypeTextField);

EnhancedTextField.defaultProps = {
    addLabel: true,
};

EnhancedTextField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes,
    ...fieldPropTypes,
};

EnhancedTextField.displayName = 'EnhancedTextField';

export default EnhancedTextField;
