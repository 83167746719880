import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Card, CardContent} from "@material-ui/core";
import {
    translate,
    NumberInput,
    SelectInput,
    ReferenceInput
} from 'react-admin';
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import FeeTypeCheckboxes from "./FeeTypeCheckboxes";

const styles = theme => ({
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        color: theme.palette.text.secondary,
    },
    heading: {
        marginTop: '1%',
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
    },
    closeButton: {
        margin: 0,
        maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px',
        padding: 0,
        verticalAlign: 'top'
    },
    inputField: {
        marginRight: '2em'
    },
    timedFeeAmount: {
        display: 'none'
    }
});

const SelectStopRole = (element, classes) => {
    return <SelectInput source={element.source.name} choices={[
        { id: '', name: 'Select a Role' },
        { id: 'ROLE_WAREHOUSE', name: 'Warehouse' },
        { id: 'ROLE_PRODUCER', name: 'Producer' },
        { id: 'ROLE_DISTRIBUTOR', name: 'Distributor' },
        { id: 'ROLE_VENUE', name: 'Venue' }

    ]} label={element.source.label} className={classes.inputField} />
}

class FeeTypes extends React.Component {
    constructor(props) {
        super(props)

        // Bind the this context to the handler function
        this.showFeeType = this.showFeeType.bind(this);

        // Set some state
        this.state = {
            issueFee: {
                show: false,
                amount: undefined
            },
            dailyRentalFee: {
                show: false,
                amount: undefined
            },
            cleaningFee: {
                show: false,
                amount: undefined
            },
            timedFee: {
                show: false,
                amount: undefined
            },
            interCountryFee: {
                show: false,
                amount: undefined
            },
            taproomVenueFee: {
                show: false,
                amount: undefined
            },
            taproomDistributorFee: {
                show: false,
                amount: undefined
            },
            freightCapitalMetroFee: {
                show: false,
                amount: undefined
            },
            freightRegionalMetroFee: {
                show: false,
                amount: undefined
            },
            freightRegionalFee: {
                show: false,
                amount: undefined
            }
        };
    }

    showFeeType = () => name => {
        this.setState(state => ({ [name]: {show: !state[name].show} }));
    };

    removeFee = name => () => {
        this.setState(state => ({ [name]: {show: true, amount: ''} }));
    };

    render() {
        const { classes, translate } = this.props;
        const { issueFee, dailyRentalFee, cleaningFee, timedFee, interCountryFee, taproomVenueFee, taproomDistributorFee,
            freightCapitalMetroFee, freightRegionalMetroFee, freightRegionalFee} = this.state;

        return (
            <div className={classes.root}>
                <Grid container>
                    <FeeTypeCheckboxes action={this.showFeeType()}/>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={issueFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.issueFee')}
                                        </Typography>
                                        <NumberInput source="fees[IssueFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField} defaultValue={issueFee.amount} allowEmpty emptyValue={''} />
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={dailyRentalFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.dailyRentalFee')}
                                        </Typography>
                                        <NumberInput source="fees[DailyRentalFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField}/>
                                        <SelectStopRole source={{name: "fees[DailyRentalFee][stopRole]", label: translate('resources.packages.create.fees.stopRole') }} />
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={cleaningFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.cleaningFee')}
                                        </Typography>
                                        <NumberInput source="fees[CleaningFee][amount]" label={translate('resources.packages.create.fees.amount')}  className={classes.inputField} />
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={timedFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.timedFee')}
                                        </Typography>
                                        <NumberInput source="fees[TimedFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.timedFeeAmount} />
                                        <ReferenceInput source="timedFeesGroupUuid" reference="timedfeesgroups" resource="timedFeesGroupUuid"
                                                        label={translate('resources.packages.create.fees.timedFeeGroupUuid')} className={classes.inputField}>
                                            <SelectInput optionText={choice =>
                                                `${choice.description}`
                                            }
                                            />
                                        </ReferenceInput>
                                        <SelectStopRole source={{name: "fees[TimedFee][stopRole]", label: translate('resources.packages.create.fees.stopRole') }} />
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={interCountryFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.interCountryFee')}
                                        </Typography>
                                        <NumberInput source="fees[InterCountryFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField} />
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={taproomVenueFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.taproomVenueFee')}
                                        </Typography>
                                        <NumberInput source="fees[TaproomVenueFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField} />
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={taproomDistributorFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.taproomDistributorFee')}
                                        </Typography>
                                        <NumberInput source="fees[TaproomDistributorFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField}/>
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={freightCapitalMetroFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.freightCapitalMetroFee')}
                                        </Typography>
                                        <NumberInput source="fees[FreightCapitalMetroFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField}/>
                                        <NumberInput source="fees[FreightCapitalMetroFee][minimumFee]" label={translate('resources.packages.create.fees.minimumFee')} className={classes.inputField}/>
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={freightRegionalMetroFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.freightRegionalMetroFee')}
                                        </Typography>
                                        <NumberInput source="fees[FreightRegionalMetroFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField}/>
                                        <NumberInput source="fees[FreightRegionalMetroFee][minimumFee]" label={translate('resources.packages.create.fees.minimumFee')} className={classes.inputField}/>
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Collapse in={freightRegionalFee.show}>
                                <Card>
                                    <CardContent>
                                        <Divider light={true}/>
                                        <Typography className={classes.heading}>
                                            {translate('resources.packages.fields.freightRegionalFee')}  </Typography>
                                        <NumberInput source="fees[FreightRegionalFee][amount]" label={translate('resources.packages.create.fees.amount')} className={classes.inputField}/>
                                        <NumberInput source="fees[FreightRegionalFee][minimumFee]" label={translate('resources.packages.create.fees.minimumFee')} className={classes.inputField}/>
                                        <NumberInput source="fees[FreightRegionalFee][distanceGroups][0][minimumDistance]" label={translate('resources.packages.create.fees.distanceGroups.minimumDistance')} className={classes.inputField}/>
                                        <NumberInput source="fees[FreightRegionalFee][distanceGroups][0][maximumDistance]" label={translate('resources.packages.create.fees.distanceGroups.maximumDistance')} className={classes.inputField}/>
                                        <NumberInput source="fees[FreightRegionalFee][distanceGroups][0][fixedAmount]" label={translate('resources.packages.create.fees.distanceGroups.fixedAmount')} className={classes.inputField}/>
                                        <NumberInput source="fees[FreightRegionalFee][distanceGroups][0][amountPer100Km]" label={translate('resources.packages.create.fees.distanceGroups.amountPer100Km')} className={classes.inputField}/>
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

FeeTypes.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default translate(withStyles(styles)(FeeTypes));
