import { AuthProvider } from 'ra-core';
import {SimpleCrypto} from "simple-crypto-js";
import {UserRole} from "./components/Utilities";

export function authenticate() {
    // console.log('authenticate');
    if(localStorage.getItem('apiKey') && localStorage.getItem('token') && localStorage.getItem('username') && localStorage.getItem('userRole')) {
        let apiKey = (localStorage.getItem('apiKey')) ? localStorage.getItem('apiKey') : '';
        let username = (localStorage.getItem('username')) ? localStorage.getItem('username') : '';
        let role = (localStorage.getItem('role')) ? localStorage.getItem('role') : 'ROLE_CUSTOMER';
        let userRole = (localStorage.getItem('userRole')) ? localStorage.getItem('userRole') : 'Customer';

        // @ts-ignore
        let cryptography = new SimpleCrypto(apiKey);

        // @ts-ignore
        let cipher = username + role + userRole;
        let token = (localStorage.getItem('token')) ? localStorage.getItem('token') : ''

        try {
            let decipher = cryptography.decrypt(token)
            if(cipher !== decipher) {
                return Promise.reject({ redirectTo: '/login' });
            }
        } catch (e) {
            return Promise.reject({ redirectTo: '/login' });
        }

    }
    return Promise.resolve();
}



const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        localStorage.setItem('username', username);

        const request = new Request(process.env.REACT_APP_AUTH_URL + '/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache'
            }),
            credentials: "include"
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();

            }).then(({ apiKey, roles, uuid }) => {
                return Promise.resolve(getUserProfile(apiKey, roles, uuid));

            });
    },
    logout: () => {
        localStorage.removeItem('apiKey');
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        localStorage.removeItem('userRole');
        localStorage.removeItem('uUuid');
        localStorage.removeItem('cUuid');
        localStorage.removeItem('oUuid');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('timezone');
        localStorage.removeItem('login');
        localStorage.removeItem('redirect');

        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        // console.log("checkAuth");
        let href = window.location.href;
        let redirect = '';
        let apiKey = '';

        if(href.indexOf("apiKey") !== -1) {
            let urlToken = href.split("?");
            redirect = urlToken[0];
            let apiKeyArray = urlToken[1].split("=");
            apiKey = apiKeyArray[1];
        }

        if ( apiKey ) {
            localStorage.setItem('apiKey', apiKey);
            // localStorage.setItem('redirect', redirect);
            localStorage.setItem('login', 'apikey');
            return localStorage.getItem('apiKey') ? Promise.resolve() : Promise.reject();
        } else {
            return localStorage.getItem('apiKey') ? Promise.resolve(authenticate()) : Promise.reject();
        }

    },
    getPermissions: () => {
        // console.log("getPermissions");
        if ( localStorage.getItem('login') === 'apikey' ) {

            let apiKey = localStorage.getItem('apiKey');
            // localStorage.setItem('username', 'redirect');

            const request = new Request(process.env.REACT_APP_AUTH_URL + '/user-profile', {
                method: 'GET',
                headers: new Headers({ 'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache',
                    'x-api-key': (apiKey) ? apiKey : ''
                }),
                credentials: "include"
            });

            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }

                    return response.json();

                }).then(({ roles, uuid }) => {
                    let apiKey = localStorage.getItem('apiKey');
                    // @ts-ignore
                    return Promise.resolve(getUserProfile(apiKey, roles, uuid));

                });

        } else {
            const role = localStorage.getItem('role');
            // @ts-ignore
            return role ? Promise.resolve(role) : Promise.reject();
        }

    }
};

export function getUserProfile(apiKey: string, roles: string, uuid: string){

    localStorage.setItem('apiKey', apiKey);
    localStorage.setItem('role', (roles.length > 0) ? roles : 'ROLE_CUSTOMER');
    localStorage.setItem('uUuid', uuid);
    let userRole = (UserRole(roles)) ? UserRole(roles) : 'Customer';

    try {
        let cryptography = new SimpleCrypto(apiKey);
        let cipher = cryptography.encrypt(localStorage.getItem('username')+roles.toString()+userRole);
        localStorage.setItem('token', cipher);
    } catch (e) {
        return Promise.reject({ redirectTo: '/' });
    }

    const request = new Request(process.env.REACT_APP_API_URL + '/users/' + uuid, {
        method: 'GET',
        headers: new Headers({ 'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache': 'no-cache',
            'x-api-key': apiKey,
        }),
        credentials: "include"
    });

    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }

            return response.json();

        }).then(({ firstName, lastName, organisationUuid, countryUuid, timezone }) => {
            if(timezone) {
                if(isValidTimeZone(timezone)) {
                    localStorage.setItem('timezone', timezone);
                } else {
                    localStorage.setItem('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
                }
            } else {
                localStorage.setItem('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
            }

            localStorage.setItem('firstName', firstName);
            localStorage.setItem('lastName', lastName);
            if(localStorage.getItem('cUuid')){
               // keep existing country
            } else {
                localStorage.setItem('cUuid', countryUuid);
            }
            localStorage.setItem('oUuid', organisationUuid);
            return roles ? Promise.resolve(roles) : Promise.reject();
        });
}

function isValidTimeZone(tz: string | undefined) {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
        throw 'Time zones are not available in this environment';
    }

    try {
        Intl.DateTimeFormat(undefined, {timeZone: tz});
        return true;
    }
    catch (ex) {
        return false;
    }
}
export default authProvider;
