import Grid from "@material-ui/core/Grid";
import React, {useCallback} from "react";
import {useHistory} from "react-router";
import Button from "@material-ui/core/Button";
import BackIcon from "../components/icons/HistoryLeftArrowIcon";
import ForwardIcon from "../components/icons/HistoryRightArrowIcon";

const HistoryButtons = props => {
    const history = useHistory();

    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const handleForward = useCallback(() => {
        history.goForward();
    }, [history]);

    return (
        <Grid container>
            <Grid item xs>
                <Button onClick={handleBack}>
                    <BackIcon />
                </Button>
            </Grid>
            <Grid item xs>
                <Button onClick={handleForward}>
                    <ForwardIcon />
                </Button>
            </Grid>
        </Grid>
    );
}

export default HistoryButtons;
