import ChargeList from './ChargeList';
import ChargeShow from './ChargeShow';
import ChargeIcon from '@material-ui/icons/Payment';


export default {
    list: ChargeList,
    show: ChargeShow,
    icon: ChargeIcon,
};
