import React, {Fragment} from "react";
import {Grid, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {KegnovaTextField} from "./KegnovaTextField";
import {
    PackageDetailsHeaders,
    PackageFeeValues,
    PackagePaymentDays,
    PackagePaymentDaysHeaders
} from "../packages/details/PackageSummaryList";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#EDEEEF',
        padding: '2em'
    },
    divider: {border: '0.5px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
    tableContainer: {}
}));

export const PackageDetailsPanel = ({record}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <div className={classes.container}>
                <Grid container spacing={4}>
                    <Grid item xs>
                        <Box><Typography color={'primary'} className={classes.subTitle}>Fees</Typography></Box>
                        <div><Divider className={classes.divider}/></div>
                        <div className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow key={'packages-row1'}>
                                        <PackageDetailsHeaders packages={record}/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={'packages-row2'}>
                                        <PackageFeeValues packages={record}/>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs>
                        <Box><Typography color={'primary'} className={classes.subTitle}>Payment Days</Typography></Box>
                        <div><Divider className={classes.divider}/></div>
                        <div className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow key={'packageDetails-row1'}>
                                        <PackagePaymentDaysHeaders packages={record}/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={'packageDetails-row2'}>
                                        <PackagePaymentDays packages={record} />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
}
