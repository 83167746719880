import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const RentalsExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            id,	uuid, createdAt, status, modifiedAt, packageSetGroupDisplayId,	createdAtText,	modifiedAtText, startDateText,	endDateText,	kegUuid,	packageSetGroupUuid,	organisationUuid,
            startDate, endDate, startKegRole, orderUuid,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'displayId', 'organisationName', 'kegSerialNumber', 'skuName', 'rentalExpectedStartDateText', 'requestedForTime', 'endDateText', 'isChargeable'
        ], // order fields in the export
        rename:[
            'Rental Id', 'Organisation', 'Keg Serial Number', 'SKU', 'Cycle Start Date', 'Requested For', 'End Date', 'Is Chargeable'
        ]
    }, (err, csv) => {
        downloadCSV(csv, 'Rentals'); // download as 'posts.csv` file
    });

};

export default RentalsExport;
