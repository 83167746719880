import React, { useState, useEffect } from 'react';
// import customRoutes from './routes';
import { Admin, Resource, EditGuesser } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { createHashHistory } from 'history';
import ReactGA from 'react-ga';
import './App.css';
import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Layout } from './layout';
import { KegstarLogin } from './auth';
import { Dashboard } from './dashboard';
import KegstarRoutes from './auth/KegstarRoute';
import englishMessages from './i18n/en';
import countries from './countries/index.js';
import organisations from './organisations/index.js';
import draftinvoices from './invoices/draft/index';
import draftinvoiceitems from './invoiceitems/draft/index.js';
import mystardraftinvoices from './invoices/draft/mystarindex.js';
import mystardraftinvoiceitems from './invoiceitems/draft/mystarindex.js';
import invoices from './invoices/index';
import mystarinvoices from './invoices/mystarindex';
import orders from './orders/index';
import locations from './locations/index.js';
import skus from './skus/index';
import timedfeesgroups from './timedfeesgroups/index';
import charges from './charges/index.js';
import mystarcharges from './charges/mystarindex.js';
import rentals from './rentals/index.js';
import kegs from './kegs/index.js';
import packages from './packages/index.js';
import countrieswithpackage from './packages/countries/index.js';
import packagesetgroups from './packagesetgroups/index';
import packagedetails from './packages/details/index.js';
import reportrentals from './report/rentals/index.js';
import reportcharges from './report/charges/index.js';
import users from './users/index.js';
import dataProviderFactory from './dataProvider';
import {isCustomer} from "./security/Role";
import KegstarLoadingScreen from "./components/KegstarLoadingScreen";
import UnauthorisedPage from "./components/UnauthorisedPage";
import search from './search/index.js';
import Loading from "./components/layout/Loading";

const i18nProvider = polyglotI18nProvider(locale => {
    //@Todo Localisation setup
    // if (locale === '{country-code}') {
    //     return import('./i18n/{country-code}').then(messages => messages.default);
    // }
    // Default to english
    return englishMessages;
}, 'en');

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);
    const history = createHashHistory();

    // @ts-ignore
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    // @ts-ignore
    ReactGA.pageview(window.location.pathname);

    history.listen(location => {
        ReactGA.pageview(location.pathname);
        // @ts-ignore
        ReactGA.event({
            category: 'User',
            // @ts-ignore
            label: (localStorage.getItem('userRole')) ? localStorage.getItem('userRole') : '',
            // @ts-ignore
            action: localStorage.getItem('uUuid') ? localStorage.getItem('uUuid') : ''
        });

        // @ts-ignore
        ReactGA.set({ user: (localStorage.getItem('uUuid')) ? localStorage.getItem('uUuid') : null,
                                    role: (localStorage.getItem('userRole')) ? localStorage.getItem('userRole') : null
                    }, ['User']);
    });

    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
            restoreFetch = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER
            );


            setDataProvider(
                // @ts-ignore
                await dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER)
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <KegstarLoadingScreen />
        );
    }

    return (
        <Admin
            title="Kegnova"
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={KegstarRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={KegstarLogin}
            loading={Loading}
            layout={Layout}
            i18nProvider={i18nProvider}
            history={history}
        >
            {(permissions: string) => [
                // Restrict access to the edit and remove views to admin only
                <Resource name="countries" {...countries} list={ isCustomer(permissions) ? countries.unauthorised : countries.list } />,
                <Resource name="organisations" {...organisations}
                          list={ isCustomer(permissions) ? organisations.unauthorised : organisations.list }
                          show={ isCustomer(permissions) ? organisations.unauthorised : organisations.show }/>,

                isCustomer(permissions)
                    ? <Resource name="invoices/draft" {...mystardraftinvoices} list={mystardraftinvoices.unauthorised} />
                    : <Resource name="invoices/draft" {...draftinvoices} />,

                isCustomer(permissions)
                    ? <Resource name="invoiceitems/draft" {...mystardraftinvoiceitems} list={mystardraftinvoiceitems.unauthorised} />
                    : <Resource name="invoiceitems/draft" {...draftinvoiceitems} />,

                isCustomer(permissions)
                    ? <Resource name="invoices" {...mystarinvoices} />
                    : <Resource name="invoices" {...invoices} />,

                <Resource name="orders" {...orders}
                          list={ isCustomer(permissions) ? orders.unauthorised : orders.list } />,
                <Resource name="locations" {...locations}
                          list={ isCustomer(permissions) ? locations.unauthorised : locations.list } />,

                <Resource name="skus" {...skus} />,
                <Resource name="timedfeesgroups" {...timedfeesgroups} />,

                isCustomer(permissions)
                    ? <Resource name="charges" {...mystarcharges} />
                    : <Resource name="charges" {...charges} />,

                <Resource name="packages/details" {...packagedetails} />,
                <Resource name="packages/countries" {...countrieswithpackage}
                          list={ isCustomer(permissions) ? countrieswithpackage.unauthorised : countrieswithpackage.list } />,
                <Resource name="packages" {...packages}
                          list={ isCustomer(permissions) ? packages.unauthorised : packages.list }
                          edit={ isCustomer(permissions) ? packages.unauthorised : packages.edit }  />,
                <Resource name="packagesetgroups" {...packagesetgroups}
                          list={ isCustomer(permissions) ? packagesetgroups.unauthorised : packagesetgroups.list } />,

                <Resource name="rentals" {...rentals}
                          list={ isCustomer(permissions) ? rentals.unauthorised : rentals.list } />,
                <Resource name="report/rentals" {...reportrentals} />,
                <Resource name="report/charges" {...reportcharges} />,
                <Resource name="kegs" {...kegs}
                          list={ isCustomer(permissions) ? kegs.unauthorised  : kegs.list } />,
                <Resource name="users" {...users} />,
                <Resource name="search" {...search} list={ isCustomer(permissions) ? packages.unauthorised : search.list } />,
            ]}

        </Admin>
    );
};

export default App;
