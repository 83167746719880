import React, {Fragment} from "react";
import Flag from "react-world-flags";
import {Error, useQueryWithStore} from "react-admin";
import {Box} from "@material-ui/core";
import CountryArray from "./CountryArray";
import KegstarCircularProgress from "./layout/KegstarCircularProgress";

export const CountryFlag = ({country, height}) => {
    return (
        <Fragment>
            <CountryName country={country} height={height} />
        </Fragment>
    );
}

export function CountryName({country, height}) {

    const { loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'countries',
        payload: { id: (country) ? country : localStorage.getItem('cUuid') }
    });

    if (!loaded) { return <Box m={3} alignItems="center" justifyContent="center"><KegstarCircularProgress /></Box> }
    // if (error) return <Error />;
    if (!data) return null;

    return (
        <Box>
            <Box><Flag code={ (CountryArray[data.name.toUpperCase()]) ? CountryArray[data.name.toUpperCase()] : '' } height={ (height) ? height : '100%'} /></Box>
        </Box>
    );
}
