export const styles = (theme => ({
    root: {
        flexGrow: 1,
    },
    legend: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        fontSize: 'larger',
        fontWeight: 'bold'
    },
    countryLegend: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        marginRight: '4px'
    },
    organisationLegend: {
        backgroundColor: '#00A3E0',
        color: theme.palette.primary.light,
        marginRight: '4px'
    },
    locationLegend: {
        backgroundColor: '#6CC24A',
        color: theme.palette.primary.light,
        marginRight: '4px'
    },
    tableRow: {
        backgroundColor: theme.palette.secondary.light
    },
    tableCellLabel: {
        backgroundColor: theme.palette.secondary.light
    },
    highlightCellOrganisation: {
        backgroundColor: '#00A3E0',
        color: theme.palette.primary.light
    },
    highlightCellLocation: {
        backgroundColor: '#6CC24A',
        color: theme.palette.primary.light
    },
    currentPsaLegend: {
        backgroundColor: '#FEDB00',
        color: theme.palette.primary.dark,
    },
    highlightCurrentPsaDiscountRow: {
        backgroundColor: '#FEDB00',
    },
    divider: {border: '0.5px solid', borderColor: theme.palette.primary.dark,  backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
    tableDetailsHeaderIndicator: {
        padding: 0, fontFamily: 'NettoOT Bold'
    },
    tableDetailsHeaderIndicatorRentals: {
        padding: 0, fontFamily: 'NettoOT Bold',
        backgroundColor: 'rgba(108,194,74,0.25)'

    },
    tableDetailsHeaderRentals: {
        backgroundColor: 'rgba(108,194,74,0.25)'
    },
    tableDetailsHeaderIndicatorFreight: {
        padding: 0, fontFamily: 'NettoOT Bold',
        backgroundColor: 'rgba(0,163,224,0.25)'

    },
    tableDetailsHeaderFreight: {
        backgroundColor: 'rgba(0,163,224,0.25)'
    },
    tableDetailsHeaderIndicatorCollection: {
        padding: 0, fontFamily: 'NettoOT Bold',
        backgroundColor: 'rgba(254,219,0,0.25)'

    },
    tableDetailsHeaderCollection: {
        backgroundColor: 'rgba(254,219,0,0.25)'
    },
    tableDetailsHeaderIndicatorTimed: {
        padding: 0, fontFamily: 'NettoOT Bold',
        backgroundColor: 'rgba(236,161,84,0.25)'

    },
    tableDetailsHeaderTimed: {
        backgroundColor: 'rgba(236,161,84,0.25)'
    },

    tableDetailsHeaderIndicatorPSA: {
        padding: 0, fontFamily: 'NettoOT Bold',
        backgroundColor: 'rgba(201,100,207,0.25)'

    },
    tableDetailsHeaderPSA: {
        backgroundColor: 'rgba(201,100,207,0.25)'
    },
    showPackageButton: {
        borderRadius: '0px'
    }


}));

