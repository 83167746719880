import React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

interface Props {
    children: React.ReactElement;
}

function KegstarHideOnScroll(props: Props) {
    const { children } = props;
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

KegstarHideOnScroll.propTypes = {
    children: PropTypes.node.isRequired,
};

export default KegstarHideOnScroll;
