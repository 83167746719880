import React, {Fragment} from 'react';
import {
    ReferenceField,
    Show,
    TabbedShowLayout,
    Tab,
    FunctionField, ReferenceManyField, Pagination, Datagrid, Link, TextField, DateField
} from 'react-admin';
import LocationMap from './LocationMap';
import {makeStyles} from "@material-ui/core";
import {KegnovaTextField} from "../components/KegnovaTextField";
import {LocationDetails} from "../components/LocationDetails";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import PackageExplorer from "../packages/details/PackageExplorer";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import ShowButton from "../components/button/ShowButton";
import RentalList from "../rentals/RentalList";

const useStyles = makeStyles(theme => ({
    tracksLink: {
        color: '#000',
        backgroundColor: '#fff',
        borderRadius: 0,
        float: 'right',
    }
}));

function ShowMap(props) {
    const record = props.record;
    return (
        <LocationMap {...record} mapHeight={'100vh'}/>
    )
}

const LocationShow = ({ ...props}) => {

    const classes = useStyles();
    let rentalProps = {
        basePath: "/rentals",
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        history: {},
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "rentals"
    }

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'L'} title={'Location'} subheader={'Details'}/>
                <CardContent>
                    {/* Content Start */}
                    <Show {...props}

                    >
                        <TabbedShowLayout >
                            <Tab label="Location">
                                <FunctionField render={record =>
                                    <Button variant="outlined" className={classes.tracksLink} href={`${process.env.REACT_APP_TRACKS_URL}/#/organisations/${record.organisationUuid}/locations/view/${record.id}`}
                                             target="_blank" rel="noreferrer">
                                        View on Tracks
                                    </Button>
                                } addLabel={false} />

                                <FunctionField render={data => <KegnovaTextField value={data.locationName} label={'Name'} linkType={false} style={{width: '100%'}} multiline={true} /> } addLabel={false} />
                                <FunctionField render={data => <KegnovaTextField value={data.streetAddress} label={'Street Address'} style={{width: '100%'}} multiline={true}/> } addLabel={false} />

                                <ReferenceField resource="organisations" reference="organisations" source="organisationUuid"
                                                basePath="/organisations" link={false} addLabel={false} >
                                    <FunctionField render={data => <KegnovaTextField value={data.name} label={'Organisation'} linkType={false} style={{width: '100%'}} multiline={true} /> } addLabel={false} />
                                </ReferenceField>
                                <FunctionField render={record => <LocationDetails record={record} /> } addLabel={false} />
                            </Tab>

                            <Tab label="Packages" path="packages">
                                <FunctionField render={record => <PackageExplorer type={'location'} name={record.locationName} location={record.uuid} /> } addLabel={false} />
                            </Tab>

                            <Tab label="Rentals" path="rentals">
                                <FunctionField render={record =>
                                    <Box m={2}><Typography variant={'h4'}>{ record.locationName } RENTALS</Typography>
                                    </Box> } addLabel={false} />

                                <div><Divider className={classes.subDivider}/></div>
                                <Box m={2}></Box>

                                <FunctionField render={record => <RentalList orgLocation={record.uuid} {...rentalProps} /> } addLabel={false} />

                            </Tab>
                            <Tab label="Map" path="map">
                                <ShowMap/>
                            </Tab>

                        </TabbedShowLayout>

                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default LocationShow;

