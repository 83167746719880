import React, {Fragment} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Error, useQuery} from "react-admin";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CountryIcon from '@material-ui/icons/Flag';
import {CountryFlag} from "./CountryFlag";
import KegstarCircularProgress from "./layout/KegstarCircularProgress";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    ExpansionPanelSummary: {
        padding: '15px'
    },
    ExpansionPanelDetails: {
        padding: '10px'
    },
    selectLabel: {
      paddingLeft: '5px'
    },
    ListItem: {
        margin: '5px',
        padding: '5px'
    },
    ListItemText: {
        marginLeft: '1em'
    },
}));

const setCountry = (country) => {
    console.log(country)
    localStorage.setItem('cUuid',country);
    window.location.reload();
}


const GetCountryWithPackages = () => {
    const classes = useStyles();

    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'packages/countries',
        payload: {
            pagination: { perPage: 250 }, sort:{ field: 'id', order: 'ASC' }, filter: {countryUuid: 'all'}
        }
    });

    if (loading) return <KegstarCircularProgress />;
    // if (error) return <Error />;
    if (!data) return null;

    return (
        <Fragment>
            <List component="nav" aria-label="main mailbox folders">
                {
                    data.map(
                    item =>
                        (
                            <ListItem button onClick={ () => { setCountry(item.uuid)} } className={classes.ListItem} >
                                <ListItemIcon>
                                    <CountryFlag country={item.uuid} height={'30'}/>
                                </ListItemIcon>
                                <ListItemText className={classes.ListItemText} primary={ item.name } />
                            </ListItem>
                        )
                    )
                }
            </List>
        </Fragment>
    )
};

const KegstarCountrySelect = (record) => {
    const classes = useStyles();
    const [country, setCountry] = React.useState(localStorage.getItem('cUuid'));

    const handleChange = (event) => {
        setCountry(event.target.value);
    };

    return (
        <Box m={1}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.ExpansionPanelSummary}
                >
                    <CountryIcon />  <Typography className={classes.selectLabel}> Select country</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>

                    <GetCountryWithPackages />

                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Box>
    )
}

export default KegstarCountrySelect;
