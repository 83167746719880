import React, {Fragment} from "react";
import styled, { keyframes } from 'styled-components';
import {rotateIn, zoomIn} from 'react-animations';
import {makeStyles} from "@material-ui/core/styles";

const loadingAnimationRotateIn = keyframes`${rotateIn}`;
const LoadingWrapperRotate = styled.div`
  animation: 1s ${loadingAnimationRotateIn} infinite;
`;
const loadingAnimationZoomIn = keyframes`${zoomIn}`;
const LoadingWrapperZoom = styled.div`
  animation: 0.5s ${loadingAnimationZoomIn} infinite;
`;
const useStyles = makeStyles( theme => ({
        circle: {
            width: '50px', opacity: 0.1,
            filter: theme.overrides.iconFilter.filter
        },
        star: {
            width: '33px',
            filter: theme.overrides.iconFilter.filter
        },
    })
);
const KegstarAppLoader = () => {
    const classes = useStyles();

    return (
        <div style={{padding: '10px'}}>
            <div style={{
                position: 'absolute', zIndex: -10,  marginLeft: '-9px', marginTop: '-8px'
            }}>
                <LoadingWrapperZoom>
                    <img src={ require('../../assets/images/Circle.svg') } alt={''} className={classes.circle} />
                </LoadingWrapperZoom>
            </div>
            <div style={{zIndex: 10
            }}>
                <LoadingWrapperRotate>
                    <img src={ require('../../assets/logos/KegstarLogo_Star.svg') } alt={''} className={classes.star} />
                </LoadingWrapperRotate>
            </div>
        </div>
    )
}

export default KegstarAppLoader;
