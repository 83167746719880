import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Error} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import KegstarDataLoader from "../../components/loaders/KegstarDataLoader";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import PackageSummaryContent from "./PackageSummaryContent";
import {styles} from "../PackageStyles";
import PackageDetailsContent from "./PackageDetailsContent";
import WeeklyIcon from "@material-ui/core/SvgIcon/SvgIcon";
const useStyles = makeStyles(styles);

const GetPackageView = ({type, name, country, organisation, location, checkedDate, sku}) => {

    let apiParameter = '?';

    switch (type) {
        case 'country':
            apiParameter += 'countryUuid='+country;
            break;
        case 'organisation':
            apiParameter += 'organisationUuid='+organisation;
            break;
        case 'location':
            apiParameter += 'locationUuid='+location;
            break;
    }

    if (checkedDate) {
        apiParameter += 'checkedDate='+checkedDate;
    }
    if (sku) {
        apiParameter += 'skuUuid='+sku;
    }

    let url = process.env.REACT_APP_API_URL + `/packages/details` + apiParameter;

    const [packageSummary, setPackageSummary] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            try {

                const result = await axios(url, { headers: { 'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-api-key': localStorage.getItem('apiKey')
                    } });

                setPackageSummary(result.data);

            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [url]);

    if (isLoading) return <KegstarDataLoader />;
    // if (isError) return <Error />;
    if (!packageSummary) return null;

    return (
        <Fragment>
            <PackageSummaryContent type={type} name={name} packageSummary={packageSummary} isError={isError} />
        </Fragment>
    )
};

const PackageExplorerFilter = ({setCheckedDate, setSku}) => {

    const handleChange = event => {
        setCheckedDate(event.target.value);
        setSku(event.target.value);
    };

    return (
        <Fragment>
            {/*<FormControl variant="filled">*/}
            {/*   */}
            {/*</FormControl>*/}
        </Fragment>
    )
}

const PackageExplorer = ({type, name, country, organisation, location, ...props}) => {
    const classes = useStyles();
    const [checkedDate, setCheckedDate] = useState(null);
    const [sku, setSku] = useState(null);

    return(
        <Fragment>
            <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                { country && <GetPackageView type={'country'} country={country} name={name}/>}
                { organisation && <GetPackageView type={'organisation'} organisation={organisation} name={name}/>}
                { location && <GetPackageView type={'location'} location={location} name={name} />}
            </Grid>
        </Fragment>
    )
}

export default PackageExplorer;
