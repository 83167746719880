import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import {Error, LinearProgress, useQueryWithStore} from "react-admin";
import KegstarTempDashboardSpinner from "../components/loaders/KegstarTempDashboardSpinner";

export const styles = theme => ({
    root: {
        flexGrow: 1,
        fontFamily: 'NettoOT',
        paddingLeft: '2em'
    },
    logo: {
        width: '3em',
        padding: '1em'
    },
    label: {
        padding: '1em',
        color: theme.palette.primary.main
    },
    headline: {
        fontFamily: 'NettoOT Bold',
        fontSize: '3em',
        color: theme.palette.primary.dark
    },
    subHeadline: {
        color: theme.palette.primary.dark
    },
    organisation: {
        fontFamily: 'NettoOT',
        fontSize: '2em'
    }
});

const useStyles = makeStyles(styles);

const Logo = () => {
    const classes = useStyles();
    return (
        <div>
            <img src={require('../assets/logos/logo-sm-kegstar-keg.svg')} className={classes.logo} alt={''} />
        </div>
    )
}

const OrganisationName = () => {
    const classes = useStyles();

    const { loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'organisations',
        payload: { id: localStorage.getItem('oUuid') }
    });

    if (!loaded) { return <LinearProgress />; }
    if (error) return <Error />;
    if (!data) return null;
    if (!data.name) return null;

    return (
        <Fragment>
            <Typography variant={'subtitle1'} className={classes.organisation}> {data.name} </Typography>
        </Fragment>
    );
}

export default function DashboardBanner() {
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <Box m={3}>
                            <Typography className={classes.headline}> Welcome to Kegnova </Typography>
                            { (localStorage.getItem('firstName')) &&
                                <Box>
                                    <Typography className={classes.subHeadline} variant={"h4"} >{ localStorage.getItem('firstName') } { localStorage.getItem('lastName') } </Typography>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <KegstarTempDashboardSpinner />
            </div>
        </Fragment>
    );
}
