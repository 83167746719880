import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const CustomerInvoicesExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            id,	uuid,	status,	createdAt,	modifiedAt,  orderUuid, countryUuid , countryName,	countryCode,
            organisationName,	organisationUuid   , draftInvoiceUuid	,invoiceUrl , netsuiteDocNumber, invoiceStatus, invoiceType, netsuiteInvCreatedAt,
            cutoffDate, dueDate, invoiceCreatedAtText, invoiceStatusText,	createdAtText,	modifiedAtText, actionedAt,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'displayId', 'netsuiteInvCreatedAtText', 'netsuiteInvDocNumber', 'cutoffDateText', 'paymentDays', 'dueDateText', 'subTotal', 'discountAmount', 'netTotal', 'invoiceTypeText', 'tracksOrderId', 'actionedAtText'

        ], // order fields in the export
        rename:[
            'Id', 'Invoice Gen. Date', 'Invoice No.', 'Cutoff Date', 'Payment Days', 'Due Date', 'Sub Total', 'Discount', 'Total (Excl. Tax)', 'Invoice Type', 'Order No.', 'Actioned At'
        ]
    }, (err, csv) => {
        downloadCSV(csv, 'Invoices'); // download as 'posts.csv` file
    });

};

export default CustomerInvoicesExport;
