import React, { Fragment } from 'react';
import {
    Datagrid, List, TextInput, DateField, NumberField,
    FunctionField, SelectInput, TextField, DateInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import ButtonDownloadIcon from "../components/icons/ButtonDownloadIcon";
import ShowButton from "../components/button/ShowButton";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import CustomerInvoicesExport from "./CustomerInvoicesExport";
import InvoiceTypeTextField from "../components/field/InvoiceTypeTextField";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import {showInvoiceField} from "../components/Utilities";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    organisationFilter: {
        width: '20em'
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
    gridListActionButton: {float: 'right', textAlign: 'right', borderLeft: '1px solid #E0E0E0'},
    closeFilterButton: {padding: '0px', marginTop: '15px'},
    closeFilterIcon: {fontSize: 'smaller'}
}));

const ListFilters = ({...props}) => {
    const classes = useStyles();
    return (
        <KegstarFilter {...props} >

            <DateInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"createdAt[from]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            <DateInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"createdAt[to]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            {/*<KegnovaDatePickerInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn/>*/}
            {/*<KegnovaDatePickerInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn/>*/}

            <SelectInput source="invoiceType" label={'Invoice Type'} choices={[
                { id: 1, name: 'Order' },
                { id: 2, name: 'Monthly'}
            ]} options={{variant:'outlined'}} alwaysOn />

            {/*<DateInput source="invoiceDueDate" label={'Due Date From'} parse={v => { return dateInputTimezoneConverter(v, localStorage.getItem('timezone')) } } inputVariant={'standard'} alwaysOn/>*/}
            <DateInput source="invoiceDueDate" label={'Due Date'} inputVariant={'standard'} alwaysOn options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"invoiceDueDate": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            <DateInput source="actionedAt" label={'Actioned At'} inputVariant={'standard'} options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"actionedAt": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />

            {/*<DateInput source="invoiceDueDate[to]" label={'Due Date To'} inputVariant={'standard'} />*/}

            <TextInput source={'tracksOrderId'} label={'Order No.'} resettable alwaysOn/>

        </KegstarFilter>
    )

}

const InvoiceList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();

    return (

        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'I'} title={'Invoices'} />
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <List {...props} sort={{ field: 'createdAt', order: 'DESC', countryUuid: localStorage.getItem('cUuid') }}
                          filters={<ListFilters classes={classes}/>}
                          bulkActionButtons={false}
                          filter={{organisationUuid: localStorage.getItem('oUuid')}}
                          actions={<KegstarListActions maxResults={10000}/>}
                          exporter={CustomerInvoicesExport}
                    >
                        {isSmall ? (
                            <Datagrid>
                                <InvoiceTypeTextField emptyText source={'invoiceType'} label={'Invoice Type'} />

                                <TextField emptyText source={'netsuiteInvDocNumber'} label={'Invoice No.'} />

                                <DateField source="createdAt" label={'Invoice Generated Date'} />
                                <DateField source="actionedAt" label={'Actioned At'} />

                                <NumberField source={'netTotal'} label={'Total (Excl. Tax)'} sortable={false} options={{ maximumFractionDigits: 2 }}/>

                                { showInvoiceField() &&
                                <KegstarReferenceField source="draftInvoiceUuid" reference="invoices/draft" label={''} link={false} sortable={false} style={{float: 'right', textAlign: 'right'}} >
                                    <FunctionField render={record => <ShowButton variant={'none'} color={'black'} label={false} basePath={'/invoices/draft'} record={record} style={{float: 'right', textAlign: 'right'}} /> } />
                                </KegstarReferenceField>
                                }
                            </Datagrid>
                        ) : (
                        <Datagrid className={'mystarInvoiceList'}>

                            <InvoiceTypeTextField emptyText source={'invoiceType'} label={'Invoice Type'} />

                            <TextField emptyText source={'netsuiteInvDocNumber'} label={'Invoice No.'} />

                            <DateField source="actionedAt" label={'Order Actioned Date'} />

                            <KegstarReferenceField source="orderUuid" reference="orders" link={false} label={'Order No.'}>
                                <TextField emptyText source={'tracksOrderId'} />
                            </KegstarReferenceField>

                            {/*<DateField source="dueDate" label={'Due Date'} options={{timeZone: localStorage.getItem('timezone')}} />*/}
                            <DateField source="dueDate" label={'Due Date'} />

                            { showInvoiceField() &&
                            <NumberField source={'subTotal'} label={'Total Before Discount'} sortable={false}
                                         options={{minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
                            }
                            { showInvoiceField() &&
                            <NumberField source={'discountAmount'} label={'Total Discount'} sortable={false}
                                         options={{minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
                            }
                            { showInvoiceField() &&
                            <NumberField source={'netTotal'} label={'Total (Excl. Tax)'} sortable={false}
                                         options={{minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
                            }

                            <FunctionField render={ record => <Link href={record.invoiceUrl} target="_blank" rel="noreferrer" style={{padding: 0, margin: 0, minWidth: '35px', minHeight: '35px'}} >
                                <ButtonDownloadIcon />
                            </Link>
                            }  link={false} sortable={false} label={''} source={'gridListAction'} />

                            { showInvoiceField() &&
                                <KegstarReferenceField source="draftInvoiceUuid" reference="invoices/draft" label={''} link={false} sortable={false}>
                                    <FunctionField render={record => <ShowButton variant={'none'} color={'black'} label={false}
                                                                                 basePath={'/invoices/draft'} record={record}
                                                                                 style={{padding: 0, margin: 0, minWidth: '35px', minHeight: '35px'}} />
                                    } link={false} sortable={false} label={''} />
                                </KegstarReferenceField>
                            }
                        </Datagrid>
                        )}
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default InvoiceList;
