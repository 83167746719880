import React, { Fragment } from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    footer: {
        position: 'fixed', right: 0, bottom: 0, left: 0,
        padding: '6px',
        backgroundColor: '#FFF',
        color: '#000'
    },
    footerRoot: {
        float: 'right',
        display: 'inline-block'
    },
    footerItem: {
        display: 'inline-block',
        padding: '0.5em',
        paddingRight: '2em',
        fontSize: 'smaller'
    },
    footerLink: {
        color: '#000',
        textDecoration: 'none'
    }
}));

const getAppLink = () => {
    let url = '';

    if(navigator.vendor.includes('Apple')) {
        url = 'https://apps.apple.com/au/app/kegstar/id942336627';
    } else {
        url = 'https://play.google.com/store/apps/details?id=com.kegstar.kegstar';
    }

    return url;
}

const Footer = (props: any) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Box m={5}></Box>
            <div className={classes.footer}>
                <div className={classes.footerRoot}>
                    <div className={classes.footerItem} ><a href={getAppLink()} target="_blank" className={classes.footerLink}>Open App</a> </div>
                    <div className={classes.footerItem} ><a href={'https://kegstar.com/privacy-policy'} target="_blank" className={classes.footerLink}>Privacy Policy</a> </div>
                    <div className={classes.footerItem} >© 2020 Kegstar</div>
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;

