import React, {Fragment} from "react";
import { makeStyles } from "@material-ui/core/styles";

export const styles = theme => ({
    iconWidth: {
        width: '24px',
        color: theme.palette.primary.dark,
        filter: theme.overrides.iconFilter.filter
    },
    iconSmallerWidth: {
        width: '18px',
        color: theme.palette.primary.dark,
        filter: theme.overrides.iconFilter.filter
    },
    iconXSmallWidth: {
        width: '12px',
        color: theme.palette.primary.dark,
        filter: theme.overrides.iconFilter.filter
    }
});

const useStyles = makeStyles(styles);

const SidebarBaseMenuIcon = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <img src={require('../../assets/icons/SideMenu_Accounts.svg')} alt={'alt'} className={classes.iconWidth} />
        </Fragment>
    )
}

export default SidebarBaseMenuIcon;
