import React, {Fragment} from 'react';
import {
    FunctionField,
} from 'react-admin';
import { Show, SimpleShowLayout} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import {CountryFlag} from './CountryList';
import PackageExplorer from "../../packages/details/PackageExplorer";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.primary.light,
        paddingBottom: '1em',
        verticalAlign: 'top'
    },
    div: {
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingRight: '1em'
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        paddingLeft: '10px'
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
    divider: {border: '0.5px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
}));

const CountryDetails = ({record}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <div className={classes.container} >
                <div className={classes.div}><CountryFlag record={record} height={'72px'} /></div>
                <div className={classes.div}><Typography variant={'h4'}> {record.name} </Typography></div>
            </div>
        </Fragment>
    )
}

const CountryShow = ({ ...props }) => {

    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'C'} title={'Country'} subheader={'Details'}/>
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <Show {...props}  >
                        <SimpleShowLayout>
                            <FunctionField render={record => <CountryDetails record={record} /> } addLabel={false} />

                            <FunctionField render={record => <Box m={1}><PackageExplorer type={'country'} name={record.name} country={record.uuid} /></Box>}
                                               addLabel={false} />
                        </SimpleShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default CountryShow;
