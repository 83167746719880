import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    DateInput, FunctionField, Link, TextInput
} from 'react-admin';
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import {makeStyles} from "@material-ui/core";
import KegstarReferenceInput from "../components/input/KegstarReferenceInput";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";
import RentalsExport from "./RentalsExport";
import ShowButton from "../components/button/ShowButton";

const useStyles = makeStyles(theme => ({
    organisationField: {
        width: '25em'
    },
}));

const ListFilters = ({organisation, location, ...props}) => {
    const classes = useStyles();

    return (
        <KegstarFilter {...props}>

            <TextInput source={'id'} label={'Rental Id'} alwaysOn resettable/>
            <TextInput source={'kegSerialNumber'} label={'Keg Serial Number'} alwaysOn resettable/>
            <TextInput source={'tracksOrderId'} label={'Order Id'} alwaysOn resettable/>
            {/*<KegnovaDatePickerInput source={'startDate[from]'} label={'From Start Date'} />*/}
            {/*<KegnovaDatePickerInput source={'startDate[to]'} label={'To Start Date'} />*/}
            <DateInput source={'startDate[from]'} label={'Cycle Start Date From'} inputVariant={'standard'} alwaysOn/>
            <DateInput source={'startDate[to]'} label={'Cycle Start Date To'} inputVariant={'standard'} />
            <DateInput source={'endDate[from]'} label={'Cycle End Date From'} inputVariant={'standard'} />
            <DateInput source={'endDate[to]'} label={'Cycle End Date To'} inputVariant={'standard'} />

        </KegstarFilter>
    )
}

const RentalList = ({organisation = null, orgLocation = null, ...props}) => {

    let rentalFilter = null;

    if(organisation) {
        rentalFilter = {organisationUuid: organisation};
    } else if (orgLocation) {
        rentalFilter = {locationUuid: orgLocation};
    }

    return (
        <Fragment>
            <List {...props} filters={<ListFilters organisation={organisation} />} perPage={10} bulkActionButtons={false} sort={{ field: 'createdAt', order: 'DESC' }}
                  filter={ rentalFilter }
                  actions={<KegstarListActions maxResults={10000} />}
                  exporter={RentalsExport}
            >
                <Datagrid  className={localStorage.getItem('userRole')}>
                    <FunctionField render={record =>
                        <Link to={`/report/rentals?order=DESC&page=1&perPage=10&sort=id&filter={"id":${record.displayId}}`}>
                            {record.displayId}
                        </Link>
                    } label={'Rental Id'} />

                    <TextField source={'kegSerialNumber'} label={'Serial Number'} />

                    <TextField source="skuName" label={"SKU"} />
                    <ReferenceField source="orderUuid" reference="orders" label={'Order Id'} link={false} sortable={false}>
                        <FunctionField
                            render={record => <Link href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`}
                                                    target="_blank" rel="noreferrer"> {record.tracksOrderId}
                            </Link> } />
                    </ReferenceField>

                    <FunctionField render={record =>
                        <KegstarReferenceField source="startKegRole.location.uuid" reference="locations" label={'Start Location'} linkType="show" record={record} basePath={'/locations'}>
                            <TextField source="locationName" />
                        </KegstarReferenceField>
                    } label={'Start Location'} />

                    <DateField source="startDate" label={'Cycle Start Date'} />
                    {/*<DateField source="endDate" label={'Cycle End Date'}  />*/}

                    <ShowButton variant={'text'} color={'default'} label={''} />
                </Datagrid>
            </List>
        </Fragment>
    )
};

export default RentalList;
