import React, {Fragment, useCallback, useState} from 'react';
import {
    Show,
    FunctionField,
    ReferenceManyField,
    Pagination,
    Tab,
    TabbedShowLayout,
    Datagrid,
    TextField,
    DateField,
    Link,
    ReferenceField,
    List,
    DateInput,
    ListView,
    ListController, NumberField
} from 'react-admin';
import {KegnovaTextField} from "../components/KegnovaTextField";
import {Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {LocationGrid} from "../locations/LocationList";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import {useSelector} from "react-redux";
import KegstarDataLoader from "../components/loaders/KegstarDataLoader";
import WeeklySummary from "../summaries/WeeklySummary";
import PackageExplorer from "../packages/details/PackageExplorer";
import KegstarReferenceManyField from "../components/field/KegstarReferenceManyField";
import MonthlySummary from "../summaries/MonthlySummary";
import WeeklyIcon from '@material-ui/icons/DateRange';
import MonthlyIcon from '@material-ui/icons/Event';
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import Divider from "@material-ui/core/Divider";
import ShowButton from "../components/button/ShowButton";
import KegstarListActions from "../components/list/KegstarListActions";
import KegstarFilter from "../components/list/KegstarFilter";
import KegstarReferenceInput from "../components/input/KegstarReferenceInput";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";
import {InvoiceListRowStyle, InvoiceStatusName, InvoiceTypeName, PackageListRowStyle} from "../components/Utilities";
import RentalList from "../rentals/RentalList";
import {InvoicePDFButton} from "../components/InvoicePDFButton";
import InvoiceShow from "../invoices/draft/InvoiceShow";
import InvoiceList, {InvoiceListFilters} from "../invoices/draft/InvoiceList";

const useStyles = makeStyles(theme => ({
   organisationName: {
       fontSize: '28px',
       fontFamily: 'NettoOT'
   },
    cardContent: {
       padding: '0px'
    },
    billingDetails: {
       color: 'gray'
    },
    subTitle: {
       fontFamily: 'NettoOT',
        paddingBottom: '1.5em'
    },
    dataGrid: {
        background: theme.palette.secondary.dark
   },
    viewPackages: {
        color: '#00A3E0'
    },
    summaryButton: {
        float: 'right',
        borderRadius: '0px'
    },
    summaryIcon: {
        padding: 0,
        margin: 0,
        paddingTop: '5px',
        paddingRight: '5px'
    },
    divider: {border: '1px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
    subDivider: {border: '0.5px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
    boxContainer: {
       backgroundColor: theme.palette.secondary.light,
        padding: '1em'
    }
}));

const OrganisationTitle = ({record}) => {
    return (
        <Fragment>
            <Box>
                <Typography variant={'h3'} style={{fontWeight: 'bold', marginTop: '1rem'}}>{ record.name }</Typography>
            </Box>
        </Fragment>
    );
}

const OrganisationDetails = ({record}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Box m={1}>
                <KegnovaTextField value={record.displayId} label={'Id'} linkType={false} />
                <KegnovaTextField value={record.countryName} label={'Country'} linkType={false} />
                <KegnovaTextField value={record.businessNumber} label={'Business Number'} fullWidth={true} linkType={false} />
                <KegnovaTextField value={record.liquorLicence} label={'Liquor Licence'} fullWidth={true}linkType={false} />
                <KegnovaTextField value={record.billingAddress} label={'Billing Address'} multiline={true} fullWidth={true} />
                <KegnovaTextField value={record.billingDetails} label={'Billing Details'} multiline={true} fullWidth={true} />
                <KegnovaTextField value={record.isPsaCustomer ? 'Yes' : 'No'} label={'PSA Customer'} linkType={false} />
                { record.isPsaCustomer ?<KegnovaTextField value={new Date(record.psaStart).toLocaleDateString()} label={'PSA Start'} linkType={false} /> : null}
                { record.isPsaCustomer ?<KegnovaTextField value={new Date(record.psaEnd).toLocaleDateString()} label={'PSA End'} linkType={false} /> : null}
            </Box>
        </Fragment>
    );
}
const DisplaySummaryIcon = (summary) => {
    let displaySummaryIcon = null;
    if (summary.weekly.visible) {
        displaySummaryIcon = summary.monthly.icon
    } else {
        displaySummaryIcon = summary.weekly.icon
    }
    return displaySummaryIcon
}
const DisplaySummaryLabel = (summary) => {
    let displaySummaryIcon = null;
    if (summary.weekly.visible) {
        displaySummaryIcon = summary.monthly.name
    } else {
        displaySummaryIcon = summary.weekly.name
    }
    return displaySummaryIcon
}


const OrganisationShow = ({...props}) => {
    const loading = useSelector(state => state.admin.loading > 0);
    const classes = useStyles();
    const [summary, setSummary] = useState({ weekly: { icon: <WeeklyIcon />,  name: 'Weekly', visible: true }, monthly: {icon: <MonthlyIcon />, name: 'Monthly', visible: false} } );

    const handleSummary = useCallback(() => {
        setSummary({ weekly: { icon: <WeeklyIcon />, name: 'Weekly', visible: summary.weekly.visible ? !summary.weekly.visible : !summary.weekly.visible },
                                    monthly: { icon: <MonthlyIcon />, name: 'Monthly', visible: summary.monthly.visible ? !summary.monthly.visible : !summary.monthly.visible} });
    }, [
        summary,
        setSummary
    ]);

    let rentalProps = {
        basePath: "/rentals",
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        history: {},
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "rentals"
    }

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} {...props} avatar={'O'} title={'Organisation'} subheader={'Details'}/>
                <CardContent className={classes.cardContent}>
                    {/*{ (loading) && <KegstarDataLoader /> }*/}
                    {/* Content Start */}
                    <Show {...props} component="div"
                          // style={(loading) ? {display: 'none'} : null }
                    >
                        <TabbedShowLayout >
                            <Tab label="Summaries" >
                                <FunctionField render={record => <OrganisationTitle record={record} /> } addLabel={false} />
                                <Grid container alignItems="flex-end" justify="flex-end" direction="row">
                                    <Button variant={'outlined'} onClick={handleSummary} className={classes.summaryButton}>
                                        <span className={classes.summaryIcon}>{ DisplaySummaryIcon(summary)}</span>  Switch to { DisplaySummaryLabel(summary) } summaries
                                    </Button>
                                </Grid>
                                { summary.weekly.visible && <FunctionField render={record => <WeeklySummary organisation={record.uuid} /> } addLabel={false} /> }
                                { summary.monthly.visible && <FunctionField render={record => <MonthlySummary organisation={record.uuid} /> } addLabel={false} /> }
                            </Tab>
                            <Tab label="Packages" path="packages">
                                <FunctionField render={record => <PackageExplorer type={'organisation'} name={record.name} organisation={record.uuid} /> } addLabel={false} />
                            </Tab>
                            <Tab label="Locations" path="locations">
                                <Box m={2}><Typography variant={'h4'}>LOCATIONS</Typography></Box>
                                <div><Divider className={classes.subDivider}/></div>
                                <Box m={2}></Box>
                                <KegstarReferenceManyField
                                    reference="locations"
                                    target="organisationUuid" addLabel={false}
                                    pagination={<Pagination/>}
                                >
                                    <LocationGrid />
                                </KegstarReferenceManyField>
                            </Tab>
                            <Tab label="Invoices" path="invoices">
                                <FunctionField render={record =>
                                    <Box m={2}><Typography variant={'h4'}>{ record.name } INVOICES </Typography>
                                    </Box> } addLabel={false} />

                                <div><Divider className={classes.subDivider}/></div>
                                <Box m={2}></Box>

                                <FunctionField render={record => <InvoiceList organisation={record.uuid} basePath={'/invoices/draft'} resource={'invoices/draft'} /> } addLabel={false} />

                            </Tab>
                            <Tab label="Rentals" path="rentals">

                                <FunctionField render={record =>
                                    <Box m={2}><Typography variant={'h4'}>{ record.name } RENTALS</Typography>
                                     </Box> } addLabel={false} />

                                <div><Divider className={classes.subDivider}/></div>
                                <Box m={2}></Box>

                                <FunctionField render={record => <Box m={3} className={classes.boxContainer}> <RentalList organisation={record.uuid} {...rentalProps} /> </Box> } addLabel={false} />

                            </Tab>
                            <Tab label="Users" path="users">

                                <FunctionField render={record =>
                                    <Box m={2}><Typography variant={'h4'}>{ record.name } USERS</Typography>
                                    </Box> } addLabel={false} />

                                <div><Divider className={classes.subDivider}/></div>
                                <Box m={2}></Box>

                                <FunctionField render={record =>
                                    <ReferenceManyField
                                        reference="users" record={record} basePath={'/users'}
                                        target="organisationUuid" addLabel={false} pagination={<Pagination/>}
                                    >
                                        {/*<FunctionField render={record => <OrganisationUsers record={record} /> } addLabel={false} />*/}
                                        <Datagrid>
                                            <TextField emptyText source="firstName"/>
                                            <TextField emptyText source="lastName"/>
                                            <TextField emptyText source="email"/>
                                            <TextField emptyText source="phone"/>
                                        </Datagrid>
                                    </ReferenceManyField>
                                } addLabel={false} />
                            </Tab>
                            <Tab label="Details" path="details">

                                <FunctionField render={record => <OrganisationDetails record={record}/> } addLabel={false} />

                            </Tab>
                        </TabbedShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default OrganisationShow;
