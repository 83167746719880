import React, {Fragment} from "react";

const AppbarRefreshIcon = () => {

    return (
        <Fragment>
            <img src={require('../../assets/icons/TopMenu_Refresh.svg')} alt={'alt'} style={{width: '33px'}} />
        </Fragment>
    )
}

export default AppbarRefreshIcon;
