import React from "react";
import format from 'date-fns/format'
import startOfMonth from 'date-fns/start_of_month'
import endOfMonth from 'date-fns/end_of_month'
import subMonth from 'date-fns/sub_months'

const MonthlyDates = (date) => {
    let monthDate = (date) ? new Date(date) : subMonth(new Date(), 1);
    let startOfMonthDate = startOfMonth(monthDate, {weekStartsOn: 1});
    let endOfMonthDate = endOfMonth(monthDate, {weekStartsOn: 1});
    let startMonthDay = format( startOfMonthDate, 'Y-MM-dd');
    let endMonthDay = format( endOfMonthDate, 'Y-MM-dd');
    return {
        startMonthDay: startMonthDay,
        endMonthDay: endMonthDay
    }
};

export function getRentalsFromMonthlySummaryLink(organisation, date) {
    const dates = MonthlyDates(date);
    return `/report/rentals?filter={"billingCycleStartDate":{"from":"${dates.startMonthDay}","to":"${dates.endMonthDay}"},"organisationUuid":"${organisation}"}&order=DESC&page=1&perPage=10&sort=id`;
}

export default MonthlyDates;
