import React, {Fragment} from 'react';
import {
    Datagrid,
    DatagridBody,
    ReferenceInput, DateInput, List
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import {FormatHeaderLabel, PackageListRowStyle} from "../../components/Utilities";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import KegstarFilter from "../../components/list/KegstarFilter";
import KegstarListActions from "../../components/list/KegstarListActions";
import PackageTypeFilterValidation from '../../components/dialogs/PackageTypeFilterValidation'
import {packageTypeValidation} from "../../helpers/Utilities";
import KegstarAutocompleteInput from "../../components/input/KegstarAutocompleteInput";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    packageTypeFilterSmall: {
        width: '12em'
    },
    packageTypeFilterLarge: {
        width: '20em'
    },
    subTitle: {
        fontFamily: 'NettoOT Bold'
    },
    divider: {border: '0.5px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
    tableCell: {
        backgroundColor: theme.palette.primary.light
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    }
}));

const ListFilters = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const validatePackageType = (value, allValues) => {
        let status = packageTypeValidation(allValues);
        if(status) {
            setOpen(true);
            return 'Select 1 package type - Country, Organisation or Location';
        } else {
            setOpen(false);
            return [];
        }
    };

    return (
        <div>
            <KegstarFilter {...props}>
                <DateInput source="checkedDate" label={'Checked Date'} alwaysOn />
                {/*<ReferenceInput source="countryUuid" reference="countries" className={classes.packageTypeFilterSmall} label={'Country'}*/}
                {/*                sort={{ field: 'id', order: 'ASC' }} validate={validatePackageType} alwaysOn>*/}
                {/*    <KegstarAutocompleteInput optionText="name" />*/}
                {/*</ReferenceInput>*/}
                <ReferenceInput source="organisationUuid" reference="organisations" className={classes.packageTypeFilterLarge} label={'Organisation'}  validate={validatePackageType} alwaysOn >
                    <KegstarAutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="locationUuid" reference="locations" className={classes.packageTypeFilterLarge} label={'Location'}  validate={validatePackageType} alwaysOn >
                    <KegstarAutocompleteInput optionText="locationName" />
                </ReferenceInput>
                <ReferenceInput source="skuUuid" reference="skus" label={'Sku'}>
                    <KegstarAutocompleteInput optionText="name" />
                </ReferenceInput>

                {/*<KegnovaDatePickerInput source="createdAt" label={'Package Date'} />*/}

            </KegstarFilter>
            { open && <PackageTypeFilterValidation /> }
        </div>
    )
}

const EmptyPricingModel = ({ basePath, resource }) => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            No pricing model found
        </Typography>
    </Box>
);

const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath, ...props }) => {
    const classes = useStyles();

    return (
        <Fragment>

            <Table>
                <TableHead style={{backgroundColor: '#EDEEEF'}}>

                    <TableRow>
                        &nbsp;
                    </TableRow>
                    <TableRow>
                        <Typography className={classes.subTitle}> PACKAGES </Typography>
                        <div><Divider className={classes.divider}/></div>
                    </TableRow>

                </TableHead>
            </Table>

            <Table>
                <TableHead style={{backgroundColor: '#EDEEEF'}}>

                    <TableRow>
                        <PackageDetailsHeaders packages={record.packages[0]} />
                    </TableRow>

                </TableHead>
                <TableBody style={{backgroundColor: '#fff'}}>

                    {record.packages.map(
                        item  =>
                            (
                                <TableRow>
                                    <PackageFeeValues packages={item} />
                                </TableRow>
                            )
                    )}

                </TableBody>
            </Table>

            <Table>
                <TableHead style={{backgroundColor: '#EDEEEF'}}>

                    <TableRow>
                        &nbsp;
                    </TableRow>

                    <TableRow>
                        <Typography className={classes.subTitle}> PACKAGE SET GROUP </Typography>
                        <div><Divider className={classes.divider}/></div>
                    </TableRow>

                </TableHead>
            </Table>

            <Table>
                <TableHead style={{backgroundColor: '#EDEEEF'}}>
                    <TableRow>
                        <PackageDetailsHeaders packages={record.packageSetGroup} />
                    </TableRow>
                </TableHead>
                <TableBody style={{backgroundColor: '#fff'}}>
                    <TableRow>
                        <PackageSetGroup packages={record.packageSetGroup} />
                    </TableRow>
                </TableBody>
            </Table>

        </Fragment>
    )
}

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;


const validateKey = (key) => {
    let valid = false;
    if( (key.indexOf('Fee') !== -1 && key.indexOf('Setting') === -1) || key === 'displayId') {
        valid = true;
    }
    return valid;
}

const showTableValue = (packages, key) => {
    return packages[key]
}

export const PackageDetailsHeaders = ({packages}) => {
    return Object.keys(packages).map(key =>
        (validateKey(key)) && <TableCell value={key}> { FormatHeaderLabel(key) }</TableCell>
    )
}

export const PackageFeeValues = ({packages}) => {
    const classes = useStyles();
    return Object.keys(packages).map(key =>
        (key.indexOf('Fee') !== -1 && key.indexOf('Setting') === -1 || key === 'displayId') && <TableCell className={classes.tableCell} value={key}>{ showTableValue(packages, key) }</TableCell>
    )
}

export const PackageSetGroup = ({packages}) => {
    return Object.keys(packages).map(key =>
        (key.indexOf('Fee') !== -1 && key.indexOf('Setting') === -1 || key === 'displayId') && <TableCell value={key}>{ showTableValue(packages, key) }</TableCell>
    )
}

export const PackagePaymentDaysHeaders = ({packages}) => {
    return Object.keys(packages).map(key =>
        (key.indexOf('PaymentDays') !== -1 || key === 'displayId') && <TableCell value={key}> { FormatHeaderLabel(key) }</TableCell>
    )
}

export const PackagePaymentDays = ({packages}) => {
    const classes = useStyles();
    return Object.keys(packages).map(key =>
        (key.indexOf('PaymentDays') !== -1 || key === 'displayId') && <TableCell className={classes.tableCell} value={key}>{ showTableValue(packages, key) }</TableCell>
    )
}

const PackageSummaryList = (props) => {

    const classes = useStyles();

    return(
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'P'} title={'Package Details'} subheader={'List of Package Details'}/>
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}

                    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}
                        // filterDefaultValues={ (localStorage.getItem('cUuid')) ? { countryUuid: localStorage.getItem('cUuid') } : null }
                          filters={<ListFilters />} bulkActionButtons={false}
                          pagination={false} empty={<EmptyPricingModel />} actions={<KegstarListActions />} exporter={false}
                    >
                        <MyDatagrid />

                    </List>

                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
}


export default PackageSummaryList;
