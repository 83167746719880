import React, {Fragment} from "react";
import {Grid, makeStyles} from "@material-ui/core";
import {KegnovaTextField} from "./KegnovaTextField";
import {ShowRoles} from "../helpers/Utilities";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
}));

export const LocationDetails = ({record}) => {
    return (
        <Fragment>
            <Grid container spacing={4} >
                <Grid item xs>
                    <Box m={2}>
                        <Typography variant={'h6'}>Location Role</Typography><ShowRoles record={record} />
                    </Box>
                    <KegnovaTextField value={record.countryName} label={'Country'} />
                    <KegnovaTextField value={record.timezone} label={'Timezone'} />
                    <KegnovaTextField value={record.googleLocationType} label={'Google Location Type'}  />

                </Grid>
            </Grid>
        </Fragment>
    );
}
