import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const PackageTypeFilterValidation = () => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-package-type-validation-title"
                aria-describedby="alert-package-type-validation-description"
            >
                <DialogTitle id="alert-package-type-validation-title">{"Package Type Validation Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-package-type-validation-description">
                        Select 1 package type filter of either a Country, Organisation or Location
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PackageTypeFilterValidation;
