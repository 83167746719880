import React, {Fragment, useState} from 'react';
import {
    Edit,
    NumberInput, DateInput, SelectInput,
    TabbedForm,
    TextInput, Toolbar, TopToolbar, SaveButton,
    FormTab, FunctionField, ReferenceInput, useQuery
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FeeTypes from "./FeeTypes";
import {CardContent} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Typography from "@material-ui/core/Typography";
import KegstarCircularProgress from "../components/layout/KegstarCircularProgress";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1, 2),
    },
    feeLabels: {
        fontSize: '17px',
        marginTop: '10px'
    },
    divider: {border: '0.5px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, marginTop: '0.25em', marginBottom: '1.5em'},
}));

const SelectStopRole = (element, classes) => {
    return <SelectInput source={element.source.name} choices={[
        { id: '', name: 'Select a Role' },
        { id: 'ROLE_WAREHOUSE', name: 'Warehouse' },
        { id: 'ROLE_PRODUCER', name: 'Producer' },
        { id: 'ROLE_DISTRIBUTOR', name: 'Distributor' },
        { id: 'ROLE_VENUE', name: 'Venue' },
        { id: 'ROLE_COLLECTOR', name: 'Collector' },
        { id: 'ROLE_CONTRACT_BREWER', name: 'Contract Brewer' },

    ]} label={element.source.label} options={{variant: 'outlined'}} defaultValue={element.source.defaultValue} />
}
const PackageEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
const PackageEditRedirect = (id) => {

    // const location = props.location;
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'packages',
        payload: {id: id}
    });

    if (error) return null;
    if (!data) return null;

    let link = '';

    const country = data.countryUuid;
    const organisation = data.organisationUuid;
    const location = data.locationUuid;

    if(country) {
        link = `/packages/countries/${country}/show?updated=true`;
    } else if(organisation) {
        link = `/organisations/${organisation}/show/packages?view=true`;
    } else if(location) {
        link = `/locations/${location}/show/packages?view=true`;
    }

    return link;

}

const freightValidation = (value, allValues) => {
    // @todo validate fees fields with minimuum fees, stop roles, etc...
    return [];
};
const validatePackageEditForm = (values) => {
    const errors = {};
    // @todo validate fees upon submission
    return errors
};

const PackageEdit = ({record, ...props}) => {
    const classes = useStyles();
    const redirect = PackageEditRedirect(props.id);

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'P'} title={'Package Edit'} subheader={'List of organisations'}/>
                <CardContent style={{backgroundColor: '#EDEEEF'}}>
                    <Edit title={'Package Edit'} {...props} actions={null} >
                        <TabbedForm toolbar={<PackageEditToolbar />} redirect={redirect} submitOnEnter={false} validate={validatePackageEditForm} >
                            <FormTab label="Rentals">
                                <TextInput source="tz" defaultValue={localStorage.getItem('timezone')} options={{variant: 'outlined'}} style={{display: 'none'}} addLabel={false}/>
                                <Box m={1}><Typography variant={'h5'}>RENTALS</Typography></Box>
                                <Divider className={classes.divider} fullWidth />
                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography className={classes.feeLabels} gutterBottom>Start Date</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DateInput source="startDate" label={'Start Date'} defaultValue={record.startDate} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.feeLabels} gutterBottom>End Date</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DateInput source="endDate" label={'End Date'} defaultValue={record.endDate} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />
                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography className={classes.feeLabels} gutterBottom>Issue Fee</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[IssueFee][amount]" label={'Amount'} defaultValue={record.issueFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />
                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography className={classes.feeLabels} gutterBottom>Cleaning Fee</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[CleaningFee][amount]" label={'Amount'} defaultValue={record.cleaningFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />
                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography className={classes.feeLabels} gutterBottom>Daily Rental Fee</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <NumberInput source="fees[DailyRentalFee][amount]" label={'Amount'} defaultValue={record.dailyRentalFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SelectStopRole source={{name: "fees[DailyRentalFee][stopRole]", label: 'Stop Role', defaultValue: null }} />
                                        </Grid>
                                        <Grid item xs={2}>
                                                <NumberInput source="fees[DailyRentalFee][minimumDays]" label={'Minimum Days'} defaultValue={null} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={2}>
                                                <NumberInput source="fees[DailyRentalFee][maximumDays]" label={'Maximum Days'} defaultValue={null} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput source="fees[DailyRentalFee][description]" label={'Description'} defaultValue={null} options={{variant: 'outlined', multiLine: true}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />
                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography className={classes.feeLabels} gutterBottom>Taproom Venue Fee</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[TaproomVenueFee][amount]" label={'Amount'} defaultValue={record.taproomVenueFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />
                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography className={classes.feeLabels} gutterBottom>Taproom Distributor Fee</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[TaproomVenueDistributor][amount]" label={'Amount'} defaultValue={record.taproomDistributorFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                            </FormTab>
                            <FormTab label="Freight" path="freight">
                                <Box m={1}><Typography variant={'h5'}>FREIGHT FEES</Typography></Box>
                                <Divider className={classes.divider} fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Freight Fee - Capital Metro</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[FreightCapitalMetroFee][amount]" label={'Amount'}  defaultValue={record.freightCapitalMetroFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[FreightCapitalMetroFee][minimumFee]" label={'Minimum Fee'} defaultValue={null} options={{variant: 'outlined'}}  />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Freight Fee - Regional Metro</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[FreightRegionalMetroFee][amount]" label={'Amount'}  defaultValue={record.freightRegionalMetroFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[FreightRegionalMetroFee][minimumFee]" label={'Minimum Fee'} options={{variant: 'outlined'}} defaultValue={null}  />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Freight Fee - Regional </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[FreightRegionalFee][amount]" label={'Amount'}  defaultValue={record.freightRegionalFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[FreightRegionalFee][minimumFee]" label={'Minimum Fee'} defaultValue={null} options={{variant: 'outlined'}}  />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Freight Fee - Super Regional </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[FreightSuperRegionalFee][amount]" label={'Amount'}  defaultValue={record.freightSuperRegionalFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[FreightSuperRegionalFee][minimumFee]" label={'Minimum Fee'} defaultValue={null} options={{variant: 'outlined'}}  />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Customer Pickup Fee </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[FreightPickUpFee][amount]" label={'Amount'}  defaultValue={record.freightPickUpFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberInput source="fees[FreightPickUpFee][minimumFee]" label={'Minimum Fee'} defaultValue={null} options={{variant: 'outlined'}}  />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Pallet Fee </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[PalletFee][amount]" label={'Amount'}  defaultValue={record.palletFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />


                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Express Fee</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[ExpressFee][amount]" label={'Amount'}  defaultValue={record.expressFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Non-Mystar Fee</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[NonMystarFee][amount]" label={'Amount'}  defaultValue={record.nonMystarFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Inter-Country Fee</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[InterCountryFee][amount]" label={'Amount'}  defaultValue={record.interCountryFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                            </FormTab>
                            <FormTab label="Collection" path="collection">
                                <Box m={1}><Typography variant={'h5'}>COLLECTION FEES</Typography></Box>
                                <Divider className={classes.divider} fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Collection Fee - Regional</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[CollectionRegionalFee][amount]" label={'Amount'}  defaultValue={record.collectionRegionalFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Collection Fee - Super Regional</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[CollectionSuperRegionalFee][amount]" label={'Amount'}  defaultValue={record.collectionSuperRegionalFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />



                            </FormTab>
                            <FormTab label="Timed" path="timed">
                                <Box m={1}><Typography variant={'h5'}>TIMED FEES</Typography></Box>
                                <Divider className={classes.divider} fullWidth />
                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Timed Fee </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="fees[TimedFee][amount]" label={'Amount'}  defaultValue={record.timedFee} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SelectStopRole source={{name: "fees[TimedFee][stopRole]", label: 'Stop Role', defaultValue: null }} options={{variant: 'outlined'}} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ReferenceInput source="timedFeesGroupUuid" reference="timedfeesgroups" resource="timedFeesGroupUuid"
                                                            label={'Timed Fee Group'} >
                                                <SelectInput optionText={choice =>
                                                    `${choice.description}`
                                                } options={{variant: 'outlined'}} />
                                            </ReferenceInput>
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                            </FormTab>
                            {/*<FormTab label="PSA" path="psa">*/}
                            {/*    <Box m={1}><Typography variant={'h5'}>PSA FEES</Typography></Box>*/}
                            {/*    <Divider className={classes.divider} fullWidth />*/}

                            {/*</FormTab>*/}
                            <FormTab label="Payment Terms" path="payment">
                                <Box m={1}><Typography variant={'h5'}>PAYMENT DAYS</Typography></Box>
                                <Divider className={classes.divider} fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Order PSA Invoice</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="invoicePaymentDays[OrderPsaInvoicePaymentDays][days]" label={'Days'}  defaultValue={record.orderInvoicePaymentDays} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Order Non-PSA Invoice</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="invoicePaymentDays[OrderInvoicePaymentDays][days]" label={'Days'}  defaultValue={record.orderInvoicePaymentDays} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />


                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Monthly PSA Invoice</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="invoicePaymentDays[MonthlyPsaInvoicePaymentDays][days]" label={'Days'}  defaultValue={record.monthlyPsaInvoicePaymentDays} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />

                                <FunctionField render={ record =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.feeLabels} gutterBottom>Monthly Non-PSA Invoice</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberInput source="invoicePaymentDays[MonthlyInvoicePaymentDays][days]" label={'Days'}  defaultValue={record.monthlyInvoicePaymentDays} options={{variant: 'outlined'}} />
                                        </Grid>
                                    </Grid>
                                } addLabel={false} />
                                <Divider fullWidth />



                            </FormTab>
                        </TabbedForm>
                    </Edit>
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default PackageEdit;
