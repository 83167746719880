import React from 'react';
import PropTypes from 'prop-types';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useTranslate } from 'ra-core';

import MenuItemLink from './MenuItemLink';
import {makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.dark
    },
    label: {
        color: theme.palette.primary.dark
    }
}));

const DashboardMenuItem = ({ locale, onClick, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <MenuItemLink
            onClick={onClick}
            to="/"
            primaryText={translate('ra.page.dashboard')}
            leftIcon={<DashboardIcon className={classes.root} />}
            exact
            dense
            className={classes.label}
            {...props}
        />
    );
};

DashboardMenuItem.propTypes = {
    classes: PropTypes.object,
    locale: PropTypes.string,
    onClick: PropTypes.func,
};

export default DashboardMenuItem;
