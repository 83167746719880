import React, { FC, ReactElement, MouseEvent, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { refreshView } from 'ra-core';

import Button, { ButtonProps } from './Button';
import {makeStyles} from "@material-ui/core";
import {Theme} from "@material-ui/core/styles";

const useStyles = makeStyles(
    (theme: Theme) => ({
        button: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.dark,
        },
    })
);

const KegstarRefreshButton: FC<RefreshButtonProps> = ({
                                                   label = 'ra.action.refresh',
                                                   icon = defaultIcon,
                                                   onClick,
                                                   ...rest
                                               }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClick = useCallback(
        event => {
            event.preventDefault();
            dispatch(refreshView());
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [dispatch, onClick]
    );

    return (
        <Button label={label} onClick={handleClick} {...rest}>
            <NavigationRefresh className={classes.button} />
        </Button>
    );
};

const defaultIcon = <NavigationRefresh />;

interface Props {
    label?: string;
    icon?: ReactElement;
    onClick?: (e: MouseEvent) => void;
}

export type RefreshButtonProps = Props & ButtonProps;

KegstarRefreshButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
};

export default KegstarRefreshButton;
