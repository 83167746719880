import React from "react";
import format from 'date-fns/format'
import startOfWeek from 'date-fns/start_of_week'
import endOfWeek from 'date-fns/end_of_week'
import subDays from 'date-fns/sub_days'

const WeeklyDates = (date) => {
    let weekDate = (date) ? new Date(date) : subDays(new Date(), 7);
    let mondayOfWeekDate = startOfWeek(weekDate, {weekStartsOn: 1});
    let sundayOfWeekDate = endOfWeek(weekDate, {weekStartsOn: 1});
    let startWeekDay = format( mondayOfWeekDate, 'Y-MM-dd');
    let endWeekDay = format( sundayOfWeekDate, 'Y-MM-dd');
    return {
        startWeekDay: startWeekDay,
        endWeekDay: endWeekDay
    }
};

export function getRentalsFromWeeklySummaryLink(organisation, date) {
    const dates = WeeklyDates(date);
    return `/report/rentals?filter={"billingCycleStartDate":{"from":"${dates.startWeekDay}","to":"${dates.endWeekDay}"},"organisationUuid":"${organisation}"}&order=DESC&page=1&perPage=10&sort=id`;
}

export default WeeklyDates;
