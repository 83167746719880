import React, {Fragment} from "react";
import { styles } from './SidebarBaseMenuIcon';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(styles);

const SidebarPackagesMenuIcon = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <img src={require('../../assets/icons/SideMenu_Packages.svg')} alt={'alt'} className={classes.iconWidth} />
        </Fragment>
    )
}

export default SidebarPackagesMenuIcon;
