import React, {Fragment, useState} from 'react';
import {
    Datagrid, List, TextField, DateField, NumberField, DateInput,
    FunctionField, SelectInput, TextInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import {InvoiceStatusName, InvoiceTypeName, InvoiceListRowStyle} from "../../components/Utilities";
import Link from "@material-ui/core/Link";
import {InvoicePDFButton} from "../../components/InvoicePDFButton";
import KegstarFilter from "../../components/list/KegstarFilter";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import ShowButton from "../../components/button/ShowButton";
import KegstarListActions from "../../components/list/KegstarListActions";
import InvoicesExport from "./InvoicesExport";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KegstarMobileList from "../../components/list/KegstarMobileList";
import KegstarReferenceInput from "../../components/input/KegstarReferenceInput";
import KegstarReferenceField from "../../components/field/KegstarReferenceField";
import KegstarAutocompleteInput from "../../components/input/KegstarAutocompleteInput";
import KegnovaDatePickerInput from "../../components/KegnovaDatePickerInput";
import IconButton from "@material-ui/core/IconButton";
import {Close, Event} from "@material-ui/icons";
import KegstarDateInput from "../../components/KegstarDateInput";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    organisationFilter: {
        width: '20em'
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
    closeFilterButton: {padding: '0px', marginTop: '15px'},
    closeFilterIcon: {fontSize: 'smaller'}
}));

const InvoiceListFilters = ({organisation, classes, permissions, ...props}) => {

    return (
        <KegstarFilter {...props} >

            {!organisation &&
            <KegstarReferenceInput source="organisationUuid" reference="organisations" label={'Organisation'}
                                   className={classes.organisationFilter}
                                   filterToQuery={searchText => ({
                                       q: searchText,
                                       countryUuid: localStorage.getItem('cUuid')
                                   })}
                                   alwaysOn>
                <KegstarAutocompleteInput optionText="name"/>
            </KegstarReferenceInput>
            }

            <TextInput source={'tracksOrderId'} label={'Order No.'} resettable />

            {/*<KegnovaDatePickerInput source="createdAt[from]" label={'Invoice Date From'} inputVariant={'standard'}  clearable={true} alwaysOn />*/}

            <DateInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn options={{InputProps: {
                endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"createdAt[from]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
            }}} />

            {/*<KegstarDateInput source={'createdAt[from]'} label={'Invoice Date From'} {...props} />*/}

            <DateInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"createdAt[to]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />

            <SelectInput source="type" label={'Invoice Type'} choices={[
                { id: 1, name: 'Order Invoice' },
                { id: 2, name: 'Monthly Invoice' }
            ]} alwaysOn  />

            <DateInput source="actionedAt" label={'Order Actioned Date'} inputVariant={'standard'} options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"actionedAt": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            {/*<KegnovaDatePickerInput source="dueDate" label={'Due Date'} inputVariant={'standard'} />*/}
            {/*<KegnovaDatePickerInput source="createdAt" label={'Invoice Date'} inputVariant={'standard'} />*/}
            <DateInput source="dueDate[from]" label={'Due Date From'} inputVariant={'standard'} options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"dueDate[from]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            <DateInput source="dueDate[to]" label={'Due Date To'} inputVariant={'standard'} options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"dueDate[to]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />

            <SelectInput source="invoiceStatus" label={'Status'} choices={[
                { id: 1, name: 'Draft' },
                { id: 2, name: 'Submitted' },
                { id: 3, name: 'Syncing' },
                { id: 4, name: 'Syncing Failed' },
                { id: 5, name: 'Synced' }
            ]} />

        </KegstarFilter>
    )
};

const InvoiceList = ({organisation, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    let filter = null;

    if(organisation) {
        filter={organisationUuid: organisation}
    } else {
        filter={countryUuid: localStorage.getItem('cUuid')}
    }

    return (
        <Fragment>
            <Card>
                { !organisation && <KegstarCardHeader {...props} avatar={'I'} title={'Invoices'} /> }
                <CardContent className={classes.cardContent}>

                    {/* Content Start */}
                    <List {...props}
                          sort={{field: 'createdAt', order: 'DESC'}}
                          filters={<InvoiceListFilters classes={classes} organisation={organisation} />}
                          filter={filter}
                          bulkActionButtons={false}
                          actions={<KegstarListActions maxResults={10000}/>}
                          exporter={InvoicesExport}
                    >
                        {isSmall ? (
                            <KegstarMobileList
                                primaryText={record => record.organisationName }
                                secondaryText={record => new Date(record.createdAt).toLocaleDateString()}
                                tertiaryText={record => `${record.netTotal}`}
                                linkType="show"
                            />
                        ) : (
                        <Datagrid
                            rowClick={'show'}
                            rowStyle={InvoiceListRowStyle} className={localStorage.getItem('userRole')}>
                            <FunctionField render={record => (InvoiceTypeName(record.type))} label={'Invoice Type'}/>

                            <DateField source="actionedAt" label={'Order Actioned Date'} />
                                { !organisation &&
                                    <KegstarReferenceField source="organisationUuid" reference="organisations" link={'show'}
                                    label={'Organisation'} sortable={false} >
                                        <TextField source="name" />
                                    </KegstarReferenceField>
                                }
                                <KegstarReferenceField source="draftInvoiceUuid" reference="invoices" link={'show'}
                                    label={'Invoice Number'} sortable={false}>
                                    <TextField source="netsuiteInvDocNumber"/>
                                </KegstarReferenceField>
                                <KegstarReferenceField source="orderUuid" reference="orders" label={'Order Number'} link={false}>
                                <FunctionField
                                render={record => <Link
                                href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`}
                                target="_blank" rel="noreferrer"> {record.tracksOrderId}
                            </Link>}/>
                                </KegstarReferenceField>
                                <DateField source="dueDate" label={'Due Date'} allowEmpty/>
                                <NumberField source={'subTotal'} label={'Total Before Discount'} options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
                                <NumberField source={'discountAmount'} label={'Total Discount'} options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
                                <NumberField source={'netTotal'} label={'Total (Exc. Tax)'} options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
                                <FunctionField render={record => (InvoiceStatusName(record.invoiceStatus))}
                                label={'Status'}/>
                                <FunctionField render={record => <InvoicePDFButton record={record}/>} addLabel={false}/>
                                <ShowButton variant={'text'} color={'default'}  label={''} />
                        </Datagrid>
                        )}
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default InvoiceList;
