import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    Filter,
    FunctionField,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput, Pagination, DateInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import KegnovaDatePickerInput from "../components/KegnovaDatePickerInput";
import {ChargeFeeTypes} from "../components/Utilities";
import Card from "@material-ui/core/Card";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import CardContent from "@material-ui/core/CardContent";
import {useSelector} from "react-redux";
import KegstarDataLoader from "../components/loaders/KegstarDataLoader";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";

export const styles = (theme => ({
    organisationFilter: {
        width: '20em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = ({classes, ...props}) => (
    <Filter {...props}>
        <ReferenceInput source="organisationUuid" reference="organisations" label={'Organisation'} className={classes.organisationFilter} alwaysOn >
            <KegstarAutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="feeType" label={'Fee Type'} choices={ChargeFeeTypes} style={{width: '15em'}} alwaysOn/>
        {/*<KegnovaDatePickerInput source="applicableDate[from]" label={'Applicable Date From'} />*/}
        {/*<KegnovaDatePickerInput source="applicableDate[to]" label={'Applicable Date To'} />*/}
        <DateInput source="applicableDate" label={'Applicable Date'} />

        <ReferenceInput source="skuUuid" reference="skus" label={'Sku'}>
            <KegstarAutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source={'description'} />
    </Filter>
);

const MystarChargeList = props => {

    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'C'} title={'Charges'} subheader={'List of charges'}/>
                <CardContent style={{backgroundColor: '#EDEEEF'}}>

                    {/* Content Start */}
                    <List {...props} filters={<ListFilters classes={classes} />} sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} bulkActionButtons={false}

                    >
                        <Datagrid

                        >
                            <TextField emptyText source="displayId" label={'Id'} sortable={false}/>
                            <ReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Type'} link={false} allowEmpty>
                                <ReferenceField source="draftInvoiceUuid" reference="invoices/draft" addLabel={false} link={false}>
                                    <FunctionField render={record => (record.type===1) ? 'Order' : 'Monthly' } addLabel={false} link={false} />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Number'} link={false} allowEmpty>
                                <ReferenceField source="draftInvoiceUuid" reference="invoices" addLabel={false} link={false}>
                                    <TextField emptyText source="netsuiteInvDocNumber" />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="draftInvoiceItemUuid" reference="invoiceitems/draft" label={'Invoice Date'} link={false} allowEmpty>
                                <ReferenceField source="draftInvoiceUuid" reference="invoices/draft" addLabel={false} link={false}>
                                    <DateField source="createdAt"  />
                                </ReferenceField>
                            </ReferenceField>
                            <TextField emptyText source="description" label={'Description'} />
                            <TextField emptyText source="kegSerialNumber" label={'Asset Code'} />
                            <ReferenceField source="orderUuid" reference="orders" label={'Tracks Order Id'} link={false} sortable={false}>
                                <FunctionField
                                    render={record => <Link href={`${process.env.REACT_APP_TRACKS_URL}/#/kegs/orders/${record.tracksOrderId}/details`}
                                                            target="_blank" rel="noreferrer"> {record.tracksOrderId}
                                    </Link> } />
                            </ReferenceField>
                            <DateField source="applicableDate" label={'Applicable Date'}/>
                            <ReferenceField source="skuUuid" reference="skus" label={'Sku'} link={false} allowEmpty>
                                <TextField emptyText source="name" />
                            </ReferenceField>
                            <ReferenceField source="rentalUuid" reference="rentals" label={'Cycle Start Date'} link={false} allowEmpty>
                                <DateField source="startDate"  />
                            </ReferenceField>
                            <ReferenceField source="rentalUuid" reference="rentals" label={'Cycle Stop Date'} link={false} allowEmpty>
                                <DateField source="endDate"   />
                            </ReferenceField>
                            <ReferenceField source="orderUuid" reference="orders" label={'Cycle Stop Location'} link={false} allowEmpty>
                                <ReferenceField source="toLocationUuid" reference="locations" addLabel={false} link={false} >
                                    <TextField emptyText source="locationName" />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="orderUuid" reference="orders" label={'Current Location'} link={false} allowEmpty>
                                <ReferenceField source="fromLocationUuid" reference="locations" addLabel={false} link={false} >
                                    <TextField emptyText source="locationName" />
                                </ReferenceField>
                            </ReferenceField>
                            <ReferenceField source="orderUuid" reference="orders" label={"Location's Organisation"} link={false} allowEmpty>
                                <ReferenceField source="fromOrganisationUuid" reference="organisations" addLabel={false} link={false} >
                                    <TextField emptyText source="name" />
                                </ReferenceField>
                            </ReferenceField>
                            <NumberField source={'quantity'}/>
                            <NumberField source={'unitAmount'}/>
                            <NumberField source={'total'}/>
                            {/*<ShowButton variant={'outlined'} color={'primary'} />*/}
                        </Datagrid>
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );

}

export default MystarChargeList;

