import React, { Fragment } from 'react';
import WeeklySummary from "../summaries/WeeklySummary";
import {
    useParams
} from "react-router-dom";

const Weekly = () => {
    let { date } = useParams();

    return (
        <Fragment>
            <WeeklySummary organisation={localStorage.getItem('oUuid')} dateOfTheWeek={date} />
        </Fragment>
    );
}

export default Weekly;
