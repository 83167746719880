import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles( theme => ({
        loader: {
            color: theme.palette.primary.dark,
            opacity: 0.1

        },
    })
    ,
    { name: 'RaLoadingIndicator' }
);

/**
 * Progress bar formatted to replace an input or a field in a form layout
 *
 * Avoids visual jumps when replaced by value or form input
 *
 * @see KegstarReferenceField
 * @see ReferenceInput
 *
 * @param {object} classes CSS class names
 */
const KegstarCircularProgress = ({ classes: classesOverride, className, ...rest }) => {
    const classes = useStyles();
    return (
        <CircularProgress
            className={classes.loader}
            size={28}
            thickness={4}
            {...rest}
            />
    );
};
KegstarCircularProgress.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
};
// wat? TypeScript looses the displayName if we don't set it explicitly
KegstarCircularProgress.displayName = 'KegstarCircularProgress';

export default KegstarCircularProgress;
