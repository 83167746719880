import React, {Fragment} from 'react';
import {
    SearchInput, ListController, ListView, TextField
} from 'react-admin';
import KegstarFilter from "../components/list/KegstarFilter";

import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Divider from "@material-ui/core/Divider";
import {
    Link
} from "react-router-dom";
import ArrowForward from '@material-ui/icons/ArrowForward';
import KegstarDataLoader from "../components/loaders/KegstarDataLoader";
import {ShowRoles} from "../helpers/Utilities";
import KegstarReferenceField from "../components/field/KegstarReferenceField";
import Button from "@material-ui/core/Button";
import ButtonDownloadIcon from "../components/icons/ButtonDownloadIcon";
import MenuCloseIcon from '@material-ui/icons/Clear';
import PackagesIcon from "../components/icons/SidebarPackagesMenuIcon";
import RentalIcon from '@material-ui/icons/Timelapse';
import InvoiceIcon from '@material-ui/icons/LibraryBooks';

const useStyles = makeStyles(theme => ({
    searchInput: {
        width: '30em',
        [theme.breakpoints.down('sm')]: {
            width: '15em',
        }
    },
    showLink: {
        textDecoration: 'none',
        color: theme.palette.primary.dark
    },
    divider: {border: '0.75px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
    subDivider: {border: '0.25px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0}
}));

const ListFilters = (props)  => {
    const classes = useStyles();
    return (
        <Fragment>
            <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                <Box m={2}>
                    <MenuCloseIcon />
                </Box>
            </Grid>
            <KegstarFilter {...props} >
                <SearchInput source="search" alwaysOn className={classes.searchInput} options={{autoFocus: true}} onClick={()=> {return false}} />
            </KegstarFilter>
        </Fragment>
    )
};

export const SearchGrid = ({ ids, data, basePath, record, loading, ...props }) => {

    const classes = useStyles();
    if (loading) return <Fragment><Box m={20}><KegstarDataLoader /></Box></Fragment>;
    if(!data.undefined) return null;
    const {organisations, locations, kegs, draftInvoices, invoices, orders } = data.undefined;
    if ( (organisations.length + locations.length + kegs.length + invoices.length + orders.length) <= 0 ) return <Fragment><Box m={5}><Typography variant={'h6'}>No results found. Search for an organisation, location, keg serial number, order id, invoice, etc...</Typography></Box></Fragment>;

    return (
        <Fragment>
            { organisations.length > 0 &&
            <Box m={2}>
                <Box m={2}><Typography variant={'h6'}>Organisations</Typography></Box>
                <Divider className={classes.subDivider}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Organisation Name</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Invoices</TableCell>
                            <TableCell>Packages</TableCell>
                            <TableCell>Rentals</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { organisations.map(organisation =>
                            <TableRow>
                                <TableCell>
                                    <Link to={`/organisations/${organisation.uuid}/show`} className={classes.showLink}>{ organisation.name }</Link>
                                </TableCell>
                                <TableCell>{ organisation.countryName }</TableCell>
                                <TableCell>
                                    <Link  to={`/organisations/${organisation.uuid}/show/invoices`} className={classes.showLink} > <InvoiceIcon /> </Link>
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/organisations/${organisation.uuid}/show/packages`} className={classes.showLink} > <PackagesIcon /> </Link>
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/organisations/${organisation.uuid}/show/rentals`} className={classes.showLink} > <RentalIcon /> </Link>
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/organisations/${organisation.uuid}/show`} className={classes.showLink} ><ArrowForward /></Link>
                                </TableCell>

                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Box m={2}>
                        <Link to={'/organisations'} className={classes.showLink}> Search for more Organisations </Link>
                    </Box>
                </Grid>
            </Box>
            }

            { locations.length > 0 &&
            <Box m={2}>
                <Box m={2}><Typography variant={'h6'}>Locations</Typography></Box>
                <Divider className={classes.subDivider}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Location Name</TableCell>
                            <TableCell>Role(s)</TableCell>
                            <TableCell>Packages</TableCell>
                            <TableCell>Rentals</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { locations.map(location =>
                            <TableRow>
                                <TableCell>
                                    <Link to={`/locations/${location.uuid}/show`} className={classes.showLink}>{ location.locationName }</Link>
                                </TableCell>
                                <TableCell>
                                    <ShowRoles record={location} />
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/locations/${location.uuid}/show/packages`} className={classes.showLink} > <PackagesIcon /> </Link>
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/locations/${location.uuid}/show/rentals`} className={classes.showLink} > <RentalIcon /> </Link>
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/locations/${location.uuid}/show`} className={classes.showLink} ><ArrowForward /></Link>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Box m={2}>
                        <Link to={'/organisations'} className={classes.showLink} > Search for more Locations </Link>
                    </Box>
                </Grid>
            </Box>
            }

            { orders.length > 0 &&
            <Box m={2}>
                <Box m={2}><Typography variant={'h6'}>Orders</Typography></Box>
                <Divider className={classes.subDivider}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tracks Order Id</TableCell>
                            <TableCell>Sku </TableCell>
                            <TableCell>From Location </TableCell>
                            <TableCell>To Location </TableCell>
                            <TableCell>Organisation </TableCell>
                            <TableCell>Kegs Requested </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { orders.map(order =>
                            <TableRow>
                                <TableCell>
                                    <Link to={`/orders/${order.uuid}/show`} className={classes.showLink}> { order.tracksOrderId } </Link>
                                </TableCell>
                                <TableCell>
                                    <KegstarReferenceField
                                        reference="skus"
                                        source="skuUuid"
                                        basePath="/skus"
                                        record={order}
                                        linkType={false}
                                    >
                                        <TextField source="name" />
                                    </KegstarReferenceField>
                                </TableCell>
                                <TableCell>
                                    <KegstarReferenceField source="fromLocationUuid" reference="locations" basePath="/locations" link={false} label={'From Location'} record={order}>
                                        <TextField source="locationName" />
                                    </KegstarReferenceField>
                                </TableCell>
                                <TableCell>
                                    <KegstarReferenceField source="toLocationUuid" reference="locations" basePath="/locations" link={false} label={'To Location'} record={order}>
                                        <TextField source="locationName" />
                                    </KegstarReferenceField>
                                </TableCell>
                                <TableCell>
                                    <KegstarReferenceField source="toOrganisationUuid" reference="organisations" basePath="/organisations" link={false} label={'Organisation'} record={order}>
                                        <TextField source="name" />
                                    </KegstarReferenceField>
                                </TableCell>
                                <TableCell>
                                    { order.kegsRequested }
                                </TableCell>

                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Box m={2}>
                        <Link to={'/orders'} className={classes.showLink}> Search for more Orders</Link>
                    </Box>
                </Grid>
            </Box>
            }

            { kegs.length > 0 &&
            <Box m={2}>
                <Box m={2}><Typography variant={'h6'}>Kegs</Typography></Box>
                <Divider className={classes.subDivider}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Serial Number</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { kegs.map(keg =>
                            <TableRow>
                                <TableCell>
                                    <Link to={`/kegs/${keg.uuid}/show`} className={classes.showLink}>{ keg.serialNumber }</Link>
                                </TableCell>
                                <TableCell>
                                    <KegstarReferenceField
                                        reference="skus"
                                        source="skuUuid"
                                        basePath="/skus"
                                        record={keg}
                                        linkType={false}
                                    >
                                        <TextField source="name" />
                                    </KegstarReferenceField>
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/kegs/${keg.uuid}/show`} className={classes.showLink} ><ArrowForward /></Link>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Box m={2}>
                        <Link to={'/organisations'} className={classes.showLink}> Search for more Kegs </Link>
                    </Box>
                </Grid>
            </Box>
            }

            { invoices.length > 0 &&
            <Box m={2}>
                <Box m={2}><Typography variant={'h6'}>Invoices</Typography></Box>
                <Divider className={classes.subDivider}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Netsuite Invoice Number</TableCell>
                            <TableCell>Organisation </TableCell>
                            <TableCell>Invoice PDF </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { invoices.map(invoice =>
                            <TableRow>
                                <TableCell>
                                    <Link to={`/invoices/draft/${invoice.draftInvoiceUuid}/show`} className={classes.showLink}>{ invoice.netsuiteInvDocNumber }</Link>
                                </TableCell>
                                <TableCell>
                                    {invoice.organisationName}
                                </TableCell>
                                <TableCell>
                                    <Link href={invoice.invoiceUrl} target="_blank" rel="noreferrer">
                                        <Button variant={'none'} style={{width: '30px', height: '40px', backgroundColor: '#fff', borderRadius: '0px'}}>
                                            <ButtonDownloadIcon />
                                        </Button>
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link  to={`/invoices/draft/${invoice.draftInvoiceUuid}/show`} className={classes.showLink} ><ArrowForward /></Link>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Box m={2}>
                        <Link to={'/organisations'} className={classes.showLink}> Search for more Invoices</Link>
                    </Box>
                </Grid>
            </Box>
            }


        </Fragment>
    );
}

const SearchList = (props) => {
    return (
        <ListController {...props}
                        // filter={{tz: (localStorage.getItem('timezone') ? localStorage.getItem('timezone') : 'UTC')} }
        >
        {controllerProps =>
        <ListView {...props} {...controllerProps}

                  filters={<ListFilters />} bulkActionButtons={false} exporter={false} actions={false} pagination={false}
        >
            <SearchGrid />
        </ListView>
    }
</ListController>

    );
}

export default SearchList;
