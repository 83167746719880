import React, {Fragment} from "react";
import { styles } from './SidebarBaseMenuIcon';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(styles);

const SidebarTrackingMenuIcon = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <img src={require('../../assets/icons/SideMenu_Tracking.svg')} alt={'alt'} className={classes.iconSmallerWidth} style={{paddingLeft: '2px'}}  />
        </Fragment>
    )
}

export default SidebarTrackingMenuIcon;
