import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Filter,
    SearchInput,
    NumberField,
    ShowButton
} from 'react-admin';

const ListFilters = props => (
    <Filter {...props}>
        <SearchInput className={'searchInputBaseClass'} source="q" alwaysOn />
    </Filter>
);

const InvoiceItemList = props => (
    <List {...props} filters={<ListFilters />} sort={{ field: 'createdAt', order: 'DESC' }}  perPage={25} bulkActions={false}>
        <Datagrid>
            <TextField emptyText source="displayId" label={'Item'} />
            <ReferenceField source="invoiceUuid" reference="invoices" label={'Invoice'} linkType="show">
                <TextField emptyText source="displayId" />
            </ReferenceField>
            <ReferenceField source="skuUuid" reference="skus" label={'Sku'} linkType="show" allowEmpty>
                <TextField emptyText source="name" />
            </ReferenceField>
            <TextField emptyText source="description" />
            <NumberField source="feeType" />
            <NumberField source="chargeType" />
            <NumberField source="unitAmount" />
            <NumberField source="quantity" />
            <NumberField source="total" />
            <ShowButton variant={'outlined'} color={'primary'} />
        </Datagrid>
    </List>
);

export default InvoiceItemList;
