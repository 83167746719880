import React, {Fragment} from 'react';
import {
    FunctionField,
} from 'react-admin';
import { Show, SimpleShowLayout} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import {CountryFlag} from './CountryList';
import PackageExplorer from "../packages/details/PackageExplorer";
import Divider from "@material-ui/core/Divider";
import BackButton from "../components/BackButton";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#fff',
        paddingBottom: '1em',
        verticalAlign: 'top'
    },
    div: {
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingRight: '1em'
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        paddingLeft: '10px'
    },
    divider: {border: '0.5px solid', borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.primary.dark, margin: 0, padding: 0},
}));

const CountryDetails = ({record}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <div className={classes.container} >
                <div className={classes.div}><CountryFlag record={record} height={'72px'} /></div>
                <div className={classes.div}><Typography variant={'h4'}> {record.name} </Typography></div>
            </div>
        </Fragment>
    )
}

const CountryShow = ({ ...props }) => {

    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'C'} title={'Country'} subheader={'Details'}/>
                <CardContent>
                    {/* Content Start */}
                    <Show {...props}  >
                        <SimpleShowLayout>
                            <FunctionField render={record => <CountryDetails record={record} /> } addLabel={false} />
                            <Divider className={classes.divider} />
                            <Typography variant={'h5'}> PACKAGES </Typography>
                            <FunctionField render={record => <PackageExplorer type={'country'} country={record.uuid} /> } addLabel={false} />
                        </SimpleShowLayout>
                    </Show>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default CountryShow;
