import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import { usePermissions, useAuthState } from 'react-admin';
import KegstarLayout from "./KegstarLayout";
import AppBar from './AppBar';
import MystarAppBar from './MystarAppBar';
import Menu from './Menu';
import CustomerMenu from './MystarMenu';
import { mainTheme, darkTheme } from './themes';
import { AppState } from '../types';
import { Customer, Admin, Staff } from '../security/Role';
import Footer from "./Footer";
import KegstarSidebar from "./KegstarSidebar";
import KegstarLoadingScreen from "../components/KegstarLoadingScreen";
import defaultTheme from "./DefaultLayoutTheme";
import KegstarError from "./KegstarError";
//@ts-ignore
import ScrollUpButton from "react-scroll-up-button";

export default (props: any) => {
    const { permissions } = usePermissions();
    const { loading, authenticated } = useAuthState();

    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : mainTheme
    );

    if (loading) {
       return <KegstarLoadingScreen />;
    }

    if (authenticated) {
        if(Customer()){
            return (<Fragment>
                        <KegstarLayout {...props} appBar={MystarAppBar} sidebar={KegstarSidebar} menu={CustomerMenu} theme={theme} error={KegstarError} />
                        <ScrollUpButton />
                        <Footer/>
                    </Fragment>)
        } else if(Staff() || Admin()) {
            return (<Fragment>
                    <KegstarLayout {...props} appBar={AppBar} sidebar={KegstarSidebar} menu={Menu} theme={theme} error={KegstarError} />
                    <ScrollUpButton />
                    <Footer/>
                </Fragment>)
        } else {
            return null;
        }
    } else {
        return null;
    }

    // return (
    //     <Fragment>
    //         <KegstarLayout {...props} appBar={AppBar} sidebar={KegstarSidebar} menu={Menu} theme={theme}/>
    //         <ScrollUpButton />
    //         <Footer/>
    //     </Fragment>
    // )

}
