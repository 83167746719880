import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField, TextInput, DateInput,
    NumberField, usePermissions
} from 'react-admin';

import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import KegstarListActions from "../../components/list/KegstarListActions";
import KegstarFilter from "../../components/list/KegstarFilter";
import RentalsExport from "./RentalsExport";
import {isAdmin, isCustomer} from "../../security/Role";
import {makeStyles} from "@material-ui/core";

const ListFilters = props => (
    <KegstarFilter {...props}>
        <TextInput source={'id'} label={'Rental Id'} resettable alwaysOn />
        <TextInput source={'kegSerialNumber'} label={'Asset Code'} resettable alwaysOn />
        <TextInput source={'orderTracksOrderId'} label={'Order No.'} resettable alwaysOn />
        <DateInput source={'billingCycleStartDate[from]'} label={'Cycle Start Date From'} alwaysOn />
        <DateInput source={'billingCycleStartDate[to]'} label={'Cycle Start Date To'} alwaysOn />
        <DateInput source={'billingCycleEndDate'} label={'Cycle End Date'} />
    </KegstarFilter>
);

const useStyles = makeStyles(theme => ({
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
}));

const RentalList = props => {
    const { permissions } = usePermissions();
    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'R'} title={'Rentals'} subheader={'List of Rentals'}/>
                <CardContent className={classes.cardContent}>
                    {/* Content Start */}
                    <List {...props} filters={<ListFilters />} bulkActionButtons={false}
                          filter={isCustomer(permissions) ? {organisationUuid: localStorage.getItem('oUuid')}
                          : null }
                          sort={{ field: 'id', order: 'DESC' }}
                          actions={<KegstarListActions maxResults={10000} />}
                          exporter={RentalsExport}
                    >
                        <Datagrid>
                            { isAdmin(permissions) && <TextField source="id" label={'Rental Id'} /> }
                            { isAdmin(permissions) && <TextField emptyText source="organisationName" label={'Organisation'} /> }
                            <TextField emptyText source="kegSerialNumber" label={'Asset Code'} />
                            <TextField emptyText source="orderTracksOrderId" label={'Order Number'} />
                            <DateField source={"billingCycleStartDate"} label={'Cycle Start Date'}  />
                            <DateField source={"billingCycleEndDate"} label={'Cycle End Date'}  />
                            <NumberField source={"cycleDays"} label={'Cycle Days'} />
                            <TextField emptyText source={"startKegRoleLocationName"} label={'Cycle Start Location'} />
                            <TextField emptyText source={"stopKegRoleLocationName"} label={'Cycle Stop Location'} />
                            {/*<ShowButton variant={'outlined'} color={'primary'}/>*/}
                        </Datagrid>
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default RentalList;
