import React, { FunctionComponent, isValidElement, cloneElement, Fragment } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { makeStyles, MenuItem } from '@material-ui/core';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import classnames from 'classnames';
import ClearAllIcon from '@material-ui/icons/ClearAll';

const useStyles = makeStyles(
    theme => ({
        root: {
            fontWeight: 400,
        },
        selected: {
            fontWeight: 500,
        },
        suggestion: {
            display: 'block',
            fontFamily: theme.typography.fontFamily,
        },
        suggestionText: { fontWeight: 300 },
        highlightedSuggestionText: { fontWeight: 500 },
    }),
    { name: 'RaAutocompleteSuggestionItem' }
);

interface Props {
    suggestion: any;
    index: number;
    highlightedIndex: number;
    isSelected: boolean;
    filterValue: string;
    classes?: any;
    getSuggestionText: (suggestion: any) => string;
}

const AutocompleteSuggestionItem: FunctionComponent<
    Props & MenuItemProps<'li', { button?: true }>
> = props => {
    const {
        suggestion,
        index,
        highlightedIndex,
        isSelected,
        filterValue,
        classes: classesOverride,
        getSuggestionText,
        ...rest
    } = props;
    const classes = useStyles(props);
    const isHighlighted = highlightedIndex === index;
    const suggestionText = getSuggestionText(suggestion);
    let matches;
    let parts;

    if (!isValidElement(suggestionText)) {
        matches = match(suggestionText, filterValue);
        parts = parse(suggestionText, matches);
    }

    return (
        <MenuItem
            key={suggestionText}
            selected={isHighlighted}
            className={classnames(classes.root, {
                [classes.selected]: isSelected,
            })}
            {...rest}
        >
            {
                // @ts-ignore
                isValidElement<{ filterValue }>(suggestionText) ? (
                // @ts-ignore
                cloneElement<{ filterValue }>(suggestionText, { filterValue })
            ) : (
                <div className={classes.suggestion}>
                    {
                        // @ts-ignore
                        parts.map((part, index) => {
                        return part.highlight ? (
                            <span
                                key={index}
                                className={classes.highlightedSuggestionText}
                            >
                                {part.text}
                            </span>
                        ) : (
                            <strong
                                key={index}
                                className={classes.suggestionText}
                            >

                                {
                                    // @ts-ignore
                                    (part.text) ? part.text : <ClearTextLabel label={'All'} />
                                }
                            </strong>
                        );
                    })}
                </div>
            )}
        </MenuItem>
    );
};

// @ts-ignore
const ClearTextLabel = ({label}) => {

    return (
        <Fragment>
            <div style={{display: 'inline-flex', verticalAlign: 'middle'}} ><ClearAllIcon style={{fontSize: '1.7rem'}}/> <span style={{fontStyle: 'italic'}}>{ label }</span></div>
        </Fragment>
    )
}

export default AutocompleteSuggestionItem;
