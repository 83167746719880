import React, {Fragment} from "react";
import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';
import {makeStyles} from "@material-ui/core/styles";

const loadingAnimation = keyframes`${pulse}`;

const LoadingWrapper = styled.div`
  animation: 1s ${loadingAnimation} infinite;
`;
const useStyles = makeStyles( theme => ({
        logo: {
            width: '100%',
            filter: theme.overrides.iconFilter.filter
        },
    })
);
const KegstarTempDashboardSpinner = () => {
    const classes = useStyles();

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <LoadingWrapper>

                <img src={ require('../../assets/logos/KegstarLogo_Star.svg') } alt={''} className={classes.logo}/>

            </LoadingWrapper>
        </div>

    )
}

export default KegstarTempDashboardSpinner;
