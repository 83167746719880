import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField, TextInput,
    NumberField, Pagination, usePermissions, ReferenceInput, AutocompleteInput, DateInput
} from 'react-admin';
import KegnovaDatePickerInput from "../../components/KegnovaDatePickerInput";
import CardContent from "@material-ui/core/CardContent";
import KegstarCardHeader from "../../components/layout/KegstarCardHeader";
import Card from "@material-ui/core/Card";
import {useSelector} from "react-redux";
import KegstarDataLoader from "../../components/loaders/KegstarDataLoader";
import {isAdmin, isCustomer, isOperations, isStaff} from "../../security/Role";
import KegstarFilter from "../../components/list/KegstarFilter";
import KegstarListActions from "../../components/list/KegstarListActions";
import BackButton from "../../components/BackButton";
import ChargesExport from "./ChargesExport";
import {makeStyles} from "@material-ui/core";
import KegstarReferenceInput from "../../components/input/KegstarReferenceInput";
import KegstarAutocompleteInput from "../../components/input/KegstarAutocompleteInput";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    organisationFilter: {
        width: '20em'
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
}));

const ListFilters = props => {
    const {permissions} = usePermissions();
    const classes = useStyles();
    return (
        <KegstarFilter {...props}>
            { isOperations(permissions) &&  <TextInput source={'id'} label={'Id'} resettable alwaysOn /> }
            { isStaff(permissions) &&
                <KegstarReferenceInput source="organisationUuid" reference="organisations" label={'Organisation'} className={classes.organisationFilter}
                                alwaysOn>
                    <KegstarAutocompleteInput optionText="name" />
                </KegstarReferenceInput>
            }
            <TextInput source="invoiceDocNumber" label={'Invoice Number'} resettable />
            <TextInput source={'kegSerialNumber'} label={'Asset Code'} resettable alwaysOn />
            {/*<KegnovaDatePickerInput source={"invoiceCreatedAt"} label={'Invoice Date'} />*/}
            {/*<KegnovaDatePickerInput source={'billingCycleStartDate'} label={'Cycle Start Date'} />*/}

            { isOperations(permissions) && <DateInput source={"createdAt"} label={'Charge Date'} /> }
            <DateInput source={'billingCycleStartDate'} label={'Cycle Start Date'} alwaysOn />

        </KegstarFilter>
    )
};

const ChargeList = props => {
    const { permissions } = usePermissions();
    const classes = useStyles();

    return (
        <Fragment>
            <Card>
                <KegstarCardHeader {...props} avatar={'R'} title={'Charges'} subheader={'List of Charges'}/>
                <CardContent className={classes.cardContent}>
                    {/* Content Start */}
                    <List {...props} filters={<ListFilters />} perPage={10} bulkActionButtons={false}
                          filter={isCustomer(permissions) ? {organisationUuid: localStorage.getItem('oUuid')}
                              : null }
                          sort={{ field: 'id', order: 'DESC' }}
                          actions={<KegstarListActions />}
                          exporter={ChargesExport}
                    >
                        <Datagrid>
                            { isOperations(permissions) && <TextField emptyText source={"id"} label={'Id'} /> }
                            { isOperations(permissions) && <DateField source={"createdAt"} label={'Charge Date'}   /> }
                            { isOperations(permissions) && <TextField emptyText source={"organisationName"} label={'Organisation'} /> }
                            <TextField emptyText source="invoiceDocNumber" label={'Invoice Number'} />
                            <DateField source={"netsuiteInvoiceCreatedAt"} label={'Invoice Date'}   />
                            <TextField emptyText source={"chargeDescription"} label={'Charge Type'} />
                            <TextField emptyText source={"kegSerialNumber"} label={'Asset Code'} />
                            <DateField source={"billingCycleStartDate"} label={'Cycle Start Date'}   />
                            <DateField source={"billingCycleEndDate"} label={'Cycle End Date'}   />
                            <TextField emptyText source={"stopKegRoleLocationName"} label={'Cycle Stop Location'} />
                            <NumberField source={"quantity"} label={'Quantity'} />
                            <NumberField source={"unitAmount"} label={'Unit Amount'} options={{ maximumFractionDigits: 2 }} />
                            <NumberField source={"total"} label={'Total'} options={{ maximumFractionDigits: 2 }} />
                        </Datagrid>
                    </List>
                    {/* Content End */}
                </CardContent>
            </Card>
        </Fragment>
    )
};

export default ChargeList;
