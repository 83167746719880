import React from "react";
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";

const loadingAnimation = keyframes`${fadeInUp}`;

const LoadingWrapper = styled.div`
  animation: 1s ${loadingAnimation};
`;

const useStyles = makeStyles(theme => ({
    progressBarRed: {
        color: '#FFFFFF', fontSize: '18px', paddingLeft: '10px',
        paddingTop: '5px', paddingBottom: '5px', border: 'none'
    }
}));

const AverageCycleTimeLineGraph = ({color = 'black', record, value, label}) => {
    const classes = useStyles();
    const maxValue = Math.max(record.currentMonthInDays, record.previousMonthInDays, record.previousYearInDays);
    let percentage = ((value / maxValue) * 100).toFixed(0);

    return (
        <LoadingWrapper>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.progressBarRed} style={{backgroundImage: `linear-gradient(to right, ${color} ${percentage}%, white ${percentage}%)` }}> { label } </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </LoadingWrapper>
    )
}

export default AverageCycleTimeLineGraph;
