import React from 'react';
import {
    translate,
    Create,
    DateInput,
    ReferenceInput,
    FormTab,
    TabbedForm,
    SelectInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import DollarIcon from '@material-ui/icons/AttachMoney';
import AppIcon from '@material-ui/icons/Apps';
import DatesIcon from '@material-ui/icons/PermContactCalendar';
import Avatar from "@material-ui/core/Avatar";
import Zoom from '@material-ui/core/Zoom';
import FeeTypes from "./FeeTypes";
import KegstarAutocompleteInput from "../components/input/KegstarAutocompleteInput";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    icon: {
        backgroundColor: theme.palette.secondary.main
    },
    gridContainer: {
        paddingTop: '1em'
    }
});

class PackageCreate extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            countryDisplay: false,
            organisationDisplay: false,
            locationDisplay: false,
            formDisplay: false
        }
    }

    displayPackageType = tags => (event, value) => {

        this.setState({
            countryDisplay: false,
            organisationDisplay: false,
            locationDisplay: false,
            formDisplay: false
        });

        switch(value){
            case 'C':
                this.setState({countryDisplay: !this.state.countryDisplay});
                break;
            case 'O':
                this.setState({organisationDisplay: !this.state.organisationDisplay});
                break;
            case 'L':
                this.setState({locationDisplay: !this.state.locationDisplay});
                break;
            default:
                break;
        }

    }

    showFormContainers = tags => () => {
        this.setState({formDisplay: true});
    }

    render() {
        const { translate, classes, styles, record, ...props } = this.props;

        return (
            <Create {...props} >
                <TabbedForm submitOnEnter={false}>
                    <FormTab label="Create Package">
                        <Grid container spacing={8} >
                            <Grid item xs>
                                <Card>
                                    <CardHeader title="Attribute" subheader="Select a Country, Organisation or Location"
                                                avatar={<Avatar className={classes.icon}>
                                                    <AppIcon />
                                                </Avatar>   }  titleTypographyProps={{variant: 'title' }} />
                                    <Divider variant="middle"/>
                                    <CardContent>
                                        <SelectInput source="packageType" choices={[
                                            { id: '', name: 'Select a Type' },
                                            { id: 'C', name: 'Country' },
                                            { id: 'O', name: 'Organisation' },
                                            { id: 'L', name: 'Location' }
                                        ]} onChange={this.displayPackageType("packageType")} options={{ fullWidth: true, }} />

                                        <div style={{display: this.state.countryDisplay ? 'inherit' : 'none'}}>
                                            <ReferenceInput source="countryUuid" reference="countries" resource="countryUuid"
                                                            sort={{ field: 'name', order: 'ASC' }}
                                                            label={'Country'} onChange={this.showFormContainers("showForm")}  value={'test'}>
                                                <KegstarAutocompleteInput optionValue="uuid" optionText="name"  options={{ fullWidth: true, }} />
                                            </ReferenceInput>
                                        </div>
                                        <div style={{display: this.state.organisationDisplay ? 'inherit' : 'none'}}>
                                            <ReferenceInput source="organisationUuid" reference="organisations" resource="organisationUuid"
                                                            label={'Organisation'} onChange={this.showFormContainers("showForm")}>
                                                <KegstarAutocompleteInput optionValue="uuid" optionText="name"  options={{ fullWidth: true, }} />
                                            </ReferenceInput>
                                        </div>
                                        <div style={{display: this.state.locationDisplay ? 'inherit' : 'none'}}>
                                            <ReferenceInput source="locationUuid" reference="locations" resource="locationUuid"
                                                            label={'Location'} onChange={this.showFormContainers("showForm")}>
                                                <KegstarAutocompleteInput optionValue="uuid" optionText="locationName"  options={{ fullWidth: true, }} />
                                            </ReferenceInput>
                                        </div>

                                        <ReferenceInput source="skuUuid" reference="skus" resource="skuUuid"
                                                        label={translate('resources.packages.create.skuUuid')} >
                                            <KegstarAutocompleteInput optionValue="uuid" optionText="name"  options={{ fullWidth: true, }} />
                                        </ReferenceInput>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Zoom in={this.state.formDisplay}>
                                <Grid item xs>
                                    <Card>
                                        <CardHeader title="Dates and Discount Group" subheader="Enter a Start,  End Date and PSA Group"  avatar={<Avatar className={classes.icon}>
                                            <DatesIcon />
                                        </Avatar>   }  titleTypographyProps={{variant: 'title' }} />
                                        <Divider variant="middle"/>
                                        <CardContent>
                                            <DateInput source="startDate" label={translate('resources.packages.fields.startDate')}  fullWidth />
                                            <DateInput source="endDate" label={translate('resources.packages.fields.endDate')} fullWidth />

                                            <ReferenceInput source="psaDiscountGroupUuid" reference="psadiscountsgroups" resource="psaDiscountGroupUuid"
                                                            label={translate('resources.packages.create.psaDiscountGroupUuid')} >
                                                <SelectInput optionText={choice =>
                                                    `${choice.description}`
                                                } options={{ fullWidth:true }}
                                                />
                                            </ReferenceInput>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Zoom>

                        </Grid>

                        <Zoom in={this.state.formDisplay}>
                            <Grid container spacing={8} style={{height: (this.state.formDisplay) ? 'auto' : 0}}>
                                <Grid item xs>
                                    <Card>
                                        <CardHeader title="Fees" subheader="Setup Package Fees" avatar={<Avatar className={classes.icon}>
                                            <DollarIcon />
                                        </Avatar>   }  titleTypographyProps={{variant: 'title' }} />
                                        <Divider variant="middle"/>
                                        <CardContent>

                                            <FeeTypes />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Zoom>

                    </FormTab>

                </TabbedForm>
            </Create>
        );

    }

}

export default translate(withStyles(styles, { withTheme: true })(PackageCreate));
