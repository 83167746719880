// import { createMuiTheme } from '@material-ui/core/styles';
// import red from '@material-ui/core/colors/red';
// import grey from '@material-ui/core/colors/grey';

export const mainTheme = {
    palette: {
        background: {
            default: '#EDEEEF'
        },
        secondary: {
            light: '#EDEEEF',
            main: '#fff',
            dark: '#000',
            contrastText: '#000',
        },
        primary: {
            main: '#0091D9',
            dark: '#000',
            light: '#fff'
        }
    },
    shadows: Array(25).fill('none'),
    typography: {
        fontFamily: "NettoOT, sans-serif",
        fontSize: 15,
    },
    overrides: {
        MuiFilledInput: {
            root: {
                '&:hover': {
                    backgroundColor: '#FFF',
                },
                backgroundColor: '#fff',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                borderRadius: '0px !important'
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: '#fff',
                borderRadius: 0
            },
            // rounder: { borderRadius: 0 }
        },
        MuiCard: {
            root: {
                borderRadius: 0
            }
        },
        MuiCardContent: {
            root: {
                backgroundColor: '#FFF',
                borderRadius: 0
            }
        },
        MuiTabs: {
            root: {
                background: '#fff',
            }
        },
        MuiTab: {
            wrapper: {
                color: '#000',
                fontFamily: 'NettoOT Bold',
                fontSize: 'larger'
            }
        },
        MuiDrawer: {
            root: {
                background: '#fff',
                borderTop: '7px solid #EDEEEF',
                borderRight: '5px solid #EDEEEF',
            },
            docked: {
                marginTop: '6px',
                zIndex: 1
            },
            modal: {
                top: 'auto !important',
                '@media (max-width: 420px)': {
                    top: '0 !important',
                }
            }
        },
        MuiBackdrop: {
            root: {
                opacity: 0.25
            }
        },
        MuiInputLabel: {
            filled: {
                transform: 'translate(12px, 3px) scale(0.75) !important'
            }
        },
        RaDatagrid: {
            headerCell: {
                backgroundColor: '#EDEEEF'
            },
            rowCell: {
                backgroundColor: '#fff'
            }
        },
        RaListToolbar: {
            actions: {
                paddingTop: '0px'
            },
            toolbar: {
                justifyContent: 'flex-end'
            }
        },
        RaFilterFormInput: {
            body: {
                display: 'flex',
                alignItems: 'unset'
            }
        },
        RaFilterForm: {
            form: {
                marginTop: '0px'
            }
        },
        RaTabbedShowLayout: {
            content: {
                backgroundColor: '#FFF'
            }
        },
        RaButton: {
            button: {
                height: '50px'
            }
        },
        kegstarLogo: {
            filter: 'invert(0)'
        },
        iconFilter: {
            filter: 'invert(0)'
        },
        footerFilter: {
            filter: 'invert(0)'
        },
        listActionButton: {
            borderBottomColor: '#949494'
        }
    }
};

export const darkTheme = {
    palette: {
        type: 'dark',
        background: {
            default: '#0C0C0C'
        },
        secondary: {
            light: '#1F1F1F',
            main: '#000',
            dark: '#fff',
            contrastText: '#fff',
        },
        primary: {
            main: '#0091D9',
            dark: '#fff',
            light: '#000'
        }
    },
    shadows: Array(25).fill('none'),
    typography: {
        fontFamily: "NettoOT, sans-serif",
        fontSize: 15,
    },
    overrides: {
        MuiFilledInput: {
            root: {
                '&:hover': {
                    backgroundColor: '#FFF'
                },
                backgroundColor: '#000',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                borderRadius: '0px !important',
                height: '3em',
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: '#000',
                borderRadius: 0
            },
            // rounder: { borderRadius: 0 }
        },
        MuiCard: {
            root: {
                borderRadius: 0
            }
        },
        MuiCardContent: {
            root: {
                backgroundColor: '#000',
                borderRadius: 0
            }
        },
        MuiTabs: {
            root: {
                background: '#000',
            }
        },
        MuiTab: {
            wrapper: {
                color: '#fff',
                fontFamily: 'NettoOT Bold',
                fontSize: 'larger'
            }
        },
        MuiDrawer: {
            root: {
                background: '#000',
                borderTop: '7px solid',
                borderTopColor: '#0C0C0C',
                borderRight: '5px solid',
                borderRightColor: '#0C0C0C',
            },
            docked: {
                marginTop: '6px',
                zIndex: 1
            },
            modal: {
                top: 'auto !important',
                '@media (max-width: 420px)': {
                    top: '0 !important',
                }
            }
        },
        MuiBackdrop: {
            root: {
                opacity: 0.25
            }
        },
        MuiFormLabel: {
            filled: {
                backgroundColor: '#000',
                paddingLeft: '5px',
                paddingRight: '5px',
            }
        },
        RaDatagrid: {
            headerCell: {
                backgroundColor: '#0C0C0C'
            },
            rowCell: {
                backgroundColor: '#000'
            }
        },
        RaListToolbar: {
            actions: {
                paddingTop: '0px'
            },
            toolbar: {
                justifyContent: 'flex-end'
            }
        },
        RaFilterFormInput: {
            body: {
                display: 'flex',
                alignItems: 'unset'
            }
        },
        RaFilterForm: {
            form: {
                marginTop: '0px'
            }
        },
        RaTabbedShowLayout: {
            content: {
                backgroundColor: '#000'
            }
        },
        RaButton: {
            button: {
                height: '50px'
            }
        },
        kegstarLogo: {
            filter: 'invert(1)'
        },
        iconFilter: {
            filter: 'invert(1)'
        },
        footerFilter: {
            filter: 'invert(1)'
        },
        listActionButton: {
            borderBottomColor: '#949494'
        }
    }
};
