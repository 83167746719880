import OrderList from './OrderList.js';
import OrderShow from './OrderShow.js';
import OrderIcon from '@material-ui/icons/ShoppingBasket';
import UnauthorisedPage from "../components/UnauthorisedPage";

export default {
    list: OrderList,
    show: OrderShow,
    icon: OrderIcon,
    unauthorised: UnauthorisedPage
};
