import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';

import SidebarAccountMenuIcon from "../components/icons/SidebarAccountMenuIcon";
import SidebarBillingMenuIcon from "../components/icons/SidebarBillingMenuIcon";
import SidebarInvoicesMenuIcon from "../components/icons/SidebarInvoicesMenuIcon";
import SidebarChargesMenuIcon from "../components/icons/SidebarChargesMenuIcon";
import SidebarRentalsMenuIcon from "../components/icons/SidebarRentalsMenuIcon";
import SidebarPackagesMenuIcon from "../components/icons/SidebarPackagesMenuIcon";
import SidebarTrackingMenuIcon from "../components/icons/SidebarTrackingMenuIcon";

import {useMediaQuery, Theme, makeStyles} from '@material-ui/core';
import { useTranslate, MenuItemLink } from 'react-admin';

import organisations from '../organisations/index';
import countries from '../countries/index';
import locations from '../locations';
import invoices from '../invoices/draft/index';
import orders from '../orders';
import charges from '../charges';
import kegs from '../kegs';
import rentals from '../rentals';
import packages from '../packages';
import packagedetails from '../packages/details';

import SubMenu from './SubMenu';
import { AppState } from '../types';
import DashboardMenuItem from "../components/layout/DashboardMenuItem";

type MenuName = 'menuAccount' | 'menuBilling'| 'menuTracking' | 'menuPackages' ;

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}
export const styles = (theme: any) => ({
    icons: {
        color: theme.palette.primary.dark
    },
    menuLink: {
        color: theme.palette.primary.dark
    },
    menuItemHeaderLink: {
        fontFamily: 'NettoOT Bold'
    },
    menuItemLink: {
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.primary.light,
        color: theme.palette.primary.dark
    }
});

const useStyles = makeStyles(styles);

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        menuAccount: true,
        menuBilling: true,
        menuTracking: true,
        menuPackages: true
    });

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };




    return (
        <div>

            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} locale={'en'} />

            <SubMenu
                handleToggle={() => handleToggle('menuAccount')}
                isOpen={state.menuAccount}
                sidebarIsOpen={open}
                name="app.menu.account.name"
                icon={<SidebarAccountMenuIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/organisations`}
                    primaryText={'Organisations'}
                    leftIcon={<organisations.icon className={classes.icons} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />

                <MenuItemLink
                    to={`/locations`}
                    primaryText={'Locations'}
                    leftIcon={<locations.icon className={classes.icons} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />

                {/*<MenuItemLink*/}
                {/*    to={`/kegs`}*/}
                {/*    primaryText={'Kegs'}*/}
                {/*    leftIcon={<kegs.icon className={classes.icons} />}*/}
                {/*    onClick={onMenuClick}*/}
                {/*    sidebarIsOpen={open}*/}
                {/*    dense={dense}*/}
                {/*    className={classes.menuItemLink}*/}
                {/*/>*/}

                <MenuItemLink
                    to={`/packages/countries`}
                    primaryText={'Countries'}
                    leftIcon={<countries.icon className={classes.icons} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />

            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuBilling')}
                isOpen={state.menuBilling}
                sidebarIsOpen={open}
                name="app.menu.billing.name"
                icon={<SidebarBillingMenuIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/invoices/draft`}
                    primaryText={'Invoices'}
                    leftIcon={<SidebarInvoicesMenuIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />
                <MenuItemLink
                    to={`/report/charges`}
                    primaryText={'Charges'}
                    leftIcon={<SidebarChargesMenuIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />
                {/*<MenuItemLink*/}
                {/*    to={`/report/rentals`}*/}
                {/*    primaryText={'Rentals'}*/}
                {/*    leftIcon={<SidebarRentalsMenuIcon />}*/}
                {/*    onClick={onMenuClick}*/}
                {/*    sidebarIsOpen={open}*/}
                {/*    dense={dense}*/}
                {/*    className={classes.menuItemLink}*/}
                {/*/>*/}
                {/*<SubMenu*/}
                {/*    handleToggle={() => handleToggle('menuPackages')}*/}
                {/*    isOpen={state.menuPackages}*/}
                {/*    sidebarIsOpen={open}*/}
                {/*    name="app.menu.packages.name"*/}
                {/*    icon={<SidebarPackagesMenuIcon />}*/}
                {/*    dense={dense}*/}
                {/*>*/}
                {/*    <MenuItemLink*/}
                {/*        to={`/packages`}*/}
                {/*        primaryText={'Package List'}*/}
                {/*        leftIcon={<SidebarPackagesMenuIcon />}*/}
                {/*        onClick={onMenuClick}*/}
                {/*        sidebarIsOpen={open}*/}
                {/*        dense={dense}*/}
                {/*        className={classes.menuItemLink}*/}
                {/*    />*/}
                {/*</SubMenu>*/}
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuTracking')}
                isOpen={state.menuTracking}
                sidebarIsOpen={open}
                name="app.menu.tracking.name"
                icon={<SidebarTrackingMenuIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/orders`}
                    primaryText={'Orders'}
                    leftIcon={<orders.icon className={classes.icons} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.menuItemLink}
                />
            </SubMenu>

            {/*{isXSmall && (*/}
            {/*    <MenuItemLink*/}
            {/*        to="/configuration"*/}
            {/*        primaryText={'Configuration'}*/}
            {/*        leftIcon={<SettingsIcon className={classes.icons} />}*/}
            {/*        onClick={onMenuClick}*/}
            {/*        sidebarIsOpen={open}*/}
            {/*        dense={dense}*/}
            {/*    />*/}
            {/*)}*/}

            {isXSmall && logout}

        </div>
    );
};

export default Menu;
